import moment from 'moment'

import { currencyFormat } from 'utilities'

export const COLUMNS = [
  {
    Header: <div className="text-center">ID</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        {row.id}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className="text-center">SKU</div>,
    id: 'sku',
    accessor: (row) => (
      <div className='text-center'>
        {row.sku}
      </div>
    ),
  },
  {
    Header: <div className="">Nombre</div>,
    id: 'name',
    accessor: (row) => (
      <div className=''>
        {row.name}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className="">Droga</div>,
    id: 'droga',
    accessor: (row) => (
      <div className=''>
        {row.droga}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Laboratorio</div>,
    id: 'laboratorio',
    accessor: (row) => (
      <div className='text-center'>
        {row.laboratorio}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Trazable</div>,
    id: 'trazable',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.trazable) ? 'success' : 'danger'}`}>
          {Boolean(row?.trazable) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Frío</div>,
    id: 'Frio',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.Frio) ? 'success' : 'danger'}`}>
          {Boolean(row?.Frio) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
  },
  {
    Header: <div className='text-center'>TA</div>,
    id: 'TA',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.TA) ? 'success' : 'danger'}`}>
          {Boolean(row?.TA) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Stock disp.</div>,
    id: 'Disponible',
    accessor: (row) => (
      <div className='text-center'>
        {row.Disponible}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>PVP</div>,
    id: 'pvp',
    accessor: (row) => (
      <div className='text-center'>
        {row?.pvp ? currencyFormat.format(row.pvp) : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Costo de lista unitario</div>,
    id: 'CostoLista',
    accessor: (row) => (
      <div className='text-center'>
        {row?.CostoLista ? currencyFormat.format(row.CostoLista) : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Lote</div>,
    id: 'Lote',
    accessor: (row) => (
      <div className='text-center'>
        {row.Lote}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Vencimiento</div>,
    id: 'ProxVencimiento',
    accessor: (row) => (
      <div className='text-center'>
        {row.ProxVencimiento}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Días vencimiento</div>,
    id: 'DiasProxVencimiento',
    accessor: (row) => (
      <div className='text-center'>
        {row.DiasProxVencimiento}
      </div>
    ),
  },
]