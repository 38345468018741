import { useState, useEffect, useCallback } from 'react'
import { useFormikContext } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers, usePatients, useShipmentAddresses } from 'hooks'

import { ShipmentAddressModal, PatientModal } from 'containers'
import { Label, Checkbox, SelectField, InputBS, Textarea } from 'components'

import { HomeDeliveryOptionI, RoleI, SalesOrderStatusI } from 'interfaces'
import { specialDeliveryOptions } from "pages/Sells/common/utils/"

import Modal from './Modal'

import routes from 'app/routing/routes'

const TabDestinatario = ({ loading, editing, obtenerPedido, setTab }) => {
  const { currentUser } = useAuth()

  const { values, setFieldValue, errors, touched } = useFormikContext()

  const [addressModal, setAddressModal] = useState(false)
  const [patientAddressInfo, setPatientAddressInfo] = useState(null)
  const [patientModal, setPatientModal] = useState(false)

  const [editObservations, setEditObservations] = useState(false)
  const [editDispatchObservations, setEditDispatchObservations] = useState(false)

  const { handleGetShipmentAddresses } = useShipmentAddresses()

  const { handleGetCustomerSupplierPatients, handleGetCustomerSupplierShipmentAddresses, handleAddCustomerSupplierPatient, handleGetClientesParaRemitir } = useCustomerSuppliers()

  const [patients, setPatients] = useState([])
  const [ourAddresses, setOurAddresses] = useState([])
  const [customerAddressesTrace, setCustomerAddressesTrace] = useState([])
  const [customerAddresses, setCustomerAddresses] = useState([])
  const [patientAddresses, setPatientAddresses] = useState([])
  const [clientesParaRemitir, setClientesParaRemitir] = useState([])

  const [optionsAddressShip, setOptionsAddressShip] = useState([])
  const [optionsAddressTraza, setOptionsAddressTraza] = useState([])

  const { handleCreatePatient, handleGetPatientAddresses, handleAddPatientAddress, handleUpdatePatientAddress } = usePatients()

  const [homeDeliveryOptions, setHomeDeliveryOptions] = useState(specialDeliveryOptions)

  /* Modal de cambio de domicilios */
  const patientAddressSubmit = async (data) => {
    try {
      let response

      if (patientAddressInfo?.id) {
        response = await handleUpdatePatientAddress(Number(values?.patient_id), Number(values?.address_id_ship), data)

        toast.success("Registro actualizado correctamente", { theme:"colored" })
        return
      }

      response = await handleAddPatientAddress(Number(values?.patient_id), data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      loadPatientAddresses()
      obtenerPedido()
      setAddressModal(false)
    }
  }

  const loadAddressOptions = (customerAddrTrace, customerAddr, ourAddr, patientAddr) => {
    let _addresses = [];

    if (values?.home_delivery_option_id === HomeDeliveryOptionI.NO) {
      setOptionsAddressTraza(customerAddrTrace?.filter(a => a.has_traceability == 1))
      _addresses = customerAddrTrace
    } else if (values?.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE) {
      setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
      _addresses = customerAddr?.filter(a => (a.gln == null || a.gln == ''))
    } else if (values?.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
      if (Boolean(values?.to_patient)) {
        setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
        _addresses = patientAddr?.filter(a => (a.gln == null || a.gln == ''))
        setPatientAddresses(_addresses)
      }
    } else if (values?.home_delivery_option_id === HomeDeliveryOptionI.FARMACIA) {
      setOptionsAddressTraza(ourAddr?.filter(a => a.has_traceability == 1))
      _addresses = ourAddr?.filter(a => a.has_traceability == 1)
    }

    if (_addresses && _addresses?.length) {
      const _options = _addresses.map(address => {
        const { name, address: addr } = address;

        return {
          value: address.id,
          label: `${name} - ${addr?.domicilio?.trim()}, ${addr.localidad_completa?.trim()}`
        };
      });

      setOptionsAddressShip(_options || [])
    }
  }

  const loadPatients = async() => {
    try {
      const params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierPatients(Number(values?.customer_supplier_id), params)
      const _patients = Array.isArray(response.data.result)
        ? response.data.result.map(p => ({
            ...p,
            display_name : `[${p.id}] ${p.documentation ? `${p.documentation} - ${p.first_name} ${p.last_name}` : `${p.first_name} ${p.last_name}`}${p?.affiliate_number ? ` | Nro. afiliado: ${p.affiliate_number}` : ''}`
          }))
        : []

      setPatients(_patients)

    } catch (error) {
      toast.error(error.message)
    }
  }

  const loadAddresses = async () => {
    try {
      let _pac = {}
      let ourParams = {
        has_traceability: 1,
        is_ours: 1,
        is_enabled: 1,
      }
      const _our = await handleGetShipmentAddresses('', null, ourParams)
      setOurAddresses(_our.data.result);

      let cssaParams = {
        has_traceability: 1,
        is_enabled: 1,
      }
      const _cust_traceability = await handleGetCustomerSupplierShipmentAddresses(Number(values?.customer_supplier_id), cssaParams, '')
      setCustomerAddressesTrace(_cust_traceability.data.result);

      cssaParams = {
        has_traceability: 0,
        is_enabled: 1,
      }
      const _cust = await handleGetCustomerSupplierShipmentAddresses(Number(values?.customer_supplier_id), cssaParams, '')
      setCustomerAddresses(_cust.data.result);

      if (values?.patient_id) {
        let pacParams = {
          is_enabled: 1,
          has_traceability: 0,
        }
        _pac = await handleGetPatientAddresses(Number(values?.patient_id), pacParams)
        setPatientAddresses(_pac.data.result)
      }

      loadAddressOptions(_cust_traceability.data.result, _cust.data.result, _our.data.result, _pac.data?.result)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const loadPatientAddresses = async () => {
    const response = await handleGetPatientAddresses(Number(values?.patient_id))
    setPatientAddresses(response.data.result)

    if (values?.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
      if (Boolean(values?.to_patient)) {
        const _options = response.data?.result?.filter(a => (a.gln == null || a.gln == '')).map(address => ({
          value: address.id,
          label: `${address.name} - ${address.address?.domicilio?.trim()}, ${address.address?.localidad_completa?.trim()}`
        }))

        setOptionsAddressShip(_options || [])
      }
    }
  }

  const changePatient = (selected) => {
    if (!selected || selected?.length === 0) {
      setFieldValue('patient_id', '')
      setFieldValue('patient_fullname', '')
      setFieldValue('address_id_ship', '')
      setOptionsAddressShip([])
    } else {
      const value = selected[0]
      setFieldValue('patient_id', value.id)
      setFieldValue('patient_fullname', `[${value.id}] ${value.documentation ? `${value.documentation} - ${value.first_name} ${value.last_name}` : `${value.first_name} ${value.last_name}`}${value?.affiliate_number ? ` | Nro. afiliado: ${value.affiliate_number}` : ''}`)
    }
  }

  const createPatient = async (patient) => {
    try {
      // Se crea el paciente
      const responsePatient = await handleCreatePatient(patient)
      toast.success(responsePatient.message)

      // Se vincula el paciente creado con el cliente / proveedor
      const responseCustomer = await handleAddCustomerSupplierPatient(values?.customer_supplier_id, responsePatient.id)
      toast.success(responseCustomer.message)

      setPatientModal(false)
    } catch (error) {
      toast.error(error.message)
    } finally {
      loadPatients()
    }
  }

  const getClientesParaRemitir = useCallback(async () => {
    try {
      let params = {
        id_cliente: values?.customer_supplier_id,
        activo: 1,
      }
      const response = await handleGetClientesParaRemitir(params)
      const data = response.data.result.map(d => ({
        value: d.id_cliente_para_remitir,
        label: `[${d.id_cliente_para_remitir}] ${d.para_remitir}`,
      }))

      setClientesParaRemitir(data)
    } catch (error) {
      setClientesParaRemitir([])
      toast.error(error.message)
    }
  }, [handleGetClientesParaRemitir])

  useEffect(() => {
    if (editing) {
      if (values?.home_delivery_option_id !== HomeDeliveryOptionI.NO) { // si es NO, ya hay un effect que lo actualiza
        // setFieldValue('address_id_ship', '')
      }
      if (Boolean(values?.to_patient)) {
        setHomeDeliveryOptions(specialDeliveryOptions)
      } else {
        setFieldValue('patient_id', '')
        setHomeDeliveryOptions(specialDeliveryOptions?.filter(o => o.label !== 'A paciente'))

        if (values?.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
          setFieldValue('home_delivery_option_id', HomeDeliveryOptionI.NO)
        }
      }
    }
  }, [values?.to_patient])

  useEffect(() => {
    if (editing) {
      if (Boolean(values?.to_patient)) {
        setHomeDeliveryOptions(specialDeliveryOptions)
      } else {
        setHomeDeliveryOptions(specialDeliveryOptions?.filter(o => o.label !== 'A paciente'))
        if(values?.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE){
          setFieldValue('home_delivery_option_id', HomeDeliveryOptionI.NO)
        }
      }
    }
  }, [editing])

  useEffect(() => {
    if (editing) {
      loadAddressOptions(customerAddressesTrace, customerAddresses, ourAddresses, patientAddresses)
    }
  }, [values?.home_delivery_option_id])


  useEffect(() => {
    if (editing) {
      if (values?.home_delivery_option_id === HomeDeliveryOptionI.NO || values?.home_delivery_option_id === HomeDeliveryOptionI.FARMACIA) {
        setFieldValue('address_id_ship', values?.address_id_traza)
      }
    }
  }, [values?.address_id_traza])


  useEffect(() => {
    if (values?.patient_id) {
      loadPatientAddresses()
    }

  }, [values?.patient_id, values?.home_delivery_option_id, values?.to_patient])

  useEffect(() => {
    loadAddresses()
    loadPatients()
  }, [])

  useEffect(() => {
    getClientesParaRemitir()
  }, [getClientesParaRemitir])

  useEffect(() => {
    setTab('destinatario')
  }, [])

  return (
    <>
      <PatientModal
        show={patientModal}
        onHide={() => {
          setPatientModal(false)
        }}
        onSave={values => createPatient(values)}
      />

      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='dispatch_date' name='dispatch_date' type='date' label={'Fecha de entrega'} disabled={loading || !editing} />
        </div>

        <div className='col-5'></div>

        {Boolean(values?.to_patient) &&
          <div className='col-4 text-end'>
            <Button type='button' variant='primary' onClick={() => setPatientModal(true)} style={{ marginTop: '20px' }} disabled={loading}>
              <i className='bi bi-plus-circle' />
              Crear paciente
            </Button>
          </div>
        }
      </div>

      <div className='row mb-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='requires_coordination' label={'Destinatario'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='requires_coordination' disabled={loading || !editing} />
              <Label htmlFor='requires_coordination' label={'¿Requiere coordinación?'} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='to_patient' disabled={loading || !editing}/>
              <Label htmlFor='to_patient' label={'¿Entrega a paciente?'} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='remito_para_terceros' disabled={loading || !editing}/>
              <Label htmlFor='remito_para_terceros' label={'Remito a nombre de terceros'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col-3'>
          <SelectField
            id='home_delivery_option_id'
            name='home_delivery_option_id'
            label='Entrega especial'
            options={homeDeliveryOptions}
            disabled={loading || !editing}
          />
        </div>

        {/* Selección del paciente */}
        {Boolean(values?.to_patient) && (
          <>
            <div className="col-4">
              <Label label={'Paciente'} htmlFor='patient_id' />

              <Typeahead
                id='patient_id'
                name='patient_id'
                minLength={3}
                labelKey={option => option?.display_name}
                options={patients || []}
                filterBy={['id', 'documentation', 'first_name', 'last_name', 'affiliate_number']}
                promptText={'Buscar paciente'}
                searchText='Buscando...'
                placeholder={'Buscar paciente'}
                emptyLabel={'No se encontró el paciente'}
                onChange={changePatient}
                onBlur={e => {
                  if (e.target.value === '') {
                    setFieldValue('patient_id', '')
                    setFieldValue('patient_fullname', '')
                  }
                }}
                className={`${(errors?.patient_id && touched?.patient_id ) ? 'border border-danger' : ''}`}
                defaultInputValue={values?.patient_id ? values?.patient_fullname : ''}
                disabled={loading || !editing}
              />
            </div>

            {values?.patient_id &&
              <div className='col-2' style={{ marginTop: '29px' }}>
                <a href={`${routes.PATIENTS}/${values?.patient_id}`} target="_blank" rel="noopener noreferrer">
                  Ver paciente
                </a>
              </div>
            }
          </>
        )}

        {/* Remito para terceros */}
        {values?.remito_para_terceros &&
          <div className='col-4'>
            {editing
              ?
                <SelectField
                  id='id_tercero_para_remitir'
                  name='id_tercero_para_remitir'
                  options={clientesParaRemitir}
                  placeholder='Seleccionar tercero para remitir'
                  disabled={loading || !editing}
                />
              : <InputBS id='tercero_para_remitir_nombre' name='tercero_para_remitir_nombre' disabled />
            }
          </div>
        }
      </div>

      {/* Dirección traza */}
      <div className='row my-8'>
        <div className='col-12'>
          {editing
            ?
              <SelectField
                id='address_id_traza'
                name='address_id_traza'
                label={(values?.home_delivery_option_id == HomeDeliveryOptionI.NO || values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA)
                  ? 'Dirección de trazabilidad / entrega'
                  : 'Dirección de trazabilidad'
                }
                options={[
                  ...optionsAddressTraza?.map(address => (
                    {
                      value: address.id,
                      label: `${address.name} - ${address.address?.domicilio} ${address.address?.localidad_completa}${address?.gln ? ` | GLN: ${address.gln}` : ''}`
                    }
                  ))
                ]}
                placeholder={(values?.home_delivery_option_id == HomeDeliveryOptionI.NO || values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA)
                  ? 'Seleccione una dirección de entrega'
                  : 'Seleccione una dirección de trazabilidad'
                }
                disabled={loading || !editing}
              />
            :
              <InputBS
                id='direccion_traza'
                name='direccion_traza'
                label={(values?.home_delivery_option_id == HomeDeliveryOptionI.NO || values?.home_delivery_option_id==HomeDeliveryOptionI.FARMACIA)
                  ? 'Dirección de trazabilidad / entrega'
                  : 'Dirección de trazabilidad'
                }
                disabled
              />
          }
        </div>
      </div>

      {/* Modal para crear o editar direccion */}
      <ShipmentAddressModal
        show={addressModal}
        onClose={() => setAddressModal(false)}
        values={patientAddressInfo}
        onSave={patientAddressSubmit}
      />

      {/* Direccion entrega */}
      {(values?.home_delivery_option_id == HomeDeliveryOptionI.CLIENTE || values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE)
        ?
          <div className='row my-8'>
            <div className={editing ? 'col-8' : 'col-12'}>
              {editing
                ?
                  <SelectField
                    id='address_id_ship'
                    name='address_id_ship'
                    label={'Domicilio de entrega'}
                    placeholder='Seleccione un domicilio de entrega'
                    options={optionsAddressShip}
                    disabled={loading || !editing}
                  />
                : <InputBS id='direccion_ship' name='direccion_ship' label={'Domicilio de entrega'} disabled />
              }
            </div>

            {editing && values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE
              ?
                <div className='col-sm-auto pt-6 d-flex flex-wrap'>
                  <Button
                    type='button'
                    variant="secondary"
                    onClick={() => {
                      setPatientAddressInfo(patientAddresses.find(address => address.id == values?.address_id_ship))
                      setAddressModal(true)
                    }}
                    className='me-4'
                    disabled={loading || !values?.address_id_ship || values?.address_id_ship == '' || values?.home_delivery_option_id != HomeDeliveryOptionI.PACIENTE}
                  >
                    Editar dirección
                  </Button>

                  <Button
                    variant="primary"
                    type='button'
                    onClick={() => {
                      setPatientAddressInfo(null)
                      setAddressModal(true)
                    }}
                  >
                    Crear dirección
                  </Button>
                </div>
              :
                <></>
            }
          </div>
        : <></>
      }

      {/* Observaciones */}
      <div className='row my-8'>
        <div className='col-12'>
          <Label htmlFor='observations' label='Observaciones del pedido (se imprime en remito y factura)' />
          {
            !editObservations &&
            (
              // values?.sales_order_status_id == SalesOrderStatusI.BORRADOR ||
              values?.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
              values?.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
            ) && currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.CONTROL_RENTAS || r.id == RoleI.CONTROL_CRED)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setEditObservations(editObservations ? false : true)
                }}
              />
          }

          <Textarea id='observations' name='observations' disabled={loading || !editing} />
        </div>
      </div>

      <Modal show={editObservations} onClose={() => setEditObservations(false)} obtenerPedido={obtenerPedido} />

      <div className='row mb-8'>
        <div className='col-12'>
        <Label htmlFor='dispatch_observations' label='Observaciones despacho (se imprime en guía de despacho)' />
          {
            !editDispatchObservations &&
            (
              // values?.sales_order_status_id == SalesOrderStatusI.BORRADOR ||
              values?.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
              values?.sales_order_status_id == SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
            ) &&
            currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.CONTROL_RENTAS || r.id == RoleI.CONTROL_CRED || r.id == RoleI.VENTAS || r.id == RoleI.VENTAS_GTE)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setEditDispatchObservations(editDispatchObservations ? false : true)
                }}
              />
          }

          <Textarea id='dispatch_observations' name='dispatch_observations' disabled={loading || !editing} />
        </div>
      </div>

      <Modal dispatch={true} show={editDispatchObservations} onClose={() => setEditDispatchObservations(false)} obtenerPedido={obtenerPedido} />
    </>
  )
}

export default TabDestinatario