import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Table } from "@mui/material"

import { currencyFormat } from "utilities"

const opcionesIVA = [
  { value: 1, label: 'No Grav.', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]

const COLUMNAS_ARTICULOS = [
  {
    Header: <p className="m-0 p-0">Artículo</p>,
    id: 'product_var_desc',
    accessor: (row) => (
      <p className="m-0 p-0">{row.product_var_desc}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Precio base</p>,
    id: 'actual_pvp',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{currencyFormat.format(row.actual_pvp) || ''}</p>
    )
  },
  {
    Header: '',
    id: 'alerts',
    className:'p-0',
    accessor: (row) => (
      <>
        {row.por_unidades
          ? (+row.actual_pvp != +row.pvp_original / row.qty)
          : (+row.actual_pvp != +row.pvp_original)
            ?
              <OverlayTrigger placement="left"
                overlay={
                  <Tooltip id="tooltip">
                    <div>Precio base distinto al PVP</div>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Precio base</th>
                          <th>PVP</th>
                        </tr>
                      </thead>

                      <tbody>
                          <tr>
                            <td>{parseFloat(row.actual_pvp).toFixed(2)}</td>
                            <td>{parseFloat(row.pvp_original).toFixed(2)}</td>
                          </tr>
                      </tbody>
                    </Table>
                  </Tooltip>
                }
              >
                <span>
                  <i className='bi bi-exclamation-triangle text-danger' />
                </span>
              </OverlayTrigger>
            : <></>
        }
      </>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Costo lista</p>,
    id: 'list_cost',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{currencyFormat.format(row.list_cost) || ''}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Descuento</p>,
    id: 'discount',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{parseFloat(row.discount).toFixed(5) || ''} %</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">P. caja</p>,
    id: 'price',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{currencyFormat.format(row.price) || ''}</p>
    )
  },
  {
    Header: <p className="m-0 p-0text-center">Rentabilidad</p>,
    id: 'profitability_rate',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{parseFloat(row.profitability_rate).toFixed(2) || ''} %</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Cajas</p>,
    id: "qty",
    accessor: (row) => (
      <div className="text-center">
        <p className='m-0 p-0'>{row.qty || ''}</p>
        <p className='m-0 p-0'>{row.qty_label || ''}</p>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Unidades</p>,
    id: "units_total",
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.units_total || ''}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Subtotal</p>,
    id: 'subtotal',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{currencyFormat.format(row.subtotal) || ''}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">IVA</p>,
    id: 'vat',
    accessor: (row) => (
      <div className="text-end">
        <p className='m-0 p-0'>{currencyFormat.format(row.vat_amount) || ''}</p>
        <p className='m-0 p-0'>{`(${opcionesIVA.find(v => v.value === row.vat).label})`}</p>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0 text-end">Total</p>,
    id: 'total',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>{currencyFormat.format(row.total)}</p>
    )
  },
]

const COLUMNAS_ARTICULOS_EGRESO = [
  {
    Header: <p className="m-0 p-0">Artículo</p>,
    id: 'product_var_desc',
    accessor: (row) => (
      <p className="m-0 p-0">{row.product_var_desc}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Cajas</p>,
    id: "qty",
    accessor: (row) => (
      <div className="text-center">
        <p className='m-0 p-0'>{row.qty || ''}</p>
        <p className='m-0 p-0'>{row.qty_label || ''}</p>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Unidades</p>,
    id: "units_total",
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.units_total || ''}</p>
    )
  },
]

export {
  COLUMNAS_ARTICULOS,
  COLUMNAS_ARTICULOS_EGRESO
}