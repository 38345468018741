import service from 'services/service';

const PATH = {
  pedidos: '/sales-orders/v2',
  crearPedido: '/sales-orders/v2/crear-pedido',
  almacenamientoPropio: '/sales-orders/almacenamiento-propio'
}

export class Pedidos {
  static obtenerPedidos = async (params) => {
    const response = await service.get(PATH.pedidos, {
      params,
    })

    return response
  }

  static obtenerPedido = async (id) => {
    const response = await service.get(`${PATH.pedidos}/${id}`)
    return response
  }

  static obtenerDetallePedido = async (id, params) => {
    const response = await service.get(`${PATH.pedidos}/${id}/detalle`, {
      params
    })

    return response
  }

  static obtenerPedidoVenta = async (id, params) => {
    const response = await service.get(`${PATH.pedidos}/${id}`, {
      params
    })

    return response
  }

  static obtenerPedidoAlmacenamientoPropio = async (id, params) => {
    const response = await service.get(`${PATH.almacenamientoPropio}`, {
      params,
    })

    return response
  }

  static crearPedido = async (data) => {
    const response = await service.post(PATH.crearPedido, data)
    return response
  }

  static actualizarPedido = async (id, data) => {
    const response = await service.patch(`${PATH.pedidos}/${id}`, data)
    return response
  }

  static actualizarOrdenCompraPedido = async (id, data) => {
    const response = await service.post(`${PATH.pedidos}/${id}/orden-compra`, data)
    return response
  }

  /* DOCUMENTOS */
  static obtenerDocumentosPedido = async (id, params) => {
    const response = await service.get(`${PATH.pedidos}/${id}/documentos`, {
      params
    })
    return response
  }

  static obtenerDocumentoPedido = async (id, fileId) => {
    const response = await service.get(`${PATH.pedidos}/${id}/documentos/${fileId}`)
    return response
  }

  static crearDocumentoPedido = async (id, data) => {
    const response = await service.post(`${PATH.pedidos}/${id}/documentos`, data)
    return response
  }

  static eliminarDocumentoPedido = async (id, fileId) => {
    const response = await service.delete(`${PATH.pedidos}/${id}/documentos/${fileId}`)
    return response
  }
  /* DOCUMENTOS */

  /* ÓRDENES */
  static obtenerOrdenesEgresoPedido = async (id) => {
    const response = await service.get(`${PATH.pedidos}/${id}/ordenes`)
    return response
  }
  /* ÓRDENES */

  /* COMENTARIOS */
  static obtenerComentariosPedido = async (id) => {
    const response = await service.get(`${PATH.pedidos}/${id}/comentarios`)
    return response
  }

  static crearComentarioPedido = async (id, data) => {
    const response = await service.post(`${PATH.pedidos}/${id}/comentarios`, data)
    return response
  }
  /* COMENTARIOS */
}