import { Link, useNavigate } from 'react-router-dom'
import { Button, Card } from 'react-bootstrap'

import { Loading } from 'components'

const DetailContainerV2 = ({ loading = false, title, statusColor, status, subheader, actions, children }) => {
  const navigate = useNavigate()

  return (
    <Card className="mb-8">
      <Card.Header className='pt-8 pb-5'>
        <div className='w-70'>
          <div>
            <h3 className='me-5 d-inline' style={{ verticalAlign: 'middle' }}>{title}</h3>

            <span className={`badge ${statusColor} justify-content-center fw-normal me-4`} style={{ width: '180px' }}>
              {status}
            </span>
          </div>

          <div>
            {subheader}
          </div>
        </div>

        <div className='w-30'>
          {actions}
        </div>
      </Card.Header>

      <Card.Body className='pt-0'>
        {loading ? <Loading /> : children}
      </Card.Body>
    </Card>
  )
}

export default DetailContainerV2