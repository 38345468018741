import moment from 'moment'

const iconoTipoDocumento = (tipo_documento) => {
  let icon = ''

  switch (tipo_documento) {
    case 'Orden de compra':
      icon = 'bi bi-receipt text-primary'
      break
    case 'Otros':
      icon = 'bi bi-question-square text-warning'
      break
    case 'Receta':
      icon = 'bi bi-prescription2 text-success'
      break
    default:
      icon = ''
      break
  }

  return icon ? `${icon} me-2` : ''
}

const COLUMNS = [
  {
    Header: <p className='m-0 p-0'>Documento</p>,
    id: 'riga_file_asset_name',
    accessor: (row) => (
      <p className='m-0 p-0'>
        {row.riga_file_asset_name}
      </p>
    ),
  },
  {
    Header: <p className='m-0 p-0'>Tipo</p>,
    id: 'document_type_name',
    accessor: (row) => (
      <div className=''>
        <span className={iconoTipoDocumento(row.document_type_name)} />
        {row.document_type_name}
      </div>
    ),
  },
  {
    Header: <p className='m-0 p-0 text-center'>F. carga</p>,
    id: 'riga_file_asset_created_at',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {moment(row.riga_file_asset_created_at).format('DD / MM / YYYY')}
      </p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>F. vencimiento</p>,
    id: 'expiration_date',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row?.expiration_date ? moment(row.expiration_date).format('DD / MM / YYYY') : '-- / -- / ----'}
      </p>
    ),
  },
]

export {
  COLUMNS,
}