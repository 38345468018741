import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'

import { Tooltip, Label, InputBS, SelectField, SwitchField, Input } from 'components'

import { useGeneralData } from 'pages/Sells/common/context'
import EditarOrdenCompraModal from './EditarOrdenCompraModal'

import { RoleI, SalesOrderStatusI } from 'interfaces'

const TabGeneral = ({ loading, creacion = false, editar = false, pedido, obtenerPedido, setTab }) => {
  const { currentUser } = useAuth()

  const { values, setFieldValue, setFieldTouched } = useFormikContext()

  const [editarOrdenCompra, setEditarOrdenCompra] = useState(false)

  const {
    unidadesNegocio,
    loadingBU,
    clientes,
    loadingCS,
    vendedores,
    loadingS,
    condicionesPago,
    loadingPC,
    tiposFacturacion,
    loadingBT,
  } = useGeneralData()

  useEffect(() => {
    setTab('general')
  })

  if (loading) return (
    <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "5px" }}></l-helix>
  )

  return (
    <>
      {!creacion &&
        <div className='row mb-8'>
          <div className='d-flex justify-content-end align-items-center flex-row'>
            <Tooltip text='Usuario creación'>
              <span className='badge estado-gris'>{pedido?.user_name}</span>
            </Tooltip>

            {pedido?.updated_by_user_id &&
              <>
                <i className='bi bi-grip-vertical ms-4 me-4' />

                <Tooltip text='Últ. fecha actualización'>
                  <span className='badge estado-amarillo me-2'>{moment(pedido?.updated_at).format('DD/MM/YYYY')}</span>
                </Tooltip>

                <Tooltip text='Últ. usuario actualización'>
                  <span className='badge estado-amarillo'>{pedido?.update_user_name}</span>
                </Tooltip>
              </>
            }

            {pedido?.cancel_by_user_id &&
              <>
                <i className='bi bi-grip-vertical ms-4' />

                <Tooltip text='Fecha actualización'>
                  <span className='badge estado-rojo me-2'>{moment(pedido?.cancel_at).format('DD/MM/YYYY')}</span>
                </Tooltip>

                <Tooltip text='Usuario cancelación'>
                  <span className='badge estado-rojo'>{pedido?.cancel_user_name}</span>
                </Tooltip>
              </>
            }
          </div>
        </div>
      }

      {creacion &&
        <div className='row mb-8'>
          <div className='col-2'>
            <InputBS id='issue_date' name='issue_date' type='date' label='F. emisión' disabled />
          </div>

          <div className='col-3'>
            <SelectField
              id='business_unit_id'
              name='business_unit_id'
              label='Unidad de negocio'
              options={unidadesNegocio}
              placeholder='Seleccionar'
              disabled={loading || loadingBU}
            />
          </div>

          <div className='col-7'>
            <Label label='Cliente' htmlFor='customer_supplier_id' />

            <Typeahead
              id='customer_supplier_id'
              name='customer_supplier_id'
              minLength={1}
              labelKey={option => `[${option.id}] ${option.business_name}`}
              filters={['id', 'business_name']}
              options={clientes}
              promptText='Buscar cliente'
              searchText='Buscando...'
              placeholder={values.customer_supplier_name ? values.customer_supplier_name : 'Buscar cliente'}
              emptyLabel='No se encontró el cliente'
              onChange={(selected) => {
                if (!selected || selected.length === 0) {
                  setFieldValue('customer_supplier_id', '')
                  setFieldValue('customer_supplier_name', '')
                  setFieldValue('seller_id', '')
                  setFieldValue('payment_condition_id', '')
                  setFieldValue('billing_type_id', '')
                  setFieldValue('price_update_id', '')
                  setFieldValue('de_blister', '')
                } else {
                  const value = selected[0]
                  setFieldValue('customer_supplier_id', value.id)
                  setFieldValue('customer_supplier_name', `[${value.id}] ${value.business_name}`)
                  setFieldValue('seller_id', value?.seller_id && value.seller.is_enabled ? value.seller_id : '')
                  setFieldValue('payment_condition_id', value.payment_condition_id)
                  setFieldValue('billing_type_id', value?.billing_type_id ? value.billing_type_id : '')
                  setFieldValue('price_update_id', value?.price_update_id ? value.price_update_id : '')
                  setFieldValue('de_blister', value?.de_blister ? value.de_blister : '')
                }
              }}
              onBlur={e => setFieldTouched('customer_supplier_id', true)}
              defaultInputValue={values.customer_supplier_name}
              disabled={loading || loadingCS}
            />
          </div>
        </div>
      }

      <div className='row mb-8'>
        <div className='col-6'>
          {editar
            ?
              <SelectField
                id='seller_id'
                name='seller_id'
                label='Vendedor'
                options={vendedores}
                placeholder='Seleccionar'
                disabled={!editar || (loading || loadingS)}
              />
            : <InputBS id='seller_name' name='seller_name' label='Vendedor' readOnly />
          }
        </div>

        <div className='col-6'>
          {editar
            ?
              <SelectField
                id='payment_condition_id'
                name='payment_condition_id'
                label='Condición de pago'
                options={condicionesPago}
                placeholder='Seleccionar'
                disabled={!editar || (loading || loadingPC)}
              />
            : <InputBS id='payment_condition_label' name='payment_condition_label' label='Condición de pago' readOnly />
          }
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-4'>
          <Label htmlFor='purchase_order' label='Nro. Orden de compra' />
          {
            !editarOrdenCompra &&
            pedido.sales_order_status_id !== SalesOrderStatusI.CANCELADO &&
            currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.VENTAS || r.id == RoleI.VENTAS_GTE)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => setEditarOrdenCompra(!editarOrdenCompra)}
              />
          }

          <Input id='purchase_order' name='purchase_order' disabled={!editar || loading} />
        </div>

        <EditarOrdenCompraModal show={editarOrdenCompra} onClose={() => setEditarOrdenCompra(false)} obtenerPedido={obtenerPedido} />

        <div className='col-3'>
          {editar
            ?
              <SelectField
                id='billing_type_id'
                name='billing_type_id'
                label='Facturación'
                options={tiposFacturacion}
                placeholder='Seleccionar'
                disabled={!editar || (loading || loadingBT)}
              />
            : <InputBS id='billing_type_label' name='billing_type_label' label='Facturación' readOnly />
          }
        </div>

        <div className="col-2 d-flex justify-content-start align-items-center" style={{ paddingTop: "20px" }}>
          <div className="d-flex">
            <SwitchField id='bidding' name='bidding' disabled={!editar || loading} />

            <span style={{ fontSize: '1.1rem' }}>
              Licitación
            </span>
          </div>
        </div>

        {Boolean(values.bidding) &&
          <div className='col-3'>
            <InputBS id='bidding_value' name='bidding_value' label='Nro. Licitación' placeholder='Licitación' disabled={!editar || loading} />
          </div>
        }
      </div>
    </>
  )
}

export default TabGeneral