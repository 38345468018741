import * as Yup from 'yup'
import moment from 'moment'

const initialValues = {
  business_unit_id: 1,
  customer_supplier_id: '',
  customer_supplier_name: '',
  seller_id: '',
  payment_condition_id: '',
  issue_date: moment().format('YYYY-MM-DD'),
  bidding: false,
  bidding_value: '',
  purchase_order: '',
  billing_type_id: 1,
  billing_date: '',
}

const schema = Yup.object().shape({
  business_unit_id: Yup.number().required('* Seleccione una unidad de negocio').nonNullable(),
  customer_supplier_id: Yup.number().required('* Seleccione un cliente').nonNullable(),
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  issue_date: Yup.string().required('* Ingrese una fecha de emisión').nonNullable(),
  billing_type_id: Yup.number().required('* Seleccione un tipo de facturación').nonNullable(),
})

const submitAdapter = (values) => {
  let data = {
    ...values,
    billing_date: values?.billing_date && values.billing_date !== '' ? values.billing_date : null,
    purchase_order: values?.purchase_order ? values.purchase_order : null,
    de_blister: Boolean(values?.de_blister),
  }
  delete data.customer_supplier_name

  return data
}

export {
  initialValues,
  schema,
  submitAdapter,
}