import { useState, useMemo, useCallback, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useReports } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import Filter from './Filter'
import { COLUMNS } from './columns'

const ESTADOS_ORDEN = Object.freeze({
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13
})

export const labelColor = (order_status_id) => {
  switch (order_status_id) {
    case ESTADOS_ORDEN.GENERADO:
    case ESTADOS_ORDEN.PENDIENTE_INGRESO:
    case ESTADOS_ORDEN.INGRESO_PARCIAL:
      return "estado-amarillo";
    case ESTADOS_ORDEN.BORRADOR:
      return "estado-gris";
    case ESTADOS_ORDEN.COMPLETO:
    case ESTADOS_ORDEN.COMPLETO_PARCIAL:
    case ESTADOS_ORDEN.AUTORIZADO:
      return 'estado-verde';
    case ESTADOS_ORDEN.CONFIRMADO:
    case ESTADOS_ORDEN.INGRESADO:
      return "estado-azul";
    case ESTADOS_ORDEN.VENCIDO:
    case ESTADOS_ORDEN.CANCELADO:
    case ESTADOS_ORDEN.RECHAZADO:
    case ESTADOS_ORDEN.ERROR_TRAZA:
      return "estado-rojo";
    default:
      return 'estado-gris';
  }
}

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  id_estado_ingreso: [ESTADOS_ORDEN.INGRESADO],
  fecha_desde: moment().subtract(1, 'M').format('YYYY-MM-DD'),
  fecha_hasta: moment().format('YYYY-MM-DD'),
}

const IncomesReport = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetIncomesReport } = useReports()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getReport = useCallback(async () => {
    try {
      let params = {
        id_estado_ingreso: [ESTADOS_ORDEN.INGRESADO],
      }

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.reporte_ingresos) {
        const { reporte_ingresos } = currentUser

        params = {
          ...params,
          id_orden_ingreso: reporte_ingresos?.id_orden_ingreso || '',
          fecha_desde: reporte_ingresos?.fecha_desde || '',
          fecha_hasta: reporte_ingresos?.fecha_hasta || '',
          id_pedido_compra: reporte_ingresos?.id_pedido_compra || '',
          id_estado_ingreso: reporte_ingresos?.id_estado_ingreso || [ESTADOS_ORDEN.INGRESADO],
          proveedor: reporte_ingresos?.proveedor || '',
          proveedor_documento: reporte_ingresos?.proveedor_documento || '',
          distribuidor: reporte_ingresos?.distribuidor || '',
          distribuidor_documento: reporte_ingresos?.distribuidor_documento || '',
          articulo: reporte_ingresos?.articulo || '',
          lote: reporte_ingresos?.lote || '',
        }
      }

      const response = await handleGetIncomesReport(params)
      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.id_estado)
      }))

      setData(data);
      setTotalCount(response.metadata.count);
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetIncomesReport, queryMD, currentUser])

  const getExport = async (query) => {
    try {
      let params = {
        ...queryMD,
        ...query,
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetIncomesReport(params)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Reporte de Ingresos'
        filter={<Filter setData={setData} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} getReport={getReport} getExport={getExport}/>}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data}  paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>
    </>
  )
}

export default IncomesReport