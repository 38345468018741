import { Link } from "react-router-dom"

import routes from "app/routing/routes"

import { OutOrderStatusI } from "interfaces"

const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste"
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul"
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo"
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo"
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde"
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja"
    default:
      return ""
  }
}

const COLUMNS = [
  {
    Header: 'Nro.',
    id: 'id',
    accessor: (row) => (
      <Link to={`${routes.OUT_ORDERS}/${row.id}`}>
        {row.id}
      </Link>
    ),
  },
  {
    Header: <div className=''>Estado</div>,
    id: 'out_order_status_id',
    accessor: (row) => (
      <span className={`badge ${labelColor(row.out_order_status_id)} w-80 justify-content-center m-0 fw-normal`}>
        {row.out_order_status_label}
      </span>
    ),
  },
  {
    Header: 'Ola',
    id: 'wave_id',
    accessor: (row) => (
      <>
        {row?.wave_id
          ? <Link to={`${routes.WAVES}/${row.wave_id}`}>{row.wave_name}</Link>
          : 'Sin Ola asignada'
        }
      </>
    )
  }
]

export {
  COLUMNS
}