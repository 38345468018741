import { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useSalesOrders } from 'hooks'

const AuthModal = ({ auth = true, show, onClose, selectedRows, handleSelectedRows, handleGetSalesOrders }) => {
  const { loading, handleAuthMultipleCredit, handleUnauthMultipleCredit } = useSalesOrders()
  const [observaciones, setObservaciones] = useState('')

  const multipleAuth = async () => {
    let pedidos = selectedRows

    const pedidosOrdenados = [...pedidos].sort((a, b) => {
      const dateA = new Date(a.fechaAutorizacion).getTime()
      const dateB = new Date(b.fechaAutorizacion).getTime()

      return dateA - dateB
    })

    try {
      await handleAuthMultipleCredit(pedidosOrdenados)

      toast.success('Pedidos autorizados correctamente')
      handleSelectedRows([])
      setObservaciones('')
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrders()
      onClose()
    }
  }

  const multipleUnauth = async () => {
    let pedidos = selectedRows

    const pedidosOrdenados = [...pedidos].sort((a, b) => {
      const dateA = new Date(a.fechaAutorizacion).getTime()
      const dateB = new Date(b.fechaAutorizacion).getTime()

      return dateA - dateB
    })

    try {
      const data = {
        sales_orders: pedidosOrdenados,
        observaciones: observaciones
      }
      await handleUnauthMultipleCredit(data)

      toast.success('Pedidos no autorizados correctamente')
      handleSelectedRows([])
      setObservaciones('')
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrders()
      onClose()
    }
  }

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='md' backdrop='static' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>{`${auth ? 'Autorización' : 'Desautorización'} múltiple de crédito`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            <ul className='list-style-none m-0 p-0'>
              {selectedRows.sort((a, b) => new Date(a.fechaAutorizacion).getTime() - new Date(b.fechaAutorizacion).getTime()).map(r => (
                <li key={r.PedidoId} className='mb-3'>
                  Pedido #{r.PedidoId} -- F. Envío Autorización: {moment(r.fechaAutorizacion).format('DD/MM/YYYY HH:mm')}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {!auth &&
          <div className='row mt-8'>
            <div className='col'>
              <Form.Group controlId='observaciones'>
                <Form.Label>Motivo</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  placeholder='Completar motivo de rechazo'
                  value={observaciones}
                  onChange={e => setObservaciones(e.target.value)}
                  disabled={loading}
                />
              </Form.Group>
            </div>
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col text-end'>
            <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cerrar
            </Button>

            <Button type='button' variant='primary' onClick={auth ? multipleAuth : multipleUnauth} disabled={loading}>
              <i className='bi bi-save me-2' />
              {auth ? 'Autorizar' : 'Desautorizar'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AuthModal