import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useSalesOrders, useClickOutside } from 'hooks'

import { CustomTable, SelectField, SwitchField } from 'components'
import ContextMenu from 'components/ContextMenu'

import ArticlesModal from './ArticlesModal'
import ArticleItemModal from './ArticleItemModal'
import TotalSummaryTable from './TotalSummaryTable'

import { OrderTypeI, SalesOrderStatusI } from 'interfaces'

const detailDataAdapter = (data) => {
  const detail = data.map(article => {
    return {
      id: article.id,
      product_var_id: article.product_var_id,
      product_var_desc: article.name,
      line: article?.line ? article.line : '',
      actual_pvp: article.pvp,
      list_cost: article.list_cost,
      qty: article.qty,
      units: article.units,
      units_total: article.qty * article.units,
      discount: Number(article.discount),
      price: article.price,
      profitability: article.profitability,
      profitability_rate: article.profitability_rate,
      vat: article.vat,
      vat_rate: article.vat_rate,
      vat_amount: article.vat_amount,
      total: article.total ,
      subtotal: article.subtotal,
      outdated_pvp: Boolean(article.outdated_pvp),
      pvp_original: article.pvp_original
    }
  })

  return detail
}

const filterNewArticles = (detail, newArticles) => {
  const ids = detail.map(d => d.product_var_id)

  const newFilteredArticles = newArticles.filter((newArticle) => !ids.includes(newArticle.id))

  return newFilteredArticles
}

const TabArticulos = ({ loading, editar, pedido, obtenerPedido, setTab, columnas = [], serviceArticulos }) => {
  const { id } = useParams()
  const { values } = useFormikContext()

  const { loading: loadingArt, obtenerDetalle, loadingActuPrecio, obtenerActualizacionesPrecio } = serviceArticulos
  const [detalle, setDetalle] = useState([])

  const { loading: loadingActions, handleAddDetail, handleUpdateDetail, handleUpdatePrice, handleDeleteDetail } = useSalesOrders()
  const [actualizacionesPrecio, setActualizacionesPrecio] = useState([])

  const [modal, setModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const handleObtenerDetalle = useCallback(async () => {
    try {
      const response = await obtenerDetalle(id)
      const data = detailDataAdapter(response.data.result)

      setDetalle(data)
    } catch (error) {
      setDetalle([])
    }
  }, [obtenerDetalle])

  const handleObtenerActualizacionesPrecio = useCallback(async () => {
    try {
      const data = await obtenerActualizacionesPrecio()
      setActualizacionesPrecio(data)
    } catch (error) {
      setActualizacionesPrecio([])
    }
  }, [obtenerActualizacionesPrecio])

  const handleCloseModal = () => { setModal(false) }

  const handleShowModal = () => { setModal(true) }

  const handleCloseDetailModal = () => {
    setDetailModal(false)
    obtenerPedido()
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (pedido.sales_order_status_id === SalesOrderStatusI.BORRADOR) {
      actions.push({
        label: "Editar",
        icon: "bi bi-pencil-square text-primary",
        action: () => {
          setDetailData(currentRow)
          setDetailModal(true)
        }
      })

      actions.push({
        label: "Eliminar detalle",
        icon: "bi bi-trash text-danger",
        action: () => handleDelete(currentRow.id)
      })
    }

    return actions
  }

  const handleDelete = async (idDetalle) => {
    const resp = await Swal.fire({
      title: "Advertencia",
      text: "¿Confirma la eliminación del artículo del pedido?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, quitar del pedido",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      try {
        await handleDeleteDetail(id, idDetalle, 'token')

        toast.success("Artículo eliminado del pedido")
      } catch (err) {
        toast.error(err.message)
      } finally {
        obtenerPedido()
        handleObtenerDetalle()
      }
    }
  }
  /* Fin menu contextual */

  const handleSave = async (value) => {
    if (value.articles.length <= 0) {
      toast.error('Debe seleccionar un artículo')
      return
    }

    let articles = filterNewArticles(detalle, value.articles)
    if (articles.length <= 0) {
      toast.error('No se seleccionaron artículos nuevos')
      return
    }

    try {
      await handleAddDetail(id, { detail: articles })
      toast.success("Registro agregado correctamente")
    } catch (error) {
      toast.error(error.message)
    } finally {
      obtenerPedido()
      handleObtenerDetalle()
    }
  }

  const handleUpdate = async (idDetalle, data) => {
    try {
      let detail = {
        product_var_id: data.product_var_id,
        name: data.name,
        line: data?.line ? data.line : null,
        pvp: Number(data.precio_base),
        list_cost: Number(data.list_cost),
        qty: Number(data.qty),
        units: Number(data.units),
        discount: Number(data.discount),
        price: Number(data.unit_price),
        profitability: Number(data.profitability),
        profitability_rate: data.profitability_rate,
        vat: data.vat,
        vat_rate: Number(data.vat_rate),
        vat_amount: Number(data.vat_amount),
        subtotal: Number(data.subtotal),
        total: Number(data.total),
      }

      await handleUpdateDetail(id, idDetalle, { detail: detail })

      toast.success("Registro actualizado correctamente")
      handleCloseDetailModal()
    } catch (error) {
      toast.error(error.message)
    } finally {
      obtenerPedido()
      handleObtenerDetalle()
    }
  }

  const columns = useMemo(() => {
    let col = [...columnas]

    // Verifica si algún objeto en detalle tiene outdated_pvp == 1
    const hasOutdatedPVP = detalle && detalle.length > 0 && detalle.some(detail => Boolean(detail.outdated_pvp))

    if (pedido.sales_order_status_id == SalesOrderStatusI.BORRADOR && availableActions().length > 0) {
      col.push({
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
      })
    }

    return col
  }, [columnas, detalle, pedido])

  useEffect(() => {
    handleObtenerDetalle()
  }, [handleObtenerDetalle])

  useEffect(() => {
    handleObtenerActualizacionesPrecio()
  }, [handleObtenerActualizacionesPrecio])

  useEffect(() => {
    handleObtenerDetalle()
  }, [values.por_unidades])

  useEffect(() => {
    setTab('articulos')
  }, [])

  return (
    <>
      <ArticlesModal show={modal} onClose={handleCloseModal} onSave={handleSave} pedido={pedido} />

      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label='Actualización de precios'
            options={actualizacionesPrecio}
            placeholder='Seleccionar'
            disabled={true}
          />
        </div>

        <div className="col-3" style={{ marginLeft: "20px", paddingTop: "28px", paddingBottom: "25px" }}>
          <div className="d-flex">
            <SwitchField  id='por_unidades' name='por_unidades' disabled={loading || !editar} />
            <span style={{ fontSize: '1.1rem' }}>Por unidad</span>
          </div>
        </div>

        <div className="col-3" style={{ paddingTop: "28px", paddingBottom: "25px" }}>
          <div className="d-flex">
            <SwitchField  id='de_blister' name='de_blister' disabled={loading || !editar} />
            <span style={{ fontSize: '1.1rem' }}>Destroquelar</span>
          </div>
        </div>
      </div>


      <div className='row mb-8 align-center'>
        <div className='col text-end my-8' style={{ flex: 'none' }}>
          {pedido?.sales_order_status_id == SalesOrderStatusI.BORRADOR &&
            <Button
              variant='primary'
              onClick={handleShowModal}
              className=""
              disabled={loading}
            >
              <i className="bi bi-plus-lg pe-2" />
              Añadir artículo
            </Button>
          }
        </div>

        <CustomTable columns={columns} data={detalle || []} handleContextMenu={handleContextMenu} />

        <ContextMenu
          ref={contextRef}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
        />

        <ArticleItemModal
          show={detailModal}
          onHide={handleCloseDetailModal}
          detail={detailData}
          handleUpdate={handleUpdate}
        />

        {
          pedido.order_type_id !== OrderTypeI.PEDIDO_EGRESO &&
          detalle.length > 0 &&
          <TotalSummaryTable editar={editar} pedido={pedido} />
        }
      </div>
    </>
  )
}

export default TabArticulos