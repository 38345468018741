import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AccountBoxOutlined, AssignmentOutlined, FileOpenOutlined, Inventory2Outlined, LocalShippingOutlined, PendingActionsOutlined, ReceiptLongOutlined, TextsmsOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify'

import { usePedidos } from 'hooks'

import { DetailContainerV2, TabsContainer } from 'containers'

import { GeneralDataProvider } from 'pages/Sells/common/context'
import { TabGeneral, TabArticulos, TabDestinatario, TabDocumentos, TabOrdenes, TabComprobantes, TabPendiente, TabComentarios } from 'pages/Sells/common/tabs'
import { salesOrderStatusColor } from 'pages/Sells/common/utils'

import Actions from './Actions'
import Subheader from './Subheader'
import { useArticulos } from './services/articulos'
import { COLUMNAS_ARTICULOS, COLUMNAS_ARTICULOS_EGRESO } from './columnasArticulos'
import { useDocumentos } from './services/documentos'
import { useComentarios } from './services/comentarios'
import { useOrdenes } from './services/ordenes'
import { useComprobantes } from './services/comprobantes'

import { initialValues, schema, dataAdapter, updateDataAdapter } from './form'
import { OrderTypeI, SalesOrderStatusI } from 'interfaces'

const tituloDetalle = (order_type_id) => {
  switch (order_type_id) {
    case OrderTypeI.PEDIDO_VENTA:
      return 'de venta'
    case OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO:
      return 'con almac. propio'
    case OrderTypeI.PEDIDO_EGRESO:
      return 'de egreso'
    default:
      return 'SIN DEFINIR'
  }
}

const DetallePedido = () => {
  const { id } = useParams()

  const [editar, setEditar] = useState(false)
  const [tab, setTab] = useState('general')

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const serviceArticulos = useArticulos()
  // const serviceDestinatario = useDestinatario()
  const serviceDocumentos = useDocumentos()
  const serviceOrdenes = useOrdenes()
  const serviceComprobantes = useComprobantes()
  const serviceComentarios = useComentarios(usePedidos)

  const { loading, handleObtenerPedido, handleObtenerDetallePedido, handleActualizarPedido } = usePedidos()
  const [pedido, setPedido] = useState(initialValues)

  const obtenerPedido = useCallback(async () => {
    try {
      const [response, responseDetail] = await Promise.all([
        handleObtenerPedido(id),
        handleObtenerDetallePedido(id)
      ])

      const data = response.data.result
      const detail = responseDetail?.data?.result || []

      let enrichedData = dataAdapter({
        ...data,
        subtotal: detail && detail.reduce((acc, el) => parseFloat(acc) + parseFloat(el.subtotal), 0),
        iva: detail && detail.reduce((acc, el) => parseFloat(acc) + parseFloat(el.vat_amount), 0),
        total_calc: detail && detail.reduce((acc, el) => parseFloat(acc) + parseFloat(el.total), 0) + (data.iibb?.total || 0),
        total_profitability_rate: detail && (1 - (detail.reduce((acc, el) => parseFloat(acc) + (parseFloat(el.list_cost) * +el.qty), 0) / detail.reduce((acc, el) => parseFloat(acc) + (parseFloat(el.price) * +el.qty), 0))) * 100,
        iibb: {
          ...data.iibb,
          percepciones: data.iibb.percepciones?.map(p => ({
            ...p,
            provincia: p.provincia == 'Ciudad Autónoma de Buenos Aires' ? 'CABA' : p.provincia
          }))
        },
        sales_order_status_color: salesOrderStatusColor(data.sales_order_status_id)
      })

      setPedido(enrichedData)
    } catch (error) {
      setPedido(initialValues)
      toast.error(error.message)
    }
  }, [id, handleObtenerPedido, handleObtenerDetallePedido])

  useEffect(() => {
    obtenerPedido()
  }, [obtenerPedido])

  useEffect(() => {
    setTab('general')
  }, [])

  return (
    <GeneralDataProvider>
      <DetailContainerV2
        title={loading ? 'Cargando...' : `Detalle de Pedido ${tituloDetalle(pedido?.order_type_id)} #${id}`}
        statusColor={pedido?.sales_order_status_color}
        status={pedido?.sales_order_status_label}
        subheader={<Subheader pedido={pedido} />}
        actions={
          <Actions
            loading={loading}
            pedido={pedido}
            editar={editar}
            setEditar={setEditar}
            submitRef={submitRef}
            cancelRef={cancelRef}
            obtenerPedido={obtenerPedido}
          />
        }
      >
        <Formik
          initialValues={pedido}
          validationSchema={schema}
          onSubmit={async (data) => {
            try {
              const values = updateDataAdapter(data)
              console.log('[update]: ', values)

              const response = await handleActualizarPedido(id, values)
              toast.success(response.data.message)
            } catch (error) {
              toast.error(error.message)
            } finally {
              obtenerPedido()
              setEditar(false)
            }
          }}
          enableReinitialize
        >
          {({ values, errors, resetForm }) => {
            if (Object.keys(errors).length > 0) {
              console.log(errors)
            }

            return (
              <Form>
                <TabsContainer
                  initialValue={tab}
                  tabs={[
                    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true, disabled: loading },
                    { value: 'articulos', label: 'Artículos', icon: <Inventory2Outlined />, show: true, disabled: loading },
                    { value: 'destinatario', label: 'Destinatario', icon: <AccountBoxOutlined />, show: [OrderTypeI.PEDIDO_VENTA, OrderTypeI.PEDIDO_EGRESO].includes(pedido?.order_type_id), disabled: loading },
                    { value: 'documentos', label: 'Documentos', icon: <FileOpenOutlined />, show: true, disabled: loading },
                    { value: 'ordenes', label: 'Órdenes', icon: <LocalShippingOutlined />, show: ![SalesOrderStatusI.BORRADOR, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(pedido?.sales_order_status_id), disabled: loading },
                    { value: 'comprobantes', label: 'Comprobantes', icon: <ReceiptLongOutlined />, show: ![SalesOrderStatusI.BORRADOR, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(pedido?.sales_order_status_id), disabled: loading },
                    // { value: 'pendiente', label: 'Comprobantes', icon: <PendingActionsOutlined />, show: [SalesOrderStatusI.RESERVA_PARCIAL, SalesOrderStatusI.FALTA_STOCK].includes(pedido?.sales_order_status_id), disabled: loading },
                    { value: 'comentarios', label: 'Comentarios', icon: <TextsmsOutlined />, show: true, disabled: loading }
                  ]}
                  tabsContent={[
                    <TabGeneral loading={loading} creacion={false} editar={editar} pedido={pedido} obtenerPedido={obtenerPedido} setTab={setTab} />,
                    <TabArticulos
                      loading={loading}
                      editar={editar}
                      pedido={pedido}
                      obtenerPedido={obtenerPedido}
                      setTab={setTab}
                      columnas={pedido.order_type_id == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO ? COLUMNAS_ARTICULOS : COLUMNAS_ARTICULOS_EGRESO}
                      serviceArticulos={serviceArticulos}
                    />,
                    <TabDestinatario loading={loading} editing={editar} obtenerPedido={obtenerPedido} setTab={setTab} />,
                    <TabDocumentos editar={pedido.sales_order_status_id !== SalesOrderStatusI.CANCELADO} setTab={setTab} serviceDocumentos={serviceDocumentos} />,
                    <TabOrdenes setTab={setTab} serviceOrdenes={serviceOrdenes} />,
                    <TabComprobantes setTab={setTab} serviceComprobantes={serviceComprobantes} />,
                    // <TabPendiente />,
                    <TabComentarios setTab={setTab} serviceComentarios={serviceComentarios} />,
                  ]}
                />

                {editar &&
                  <>
                    <div className='separator my-8' />

                    <div className='col text-end'>
                      <Button
                        ref={cancelRef}
                        type='button'
                        variant='secondary'
                        onClick={() => {
                          resetForm()
                          setEditar(false)
                        }}
                        className='me-3'
                        disabled={loading}
                      >
                        <i className='bi bi-slash-circle' />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} type='submit' variant='primary' disabled={loading}>
                        <i className={`bi bi-${loading ? 'arrow-clockwise' : 'save me-1'}`} />
                        {loading ? 'Cargando' : 'Guardar'}
                      </Button>
                    </div>
                  </>
                }
              </Form>
            )
          }}
        </Formik>
      </DetailContainerV2>
    </GeneralDataProvider>
  )
}

export default DetallePedido