import { useState, useCallback, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'

import { useOutOrders, usePriceUpdate } from 'hooks'
import { CustomTable, FormTable, SelectField, Tooltip } from 'components'
import { columnsSellOrder } from 'pages/Sells/Orders/Detail/constants'
import TotalSummaryTable from 'pages/Sells/Orders/Detail/partials/TotalSummaryTable'
import { OrderTypeI } from 'interfaces'

const ArticlesTab = ({ editing, handleEditing, handleGetOutOrder }) => {

  const { values, handleReset } = useFormikContext()
  const { handleGetPriceUpdates } = usePriceUpdate()
  const [priceUpdate, setPriceUpdate] = useState([])

  const getPriceUpdate = useCallback(async () => {
    try {
      const response = await handleGetPriceUpdates()

      setPriceUpdate(response.data.result.map(item => ({ value: item.id, label: item.label })))
    } catch (error) {
      setPriceUpdate([])
      toast.error(error.message)
    }
  }, [handleGetPriceUpdates])


  const columns = [
    ...columnsSellOrder.filter(col=>{
      if(col.id=='actual_pvp' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='list_cost' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='discount' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='price' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='profitability_rate' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='subtotal' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='vat' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      if(col.id=='total' && values.order_type_id==OrderTypeI.PEDIDO_EGRESO) return false
      return true
    }),
    {
      Header: "",
      id: 'aviso',
      accessor: (row) => (
        <div className='d-flex'>
          {Boolean(row.returned_qty>0) && <Tooltip text={`Cant. devuelta: ${row.returned_qty}`}><span className='bi bi-exclamation-circle text-danger fs-3 me-2' /></Tooltip>}
        </div>
      )
    },
  ]


  useEffect(() => {
    getPriceUpdate()
  }, [getPriceUpdate])

  return (
    <>
      <div className='row mb-8'>
        <div className='col-4'>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label='Actualización de precios'
            options={priceUpdate}
            // disabled={!editing}
            disabled={true}
          />
        </div>
      </div>

      <div className=' mb-8'>
        <div className='d-flex justify-content-between align-items-center mb-8' >
          <label className=' form-label m-0'>Artículos</label>
        </div>

        <CustomTable columns={columns} data={values?.detail} />

        {values.detail.length > 0 && values.order_type_id!=OrderTypeI.PEDIDO_EGRESO &&
          <div className='mt-8'><TotalSummaryTable editing={editing} /></div>}
      </div>
    </>
  )
}

export default ArticlesTab