import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AssignmentOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify'

import { usePedidos } from 'hooks'

import { DetailContainerV2, TabsContainer } from 'containers'

import { GeneralDataProvider } from 'pages/Sells/common/context'
import { TabGeneral } from 'pages/Sells/common/tabs'

import { initialValues, schema, submitAdapter } from './form'

import { OrderTypeI } from 'interfaces'
import routes from 'app/routing/routes'

const tituloCreacion = (almacenamiento, egreso) => {
  return almacenamiento
    ? 'con almac. propio'
    : egreso
      ? 'de egreso'
      : 'de venta'
}

const CrearPedido = ({ almacenamiento = false, egreso = false }) => {
  const navigate = useNavigate()

  const [tab, setTab] = useState('general')

  const { loading, handleCrearPedido } = usePedidos()

  const handleSubmit = async (values) => {
    try {
      const data = {
        ...submitAdapter(values),
        order_type_id: almacenamiento
          ? OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO
          : egreso
            ? OrderTypeI.PEDIDO_EGRESO
            : OrderTypeI.PEDIDO_VENTA,
      }
      const response = await handleCrearPedido(data)

      toast.success(response.data.message)
      navigate(`${routes.VENTAS_PEDIDOS}/${response.data.id}`, { tab: 'general' })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    setTab('general')
  }, [])

  return (
    <GeneralDataProvider>
      <DetailContainerV2 title={`Nuevo pedido ${tituloCreacion(almacenamiento, egreso)}`}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors }) => {
            if (Object.keys(errors).length > 0) {
              console.log(errors)
            }

            return (
              <Form>
                <TabsContainer
                  initialValue={tab}
                  tabs={[{ value: 'general', label: "General", icon: <AssignmentOutlined />, show: true }]}
                  tabsContent={[<TabGeneral loading={loading} creacion={true} editar={true} setTab={setTab} />]}
                />

                <div className='separator my-8' />

                <div className='col text-end'>
                  <Button type='submit' variant='primary'>
                    <i className={`bi bi-${loading ? 'arrow-clockwise' : 'save me-1'}`} />
                    {loading ? 'Cargando' : 'Guardar'}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainerV2>
    </GeneralDataProvider>
  )
}

export default CrearPedido