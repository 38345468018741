import { useState, useRef, useEffect } from "react"
import { Button, FormLabel, Modal } from "react-bootstrap"
import Select from 'react-select'
import moment from 'moment'

import { useLogisticStock } from "hooks"

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '43px',
    height: '43px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
}

export function ArticleLotNumberModal({ loading, show, onHide, movement }) {
  const [articleData, setArticleData] = useState({})
  const [optionArticles, setOptionArticles] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [errorQty, setErrorQty] = useState(false)
  const {handleGetStockList, handleGetStockDetail} = useLogisticStock()
  const [optionsLotNumber, setOptionsLotNumber] = useState([])

  const lotRef = useRef(null)

  const handleChange = (e) => {
    let { name, value } = e.target

    if (name == 'qty') {
      value = value.replace(/\D/g, '')
    }

    setArticleData({
      ...articleData,
      [name]: value
    })
  }

  const onChangeArticle = async (option) => {
    lotRef?.current?.clearValue()

    setSubmitted(false)

    const params = {
      product_var_id: option.value,
      storage_space_id: movement.storage_space_id_origin,
      customer_supplier_id: movement?.origin_customer_supplier_id,
      business_unit_id: movement?.origin_business_unit_id,
      // only_available: 1,
      a: 1,
      na: movement?.origin_inventory_status_id == 2 ? 1 : undefined,  // CUARENTENA
      r: movement?.origin_inventory_status_id == 2 ? undefined : 1,   // CUARENTENA
    }
    const response = await handleGetStockDetail(params);

    setOptionsLotNumber(response.result.map(d => ({
      value: d.lot_number,
      // label: d.lot_number + (d.serial_number ? (' - ' + d.serial_number) : '') + ' - Vto:' + (d.expiration_date ? moment(d.expiration_date).format("DD/MM/YYYY") : "") + ' - ' + d.storage_space_name,
      label: `${d?.serial_number ? `Serie: ${d.serial_number} | ` : ''}${d.lot_number} - Vto: ${moment(d?.expiration_date || '').format('DD/MM/YYYY')}`,
      storage_space_inventory_status_id: d.storage_space_inventory_status_id,
      serial_number: d.serial_number,
      expiration_date: d.expiration_date,
      stock_available: d?.stock_available,
      stock_not_available: d?.stock_not_available,
      stock_reserved: d.stock_reserved,
    })))

    setArticleData({
      product_var_id: option.value,
      gtin: option.gtin,
      traced: option.traced,
      qty: option.traced ? '1' : '',
      lot_number: '',
      expiration_date: '',
      serial_number: '',
      stock_available: option.stock_available,
      stock_not_available: option.stock_not_available,
      stock_reserved: option.stock_reserved,
    })
  }

  const fetchArticles = async () => {
    const params = {
      a: 1,
      na: 1,
      r: 1,
      storage_space_id: movement.storage_space_id_origin,
      customer_supplier_id: movement.origin_customer_supplier_id,
      business_unit_id: movement.origin_business_unit_id,
      pageNumber: 1,
      pageSize: undefined,
    }
    const _art = await handleGetStockList(params)

    setOptionArticles(_art.result.map(a => ({
      label: a.name,
      value: a.product_var_id,
      gtin: a.gtin,
      traced: a.traced,
      stock_available: a.stock_available,
      stock_not_available: a.stock_not_available,
      stock_reserved: a.stock_reserved,
    })))
  }

  const handleSubmit = () => {
    setSubmitted(true)
    const { qty, lot_number } = articleData

    if (!qty || !lot_number || qty == '' || lot_number == '' || !articleData?.product_var_id) {
      return
    }

    onHide(articleData)
  }

  useEffect(() => {
    if (show) {
      fetchArticles()
      setArticleData({})
      setOptionArticles([])
      setOptionsLotNumber([])
      setSubmitted(false)
      setErrorQty(false)
    }
  }, [show])

  if (!show) return null

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" backdrop='static' centered>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>Movimiento interno de stock</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col">
              <FormLabel className="d-block">
                Origen: <span className="fw-light">{movement?.origin_name}</span>
              </FormLabel>

              <FormLabel className="d-block">
                Destino: <span className="fw-light">{movement?.dest_name}</span>
              </FormLabel>
            </div>
          </div>

          <div className="row mt-10">
            <div className="col">
              <FormLabel style={labelStyle}>Artículo</FormLabel>
              <Select
                options={optionArticles}
                onChange={onChangeArticle}
                placeholder='Seleccionar artículo'
                styles={customStyles}
                className={`${submitted && (!articleData.product_var_id) ? "border border-danger" : ""}`}
              />
            </div>
          </div>

          <div className="row mt-10">
            <div className="col">
              <FormLabel style={labelStyle}>GTIN</FormLabel>
              <input
                id='gtin'
                type="text"
                value={articleData.gtin || ''}
                className="form-control"
                autoComplete="off"
                disabled={true}
              />
            </div>
          </div>

          <div className="row mt-10">
            <div className="col">
              <label className='form-label'>Lote</label>
              <Select
                ref={lotRef}
                options={optionsLotNumber}
                placeholder='Seleccionar lote'
                onChange={(obj) => {
                  setArticleData({
                    ...articleData,
                    lot_number: obj?.value,
                    serial_number: obj?.serial_number,
                    expiration_date: obj?.expiration_date,
                    stock_available: obj?.stock_available,
                    stock_not_available: obj?.stock_not_available,
                    stock_reserved: obj?.stock_reserved,
                  })}
                }
                styles={customStyles}
                className={`${submitted && (!articleData.lot_number || articleData.lot_number == '') ? "border border-danger" : ""}`}
              />
            </div>
          </div>

          <div className="row mt-10">
            <div className="col">
              <label htmlFor='qty' className='form-label'>Cantidad</label>
              <input
                id='qty'
                name="qty"
                type="text"
                value={articleData.qty || ''}
                onChange={handleChange}
                className={`form-control ${submitted && (!articleData.qty || articleData.qty == '') ? "border border-danger" : ""}`}
                maxLength={5}
                autoComplete="off"
                disabled={articleData.traced}
              />
              {errorQty && <div className='text-danger'>La cantidad no puede ser superior al pendiente</div>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <p className="m-0 p-0">Stock disponible: {
                movement.origin_inventory_status_id == 2
                  ? articleData.stock_not_available
                  : articleData.stock_available
                }
              </p>

              <p className="m-0 p-0">
                Stock reservado: {articleData.stock_reserved}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button type='button' variant="secondary" onClick={() => onHide(null)} className="me-3" disabled={loading}>
            <i className='bi bi-slash-circle' />
            Salir
          </Button>

          <Button type="button" variant="primary" onClick={handleSubmit} disabled={loading}>
            <i className='bi bi-save me-2' />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}