import { OrderTypeI, SalesOrderStatusI } from "interfaces"

export const orderTypeColor = (order_type_id) => {
  switch (order_type_id) {
    case OrderTypeI.PEDIDO_VENTA:
      return 'estado-verde'
    case OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO:
      return 'estado-azul'
    default:
      return 'estado-gris'
  }
}

export const salesOrderStatusColor = (sales_order_status_id) => {
  switch (sales_order_status_id) {
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA:
    case SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO:
    case SalesOrderStatusI.PENDIENTE_PREPARACION:
    case SalesOrderStatusI.PENDIENTE_FACTURACION:
    case SalesOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case SalesOrderStatusI.ENTREGADO:
      return "estado-verde";
    case SalesOrderStatusI.BORRADOR:
      return "estado-gris";
    case SalesOrderStatusI.FALTA_STOCK:
    case SalesOrderStatusI.EN_REVISION:
    case SalesOrderStatusI.NO_AUTORIZADO:
    case SalesOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    case SalesOrderStatusI.EN_DISTRIBUCION:
    case SalesOrderStatusI.EN_PREPARACION:
    case SalesOrderStatusI.EN_DESPACHO:
      return "estado-azul";
    case SalesOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case SalesOrderStatusI.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

export const comprobanteLabel = (status) => {
  if (!status) {
    return ''
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "Aprobado c/observaciones";
    case "SUCCESS":
      return "Aprobado";
    case "DEFERRED":
      return "Factura Diferida";
    case "ERROR_NEGOCIO_AFIP":
      return "Error AFIP";
    case "DISCARDED":
      return "Descartada";
    case "CANCELED":
    case "ANULADO":
      return "Anulado";
    case "ERROR_PROCESAR_COMPROBANTE":
      return 'Error comprobante'
    default:
      return '';
  }
}

export const comprobanteColor = (status) => {
  if (!status) {
    return ""
  }

  switch (status?.toUpperCase()) {
    case "SUCCESS_WITH_OBSERVATIONS":
      return "estado-amarillo";
    case "SUCCESS":
      return "estado-verde";
    case "ERROR_NEGOCIO_AFIP":
    case "ERROR_PROCESAR_COMPROBANTE":
      return "estado-rojo";
    case "DEFERRED":
      return "estado-celeste";
    case "CANCELED":
    case "DISCARDED":
    case "ANULADO":
      return "estado-naranja";
    default:
      return '';
  }
}

export const specialDeliveryOptions = [
  { value: 1, label: 'No'},
  { value: 2, label: 'A paciente'},
  { value: 3, label: 'A cliente'},
  { value: 4, label: 'Farmacia'},
]