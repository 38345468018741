import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { Input, SelectField, SwitchField } from 'components'

const initialFilterValues = {
  business_unit_id: [],
  customer_supplier: '',
  text: '',
  text2: '',
}

const Filter = ({ loading, queryMD, setQueryMD, exportData  }) => {
  const filterData = (values) => {
    const params = {
      ...queryMD,
      ...values,
      business_unit_id: values?.business_unit_id || undefined,
      customer_supplier: values?.customer_supplier || undefined,
      text: values?.text ? values.text : undefined,
      text2: values?.text2 ? values.text2 : undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
  }

  return (
    <Formik
      initialValues={initialFilterValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='business_unit_id'
                name='business_unit_id'
                options={[
                  { value: 1, label: 'FARMA' },
                  { value: 2, label: 'SHOP' },
                ]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
              />
              <p className='text-muted'>Unidad de negocio</p>
            </div>

            <div className='col-8'>
              <Input id='customer_supplier' name='customer_supplier' />
              <p className='text-muted'>Dueño</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input name='text' />
              <p className='text-muted'>ID / Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <Input name='text2' />
              <p className='text-muted'>Laboratorio</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter