import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { useTransactionalDocuments } from 'hooks'

const errorSinRespuesta = (response) => {
  if (!response || !response.data) {
    throw new Error('La respuesta no contiene datos válidos')
  }

  // Verificar tipo de contenido (idealmente del header)
  // Por ejemplo, si sabes que debe ser 'application/pdf'
  const contentType = response.headers['content-type']
  if (!contentType.includes('application/pdf')) {
    throw new Error('El servidor no devolvió un PDF válido')
  }
}

export const useComprobantes = () => {
  const { loading, handleGetDocuments, handlePrintFactura, handlePrintFacturaSimplificada, handlePrintRemito } = useTransactionalDocuments()

  const obtenerComprobantes = useCallback(async (params = {}) => {
    try {
      const response = await handleGetDocuments(params)
      return response
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleGetDocuments])

  const descargarFactura = useCallback(async (tipoComprobante, idComprobante) => {
    try {
      const response = await handlePrintFactura(tipoComprobante, idComprobante)
      errorSinRespuesta(response)

      toast.success('¡Comprobante descargado!')

      // Crear un Blob a partir de la respuesta
      const blob = await response.data

      // Crear un enlace de descarga
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `comprobante-${idComprobante}.pdf`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Error al descargar el comprobante')
    }
  }, [handlePrintFactura])

  const descargarFacturaSimplificada = useCallback(async (tipoComprobante, idComprobante) => {
    try {
      const response = await handlePrintFacturaSimplificada(tipoComprobante, idComprobante)
      errorSinRespuesta(response)

      toast.success('¡Comprobante simplificado descargado!')

      // Crear un Blob a partir de la respuesta
      const blob = await response.data

      // Crear un enlace de descarga
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `comprobante-${idComprobante}.pdf`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Error al descargar el comprobante simplificado')
    }
  }, [handlePrintFacturaSimplificada])

  const descargarRemito = useCallback(async (idRemito, cantCopias) => {
    try {
      let copias = cantCopias || 2
      const response = await handlePrintRemito(idRemito, copias)
      errorSinRespuesta(response)

      toast.success('¡Remito descargado!')

      // Crear un Blob a partir de la respuesta
      const blob = await response.data

      // Crear un enlace de descarga
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `remito-${idRemito}.pdf`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Error al descargar el remito')
    }
  }, [handlePrintRemito])

  return {
    loading,
    obtenerComprobantes,
    descargarFactura,
    descargarFacturaSimplificada,
    descargarRemito,
  }
}