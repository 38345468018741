import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useOutOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'

import routes from 'app/routing/routes'
import { OutOrderStatusI, RoleI } from 'interfaces'
import { helix } from 'ldrs'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const TabOrdenes = ({ setTab, serviceOrdenes }) => {
  const { id } = useParams()
  const { currentUser } = useAuth()

  const navigate = useNavigate()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading: loadingOrdenes, obtenerOrdenes, liberarReservaOrden } = serviceOrdenes
  const [ordenes, setOrdenes] = useState([])

  const handleObtenerOrdenes = useCallback(async () => {
    try {
      const params = {
        ...initialQuery,
        sales_order_id: id,
        sortOrder: 'DESC',
        sortField: 'id',
      }
      const response = await obtenerOrdenes(params)
      const data = response.data.result
      const count = response.data.metadata.count

      setOrdenes(data)
      setTotalCount(count)
    } catch (error) {
      setOrdenes([])
      setTotalCount(0)
    }
  }, [id, obtenerOrdenes])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Ver',
      icon: 'bi bi-eye text-primary',
      action: () => navigate(`${routes.OUT_ORDERS}/${currentRow?.id}`)
    })

    if (
      currentUser.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.VENTAS || r.id == RoleI.VENTAS_GTE) &&
      currentRow?.remito_id == null &&
      (
        currentRow?.out_order_status_id == OutOrderStatusI.EN_REVISION ||
        currentRow?.out_order_status_id == OutOrderStatusI.COORDINACION_ENTREGA ||
        currentRow?.out_order_status_id == OutOrderStatusI.PENDIENTE_PREPARACION
      )
    ) {
      actions.push({
        label: 'Liberar reserva',
        icon: 'bi bi-box-arrow-up text-warning',
        action: () => handleLiberarReservaOrden()
      })
    }

    return actions
  }

  const handleLiberarReservaOrden = async () => {
    await liberarReservaOrden(currentRow.id)
    handleObtenerOrdenes()
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    handleObtenerOrdenes()
  }, [handleObtenerOrdenes])

  useEffect(() => {
    helix.register()
  }, [])

  useEffect(() => {
    setTab('ordenes')
  }, [])

  return (
    <>
      <TableContainer>
        {loadingOrdenes && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }} />}

        <CustomTable columns={columns} data={ordenes || []} queryMD={query} setQueryMD={setQuery} paginationOptions={paginationOptions} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default TabOrdenes