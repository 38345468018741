import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { usePedidos } from 'hooks'

export const useDocumentos = () => {
  const { loading, handleObtenerDocumentosPedido, handleObtenerDocumentoPedido, handleCrearDocumentoPedido, handleEliminarDocumentoPedido } = usePedidos()

  const obtenerDocumentos = useCallback(async (idPedido, params = {}) => {
    try {
      const response = await handleObtenerDocumentosPedido(idPedido, params)
      return response.data.result
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleObtenerDocumentosPedido])

  const obtenerDocumento = useCallback(async (idPedido, fileId, fileName) => {
    try {
      const response = await handleObtenerDocumentoPedido(idPedido, fileId)

      const blob = response.data
      const contentType = response.headers['content-type']
      const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      toast.success('Archivo del pedido descargado correctamente')
    } catch (error) {
      toast.error('Error al descargar el archivo del pedido')
      throw error // Propaga el error
    }
  }, [handleObtenerDocumentoPedido])

  const crearDocumento = useCallback(async (idPedido, file, selectedFile) => {
    try {
      const data = new FormData();
      data.append('document_type_id', file.document_type_id)
      data.append('file', selectedFile)
      data.append('expiration_date', file.expiration_date)

      await handleCrearDocumentoPedido(idPedido, data)
      toast.success("Documento agregado correctamente")
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCrearDocumentoPedido])

  const eliminarDocumento = useCallback(async (idPedido, fileId) => {
    try {
      const response = await handleEliminarDocumentoPedido(idPedido, fileId)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleEliminarDocumentoPedido])

  return {
    loading,
    obtenerDocumentos,
    obtenerDocumento,
    crearDocumento,
    eliminarDocumento,
  }
}