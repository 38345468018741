import { useState, useRef, useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AccountBoxOutlined, AssignmentOutlined, Inventory2Outlined, WidgetsOutlined, ReceiptLongOutlined, TextsmsOutlined, AccountBalanceOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix, lineSpinner } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useOutOrders } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { Actions, GeneralTab, ArticlesTab, AddresseeTab, TransactionalDocumentsTab, Header } from './partials'
import PackagingTab from './partials/PackagingTab'
import { WaybillModal } from './partials/WaybillModal'
import CommentsTab from './partials/CommentsTab'
import AvisoAfipModal from './partials/AvisoAfipModal'
import AnmatTab from './partials/AnmatTab'
import { RegenerateWaybillModal } from './partials/RegenerateWaybillModal'

import ResponseAfipModal from 'pages/Administrative/Documents/common/ResponseAfipModal'

import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'
import { OutOrderStatusI } from 'interfaces'

const initialBadgeFactura = {
  label: "",
  color: "",
  onClick:() => {},
  mostrar: false
}

const afipOk = (estado) => {
  return (estado == "SUCCESS" || estado == "SUCCESS_WITH_OBSERVATIONS" || estado == "DEFERRED")
}

export const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";

    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";

    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";

    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";

    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";

    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";

    default:
      return ""
  }
}

const OutOrderDetail = () => {
  const { id } = useParams()
  const { auth } = useAuth()
  const { state } = useLocation()

  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { handleGetOutOrder, handleUpdateOutOrder, handleGetNotes, handleGetOutOrderSimulation, handleGetAnmatValidateV2, 
    handleCancelarTraza, handleRetrazar, loading } = useOutOrders()
  const [showDetailAfipModal, setShowDetailAfipModal] = useState(false)
  const [showAvisoAfipModal, setShowAvisoAfipModal] = useState(false)
  const [showWaybillModal, setShowWaybillModal] = useState(false)
  const [showRegenerateWaybillModal, setShowRegenerateWaybillModal] = useState(false)
  const [responseAfip, setResponseAfip] = useState("")
  const [labelTraza, setLabelTraza] = useState("")
  const [outOrder, setOutOrder] = useState(initialValues)
  const [loadingTraza, setLoadingTraza] = useState(false)
  const [editing, setEditing] = useState(false)
  const [simular, setSimular] = useState(false)
  const [mostrarLoadingSimulation, setMostrarLoadingSimulation] = useState(false)
  const [simulation, setSimulation] = useState(false)
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [canPick, setCanPick] = useState(false)
  const [notes, setNotes] = useState([])
  const [badgeFactura, setBadgeFactura] = useState(initialBadgeFactura)
  const [mostrarDescargaFactura, setMostrarDescargaFactura] = useState(false)
  const [mostrarNroComprobante, setMostrarNroComprobante] = useState(false)
  const [anmatValidate, setAnmatValidate] = useState([])

  /* referer puede ser coordinacion, olas */
  const referer = state ? state.referer : 'pedidos';

  const getNotes = async()=>{
    const notes = await handleGetNotes(id)
    setNotes(notes)
  }

  const getOutOrder = useCallback(async () => {
    try {
      setLoadingOrder(true)
      setBadgeFactura(false)
      setMostrarNroComprobante(false)
      setMostrarDescargaFactura(false)
      setMostrarLoadingSimulation(false)

      const response = await handleGetOutOrder(id, { getDetail: true })

      const data = dataAdapter({ ...initialValues, ...response.data.result })

      const dataColored = {
        ...data, 
        label_color: labelColor(data.out_order_status_id),
        subtotal : data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.subtotal), 0 ),
        iva : data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.vat_amount), 0 ),
        total_calc: data.detail.reduce( (acc, el) => parseFloat(acc) + parseFloat(el.total), 0 ) + (data.iibb?.total || 0),
        // trazables: data.detail.reduce( (acc, el) => +acc + +el.traced, 0 ),
        trazables: data.detail.some(d => d.traced),
      }
      setOutOrder(dataColored)
      setCanPick(data.out_order_status_id==OutOrderStatusI.EN_PREPARACION || data.out_order_status_id==OutOrderStatusI.PENDIENTE_DESPACHO)
      if(!data.afip_id || !afipOk(data.afip_estado)){
        setSimular(true)
      }
      else{
        if (data.afip_estado == 'DEFERRED') {
          setBadgeFactura({
            ...badgeFactura,
            mostrar:true,
            label:"Diferida",
            color:'estado-amarillo',
          })
        } else {
          setMostrarNroComprobante(true)
          setMostrarDescargaFactura(true)
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoadingOrder(false)
    }
  }, [id, handleGetOutOrder])

  const getAnmatValidate = async () => {
    try {
      const respAnmat = await handleGetAnmatValidateV2({ out_order_id:id })
      setAnmatValidate(respAnmat.data.result)

      const _err = respAnmat.data?.result?.items?.filter(d=>d.anmat_traced_error && d.traced==1);

      const _trazado = respAnmat.data?.result?.items?.filter(d=>d.anmat_transaction_id);

      if (_err.length > 0) {
        setLabelTraza("Error traza")
      } else {
        if (_trazado.length > 0) {
          setLabelTraza("Trazado")
        } else {
          setLabelTraza("Sin trazar")
        }
      }
    } catch (error) {
      // toast.error(error.message)
    } finally {
      //
    }
  }

  const cancelarTraza = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        html: `
          <p className="p-0 m-0">El servicio de anmat de cancelación de traza puede demorar varios segundos</p>
          <p className="p-0 m-0">Luego debe esperar a que quede cancelada la traza antes de volver a retrazar</p>
          <p className="p-0 m-0">Confirma la cancelación de la traza?</p>
        `,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText:" Si, cancelar traza",
        customClass: { confirmButton:'btn btn-primary', denyButton:"btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        setLoadingTraza(true)

        const respAnmat = await handleCancelarTraza({ out_order_id:id })
        toast.success("Traza cancelada correctamente")
        setLoadingTraza(false)
      }
    } catch (error) {
      toast.error(error.message)
      setLoadingTraza(false)
    }
  }

  const retrazar = async (eventToSend) => {
    try {
      setLoadingTraza(true)

      let auxData = { out_order_id: id }
      if (eventToSend != null) {
        auxData = {
          ...auxData,
          traza_event_code: eventToSend.event_code,
          traza_event_id: eventToSend.id
        }
      }
      // const respAnmat = await handleRetrazar({ out_order_id:id })
      const respAnmat = await handleRetrazar(auxData)
      toast.success("Traza realizada correctamente")
      setLoadingTraza(false)
    } catch (error) {
      toast.error(error.message)
      setLoadingTraza(false)
    }
  }

  const getSimulation = async () => {
    try {
      setMostrarLoadingSimulation(true)

      const response = await handleGetOutOrderSimulation(outOrder.id)
      if (response && response.data) {
        setSimulation(response.data)
      }
      const _simulation = response.data

      if (_simulation?.datos_factura?.afip_error || outOrder?.afip_error != null) {
        setBadgeFactura({
          ...badgeFactura,
          mostrar: true,
          label: "Error en datos del pedido",
          color: 'estado-rojo',
          cursor: 'pointer',
          onClick: () => setShowAvisoAfipModal(true)
        })
      } else if (_simulation?.datos_factura) {
        setBadgeFactura({
          ...badgeFactura,
          mostrar: true,
          label: "Listo para facturar",
          color: outOrder.label_color,
          cursor: 'default',
        })
      }
    } catch (error) {
      //
    } finally {
      setMostrarLoadingSimulation(false)
    }
  }

  const handleUpdate = async (values) => {
    try {
      const data = updateDataAdapter({
        ...values,
        updated_by_user_id: auth?.user.id,
      })

      const response = await handleUpdateOutOrder(id, data)
      toast.success("Orden actualizada correctamente", {theme: "colored"})
      getOutOrder()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setEditing(false)
    }
  }

  const showAfipJson = (row)=>{
    if (row && row.respuesta_json) {
      let respuesta = JSON.parse(row.respuesta_json)

      if (respuesta && 1) {
        setResponseAfip(JSON.parse(row.respuesta_json))
        setShowDetailAfipModal(true)
      }
    }
  }

  const onHideRegenerateWaybillModal = () => {
    setShowRegenerateWaybillModal(false)
    getOutOrder()
  }

  const onHideWaybillModal = () => {
    setShowWaybillModal(false)
    getOutOrder()
  }

  useEffect(() => {
    getOutOrder()
    getNotes()
    getAnmatValidate()
  }, [getOutOrder])

  useEffect(() => {
    if (simular) {
      getSimulation()
    }
  }, [simular])

  useEffect(() => {
    helix.register()
    lineSpinner.register()
  }, [])

  return (
    <>
      <WaybillModal
        show={showWaybillModal}
        onHide={onHideWaybillModal}
        out_order_id={id}
        remito_id={outOrder.remito_id}
        shipping_carrier_service_id={outOrder.delivery?.shipping_carrier_service_id}
        wave={outOrder?.wave}
      />

      <RegenerateWaybillModal
        show={showRegenerateWaybillModal}
        onHide={onHideRegenerateWaybillModal}
        out_order_id={id}
        remito_id={outOrder.remito_id}
        shipping_carrier_service_id={outOrder.delivery?.shipping_carrier_service_id}
        wave={outOrder?.wave}
      />

      <ResponseAfipModal show={showDetailAfipModal} onHide={()=>setShowDetailAfipModal(false)} data={responseAfip} />

      <AvisoAfipModal  show={showAvisoAfipModal} onHide={()=>setShowAvisoAfipModal(false)} data={simulation?.datos_factura}  />

      <DetailContainer
        title={`Orden de egreso #${id}`}
        statusV2={
          <Header
            outOrder={outOrder}
            mostrarLoadingSimulation={mostrarLoadingSimulation}
            badgeFactura={badgeFactura}
            mostrarNroComprobante={mostrarNroComprobante}
            mostrarDescargaFactura={mostrarDescargaFactura}
            labelTraza={labelTraza}
          />
        }
        buttons={
          <Actions
            values={outOrder}
            simulation={simulation}
            editing={editing}
            handleEditing={setEditing}
            submitRef={submitRef}
            cancelRef={cancelRef}
            handleGetOutOrder={getOutOrder}
            setShowWaybillModal={setShowWaybillModal}
            setShowRegenerateWaybillModal={setShowRegenerateWaybillModal}
            loading={loadingOrder }
          />
        }
      >
        <Formik
          initialValues={outOrder}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              handleUpdate(values)
            } catch (error) {
              actions.handleReset()
            }
          }}
          enableReinitialize
          key={outOrder?.out_order_status_id} // Forzar la actualización del componente cuando cambia el status
        >
          {({ values, errors, handleReset }) => {
            return (
              <Form className='d-flex flex-column' encType='multipart/form-data'>
                {(loadingOrder || !outOrder?.id || loading) && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

                <TabsContainer
                  initialValue={(referer == 'olas' && canPick) ? 'packaging' : 'general'}
                  tabs={[
                    { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true,  disabled:(loadingOrder || !outOrder?.id) },
                    { value: 'articles', label: 'Artículos', icon: <Inventory2Outlined />, show: true,  disabled:(loadingOrder || !outOrder?.id) },
                    { value: 'addressee', label: 'Destinatario', icon: <AccountBoxOutlined />, show: true,  disabled:(loadingOrder || !outOrder?.id) },
                    { value: 'packaging', label: "Armado", icon: <WidgetsOutlined />, show: true,  disabled:(loadingOrder || !outOrder?.id) },
                    { value: 'anmat', label: "Anmat", icon: <AccountBalanceOutlined />, show: outOrder?.trazables>0,  disabled:(loadingOrder || !outOrder?.id) },
                    { value: 'transactional-documents', label: 'Comprobantes', icon: <ReceiptLongOutlined />, show: true, disabled: (loadingOrder || !outOrder?.id) },
                    { value: 'comments', label: 'Comentarios', icon: <TextsmsOutlined />, show: true, disabled: (loadingOrder || !outOrder?.id)},
                  ]}
                  tabsContent={[
                    <GeneralTab />,
                    <ArticlesTab editing={editing} handleEditing={setEditing} handleGetOutOrder={getOutOrder} />,
                    <AddresseeTab editing={editing} handleGetOutOrder={getOutOrder}  anmatValidate={anmatValidate} loadingOrder={loadingOrder}/>,
                    <PackagingTab editing={editing} getOutOrder={getOutOrder} outOrder={outOrder} />,
                    <AnmatTab anmatValidate={anmatValidate} cancelarTraza={cancelarTraza} retrazar={retrazar} loadingTraza={loadingTraza} out_order_id={id}/>,
                    <TransactionalDocumentsTab />,
                    <CommentsTab notes={notes} getNotes={getNotes} out_order_id={id} />,
                  ]}
                />

                {(editing || referer == 'coordinacion') && (
                  <div className='d-flex align-items-center justify-content-end'>
                    <Button
                      ref={cancelRef}
                      variant='secondary'
                      type='button'
                      className='me-3'
                      onClick={() => {
                        setEditing(false)
                        handleReset()
                      }}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button ref={submitRef} variant='primary' type='submit' disabled={loadingOrder}>
                      <i className='bi bi-save me-2' />
                      Guardar
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default OutOrderDetail