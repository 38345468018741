import { useState, useMemo, useRef, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { CustomTable, Input } from 'components'

const ArticlesTab = ({ loading }) => {
  const { values} = useFormikContext()


  const columns = useMemo(() => {

    return [
        {
            Header: "Artículo",
            accessor: 'name',
          },
          {
            Header: "GTIN",
            accessor: 'gtin',
          },
          {
            Header: "Cantidad",
            id: 'qty',
            accessor: (row) => (
              <span>{row.qty}</span>
            )
          },
          {
            Header: "Devolver",
            id: 'devolucion',
            accessor: (row, index) => (
              <Input 
                onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, ''); // Reemplaza todo lo que no sea un dígito
                }}
                name={`detail[${index}].devolucion`} 
                id={`detail[${index}].devolucion`} 
                className={`form-control ${(values.detail[index].devolucion > values.detail[index].qty || values.detail[index].devolucion < 0 ) ? 'border-danger' : ''}`}
                style={{width:"100px"}}/>
            )
          },
    ]

  }, [values.detail])


  
  return (
    <>
      <div className='row mb-8 align-center'>
        <div className={`${values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) ? 'd-flex flex-row align-items-center justify-content-between' : ''}`}>
          {values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) &&
            <div className='col text-start mb-8 fw-bold flex-grow-1'>
              Por favor, elimine los artículos con PVP desactualizado e ingreselos nuevamente.
            </div>
          }

          <div className='col text-end mb-8' style={{ flex: 'none' }}>

          </div>
        </div>
      </div>

      <CustomTable columns={columns} data={values?.detail}  />


    </>
  )
}

export default ArticlesTab