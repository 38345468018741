import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import { Tooltip } from 'components'

import { currencyFormat } from 'utilities'
import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: '',
    id: 'RemitoFechaCancel',
    accessor: (row) => (
      row?.RemitoFechaCancel &&
        <OverlayTrigger overlay={
          <TooltipBS>
            <div className='text-start'>
              <strong className='m-0 p-0'>Remito cancelado</strong>
              <p className='m-0 p-0'>Fecha: {moment(row?.RemitoFechaCancel).format('DD/MM/YYYY')}</p>
            </div>
          </TooltipBS>
        }>
          <i className="bi bi-code-square text-danger"  />
        </OverlayTrigger>
    )
  },
  {
    Header: <div className="text-center">Nro.</div>,
    id: 'RemitoId',
    accessor: (row) => (
      <div className='text-center'>
        {row.RemitoId}
      </div>
    ),
  },
  {
    Header: <div className="text-center">ID Pedido</div>,
    id: 'PedidoId',
    accessor: (row) => (
      <div className='text-center'>
        {row?.PedidoId
          ?
            <Tooltip text='Ir a Pedido'>
              <Link to={`${routes.SALES_ORDERS}/${row.PedidoId}`}>
                {row.PedidoId}
              </Link>
            </Tooltip>
          : '-'
        }
      </div>
    ),
  },
  {
    Header: <div className="text-center">ID O. Egreso</div>,
    id: 'OrdenEgresoId',
    accessor: (row) => (
      <div className='text-center'>
        {row.OrdenEgresoId
          ?
            <Tooltip text='Ir a O. Egreso'>
              <Link to={`${routes.OUT_ORDERS}/${row.OrdenEgresoId}`}>
                {row.OrdenEgresoId}
              </Link>
            </Tooltip>
          : '-'
        }
      </div>
    ),
  },
  {
    Header: <div className="text-center">F. remito</div>,
    id: 'RemitoFecha',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.RemitoFecha).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Remito</div>,
    id: 'RemitoNro',
    accessor: (row) => (
      <div className='text-center'>
        {row.RemitoNro}
      </div>
    ),
  },
  {
    Header: <div className='text-end'>Valor</div>,
    id: 'remitoValor',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.remitoValor || 0)}
      </div>
    )
  },
  {
    Header: <div className="">Razon social</div>,
    id: 'RazonSocial',
    accessor: (row) => (
      <div className=''>
        {row?.PedidoId && row?.RemitoNro.split('-')[0] !== 'X'
          ? <strong className='m-0 p-0'>[{row.ClienteId}] {row.RazonSocial}</strong>
          : <strong className='m-0 p-0'>{row.RazonSocial}</strong>
        }

        {row?.PacienteId &&
          <>
            <p className='m-0 p-0 mt-4'>Paciente: <strong>[{row.PacienteId}] {row.nombrePaciente}</strong></p>
            <p className='m-0 p-0 mt-2'>Documento: {row?.docPaciente ? row.docPaciente : '-'}</p>
            <p className='m-0 p-0 mt-2'>Nro. afiliado: {row?.nroAfiliadoPaciente ? row.nroAfiliadoPaciente : '-'}</p>
            <p className='m-0 p-0 mt-2'>Tel. / Cel.: {row?.telPaciente || '-'} / {row?.celPaciente || '-'}</p>
          </>
        }
      </div>
    ),
  },
  {
    Header: <div className=''>Tranporte</div>,
    id: 'Transporte',
    accessor: (row) => (
      <div className=''>
        {row?.Transporte
          ?
            <>
              <strong className='m-0 p-0'>{row.Transporte}</strong>
              <p className='m-0 p-0 mt-4'>Servicio: <strong>{row.ServicioTransporte}</strong></p>
              <p className='m-0 p-0 mt-2'>Operativa: <strong>{row?.Operativa ? row.Operativa : '-'}</strong></p>
            </>
          : <i>Sin transporte</i>
        }
      </div>
    ),
  },
  {
    Header: <div className=''>Nombre / Domicilio</div>,
    id: 'address_id_traza',
    accessor: (row) => (
      <div className=''>
        {row?.DireccionTraza
          ?
            <>
              <strong className='m-0 p-0'>{row.DireccionTraza}</strong>
              <p className='m-0 p-0 mt-4'>{row.DomicilioTraza}</p>
              <p className='m-0 p-0 mt-2'>GLN: {row?.gln ? row.gln : '-'}</p>
            </>
          : <i>Sin dirección de traza</i>
        }
      </div>
    )
  },
  {
    Header: <div className=''>Localidad</div>,
    id: 'LocalidadTraza',
    accessor: (row) => (
      <div className=''>
        {row?.LocalidadTraza
          ? <p className='m-0 p-0'>{row.LocalidadTraza}</p>
          : <i>Sin localidad</i>
        }
      </div>
    )
  },
  // {
  //   Header: <div className=''>Provincia</div>,
  //   id: 'ProvinciaTraza',
  //   accessor: (row) => (
  //     <div className=''>
  //       {row?.ProvinciaTraza
  //         ? <p className='m-0 p-0'>{row.ProvinciaTraza}</p>
  //         : <i>Sin provincia</i>
  //       }
  //     </div>
  //   )
  // },
  {
    Header: <div className='text-center'>Comprobante</div>,
    id: 'TipoComprobanteDet',
    accessor: (row) => (
      <div className='text-center'>
        {/* {row?.TipoComprobanteDet ? row.TipoComprobanteDet : '-'} */}
        <p className='m-0 p-0 mt-4'>{row?.TipoComprobanteDet ? row.TipoComprobanteDet : ''}</p>
        <p className='m-0 p-0 mt-2'>{row?.NroComp ? row.NroComp : <i>Sin nro. comprobante</i>}</p>
      </div>

    )
  },
  // {
  //   Header: <div className='text-center'>Comprobante</div>,
  //   id: 'NroComp',
  //   accessor: (row) => (
  //     <div className='text-center'>
  //       {row?.NroComp ? row.NroComp : <i>Sin nro. comprobante</i>}
  //     </div>
  //   )
  // },
  {
    Header: <div className='text-center'>Estado comprobante</div>,
    id: 'FacturaEstado',
    accessor: (row) => (
      <span className={ `badge ${row.label_color_comprobante} w-100 justify-content-center`}>
        {row.status_display_comprobante}
      </span>
    )
  },
  {
    Header: <div className='text-center'>Verifarma</div>,
    id: 'Verifarma',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.Verifarma) ? 'success' : 'danger'}`}>
          {Boolean(row?.Verifarma) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
  {
    Header: <div className='text-center'>ID O. Envío</div>,
    id: 'GuiaId',
    accessor: (row) => (
      <div className='text-center'>
        {row?.GuiaId
          ?
            <Tooltip text='Ir a O. Envío'>
              <Link to={`${routes.LOGISTICS_SHIPMENT_ORDERS}/${row.GuiaId}`}>
                {row.GuiaId}
              </Link>
            </Tooltip>
          : <i>Sin O. Envío</i>
        }
      </div>
    )
  },
  {
    Header: <div className='text-center'>Estado O. envío</div>,
    id: 'GuiaEstado',
    accessor: (row) => (
      <span className={ `badge ${row.label_color_guia} w-100 justify-content-center`}>
        {row.status_display_guia}
      </span>
    )
  },
]

const COLUMNS_SIN_COMPROBANTES = [
  {
    Header: '',
    id: 'RemitoFechaCancel',
    accessor: (row) => (
      row?.RemitoFechaCancel &&
        <OverlayTrigger overlay={
          <TooltipBS>
            <div className='text-start'>
              <strong className='m-0 p-0'>Remito cancelado</strong>
              <p className='m-0 p-0'>Fecha: {moment(row?.RemitoFechaCancel).format('DD/MM/YYYY')}</p>
            </div>
          </TooltipBS>
        }>
          <i className="bi bi-code-square text-danger"  />
        </OverlayTrigger>
    )
  },
  {
    Header: <div className="text-center">Nro.</div>,
    id: 'RemitoId',
    accessor: (row) => (
      <div className='text-center'>
        {row.RemitoId}
      </div>
    ),
  },
  {
    Header: <div className="text-center">ID Pedido</div>,
    id: 'PedidoId',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text='Ir a Pedido'>
          <Link to={`${routes.SALES_ORDERS}/${row.PedidoId}`}>
            {row.PedidoId}
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: <div className="text-center">Orden de compra</div>,
    id: 'OrdenCompra',
    accessor: (row) => (
      <div className='text-center'>
        {row?.OrdenCompra
          ? <>{row.OrdenCompra}</>
          : <i>Sin orden de compra</i>
        }
      </div>
    ),
  },
  {
    Header: <div className="text-center">ID OE</div>,
    id: 'OrdenEgresoId',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text='Ir a O. Egreso'>
          <Link to={`${routes.OUT_ORDERS}/${row.OrdenEgresoId}`}>
            {row.OrdenEgresoId}
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: <div className="text-center">F. remito</div>,
    id: 'RemitoFecha',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.RemitoFecha).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Remito</div>,
    id: 'RemitoNro',
    accessor: (row) => (
      <div className='text-center'>
        {row.RemitoNro}
      </div>
    ),
  },
  {
    Header: <div className='text-end'>Valor</div>,
    id: 'remitoValor',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.remitoValor || 0)}
      </div>
    )
  },
  {
    Header: <div className="">Razon social</div>,
    id: 'RazonSocial',
    accessor: (row) => (
      <div className=''>
        <strong className='m-0 p-0'>[{row.ClienteId}] {row.RazonSocial}</strong>

        {row?.PacienteId &&
          <>
            <p className='m-0 p-0 mt-4'>Paciente: <strong>[{row.PacienteId}] {row.nombrePaciente}</strong></p>
            <p className='m-0 p-0 mt-2'>Documento: {row?.docPaciente ? row.docPaciente : '-'}</p>
            <p className='m-0 p-0 mt-2'>Nro. afiliado: {row?.nroAfiliadoPaciente ? row.nroAfiliadoPaciente : '-'}</p>
          </>
        }
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Cond. venta</div>,
    id: 'CondicionVenta',
    accessor: (row) => (
      <div className='text-center'>
        {row.CondicionVenta}
      </div>
    )
  },
  {
    Header: <div className=''>Tranporte</div>,
    id: 'Transporte',
    accessor: (row) => (
      <div className=''>
        <strong className='m-0 p-0'>{row.Transporte}</strong>
        <p className='m-0 p-0 mt-4'>Servicio: <strong>{row.ServicioTransporte}</strong></p>
        <p className='m-0 p-0 mt-2'>Operativa: <strong>{row?.Operativa ? row.Operativa : '-'}</strong></p>
      </div>
    ),
  },
  {
    Header: <div className=''>Nombre / Domicilio</div>,
    id: 'address_id_traza',
    accessor: (row) => (
      <div className=''>
        <strong className='m-0 p-0'>{row.DireccionTraza}</strong>
        <p className='m-0 p-0 mt-4'>{row?.DomicilioTraza}</p>
        <p className='m-0 p-0 mt-2'>GLN: {row?.gln ? row.gln : '-'}</p>
      </div>
    )
  },
  {
    Header: <div className=''>Localidad</div>,
    id: 'LocalidadTraza',
    accessor: (row) => (
      <div className=''>
        <p className='m-0 p-0'>{row.LocalidadTraza}</p>
      </div>
    )
  },
  {
    Header: <div className=''>Provincia</div>,
    id: 'ProvinciaTraza',
    accessor: (row) => (
      <div className=''>
        <p className='m-0 p-0'>{row.ProvinciaTraza}</p>
      </div>
    )
  },
  {
    Header: <div className='text-center'>Verifarma</div>,
    id: 'Verifarma',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.Verifarma) ? 'success' : 'danger'}`}>
          {Boolean(row?.Verifarma) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
  {
    Header: <div className='text-center'>ID O. Envío</div>,
    id: 'GuiaId',
    accessor: (row) => (
      <>
        {row?.GuiaId &&
          <div className='text-center'>
            <Tooltip text='Ir a O. de Envío'>
              <Link to={`${routes.LOGISTICS_SHIPMENT_ORDERS}/${row.GuiaId}`}>
                {row.GuiaId}
              </Link>
            </Tooltip>
          </div>
        }
      </>
    )
  },
  {
    Header: <div className='text-center'>Estado O. envío</div>,
    id: 'GuiaEstado',
    accessor: (row) => (
      <span className={ `badge ${row.label_color_guia} w-100 justify-content-center`}>
        {row.status_display_guia}
      </span>
    )
  },
]

export {
  COLUMNS,
  COLUMNS_SIN_COMPROBANTES,
}