import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

import { Tooltip as TooltipC } from 'components'

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className='text-center'>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        <Link to={`${routes.VENTAS_PEDIDOS}/${row.id}`}>
          {row.id}
        </Link>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <p className='m-0 p-0 text-center'>Tipo</p>,
    id: 'order_type_id',
    accessor: (row) => (
      <span className={`badge ${row.order_type_color} w-100 justify-content-center fw-normal`}>
        {row.order_type_label}
      </span>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>F. creación</div>,
    id: 'created_at',
    accessor: (row) => (
      <div className='text-center'>
        <p className='m-0 p-0'>{moment(row.created_at).format("DD/MM/YYYY")}</p>

        <p className='m-0 p-0 mt-4'>{row.user_name}</p>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>F. autorización</div>,
    id: 'profitability_auth_sent_date',
    accessor: (row) => (
      <div className='text-center'>
        {row.profitability_auth_sent_date ? moment(row.profitability_auth_sent_date).format("DD/MM/YYYY HH:mm") : "-"}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>Nro. Orden de compra</div>,
    id: 'purchase_order',
    accessor: (row) => (
      <div className='text-center'>
        {row?.purchase_order ? row.purchase_order : '-'}
      </div>
    )
  },
  {
    Header: <div className='text-center'>IDs OE</div>,
    id: 'ids_egreso',
    accessor: (row) => (
      <div className='text-center'>
        {row?.ids_egreso ? row.ids_egreso : '-'}
      </div>
    )
  },
  {
    Header: <div>Cliente</div>,
    id: 'customer_supplier_business_name',
    accessor: (row) => (
      <>
        <strong className='m-0 p-0'>
          [{row.customer_supplier_id}] {row.customer_supplier_business_name}
        </strong>

        <p className='m-0 p-0 mt-2'><strong>Documento:</strong> {row.customer_supplier_documentation}</p>

        {Boolean(row.remito_para_terceros) && row?.id_tercero_para_remitir !== null &&
          <p className='m-0 p-0 mt-4'>Remitir a: {row?.tercero_para_remitir_nombre}</p>
        }

        {row?.patient_id &&
          <ul className='list-style-none m-0 p-0 mt-4'>
            <li><strong>Paciente:</strong> [{row.patient_id}] {row.patient_fullname}</li>
            <li className='mt-2'><strong>Documento:</strong> {row?.patient_documentation || '-'}</li>
            <li className='mt-2'><strong>Nro. afiliado:</strong> {row?.patient_affiliate_number || '-'}</li>
          </ul>
        }
      </>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>Vendedor</div>,
    id: 'seller_id',
    accessor: (row) => (
      <div className='text-center'>
        {row.seller_name}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Artículos</div>,
    id: 'cant_articulos',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cant_articulos || '-'}

        {row?.articulos &&
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`articulos-pedido-${row.id}`}>
                <div>
                  {row.articulos.split(';').map((item, index) => (
                    <div key={index}>{item.trim()}</div>
                  ))}
                </div>
              </Tooltip>
            }
          >
            <i className='bi bi-info-circle text-warning ms-2' />
          </OverlayTrigger>
        }
      </div>
    )
  },
  {
    Header: <div className='text-end'>Total</div>,
    id: 'total',
    accessor: (row) => (
      <div className='text-end'>
        {row.total === 0 ? '-' : currencyFormat.format(row.total)}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'sales_order_status_id',
    className:'col-status',
    accessor: (row) => (
      <span className={`badge ${row.sales_order_status_color} w-100 justify-content-center fw-normal`}>
        {row.sales_order_status_label}
      </span>
    )
  },
  {
    Header: '',
    id: 'cancelado_parcial',
    accessor: (row) => (
      <>
        {Boolean(row.cancelado_parcial) &&
          <TooltipC text='Pedido cancelado parcialmente'>
            <i className='bi bi-code-square text-danger' />
          </TooltipC>
        }
      </>
    )
  }
]

export {
  COLUMNS
}