import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap';
import moment from 'moment';

import { currencyFormat } from 'utilities';

import '../reports.css'

let sistemaLabel = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'Farma'
    case 'mi_test':
      return 'Mi test'
    default:
      return 'Sin sistema'
  }
}

let sistemaLabelColor = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'estado-celeste'
      return 'estado-azul'
    case 'mi_test':
      return 'estado-amarillo'
    default:
      return 'estado-gris'
  }
}

const COLUMNS =  [
  {
    Header: <p className='m-0 p-0'>ID</p>,
    id: 'id_comprobante',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row.id_comprobante}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <p className="m-0 p-0 text-center">Sistema</p>,
    id: 'sistema',
    accessor: (row) => (
      <span className={`badge ${sistemaLabelColor(row.sistema)} w-100 justify-content-center fw-normal`}>
        {sistemaLabel(row.sistema)}
      </span>
    ),
    hasOrder: true,
  },
]

const COLUMNS_2 = [
  // {
  //   Header: <p className="m-0 p-0 text-center">IDs Pedido</p>,
  //   id: 'ids_pedidos',
  //   accessor: (row) => (
  //     <p className='m-0 p-0 text-center'>
  //       {row?.ids_pedidos && row.ids_pedidos.split(',').join(', ')}
  //     </p>
  //   ),
  //   hasOrder: true,
  // },
  // {
  //   Header: <p className="m-0 p-0 text-center">IDs O. Egreso</p>,
  //   id: 'ids_ordenes',
  //   accessor: (row) => (
  //     <p className='m-0 p-0 text-center'>
  //       {row?.ids_ordenes && row.ids_ordenes.split(',').join(', ')}
  //     </p>
  //   ),
  //   hasOrder: true,
  // },
  {
    Header: <p className="m-0 p-0 text-center">Fecha</p>,
    id: 'fecha_emision',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row.fecha_emision ? moment(row.fecha_emision).format("DD/MM/YYYY") : '-'}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <p className="m-0 p-0 text-center">Tipo</p>,
    id: 'tipo_comprobante_afip',
    className: 'col-comprobante',
    accessor: (row) => (
      <p className="m-0 p-0 break-word text-center">
        {row.tipo_comprobante_afip}
      </p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Comprobante</p>,
    id: 'nro_completo',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row.nro_completo}
      </p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">CAE</p>,
    id: 'cae',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row.cae}
      </p>
    )
  },
  {
    Header: <p className='m-0 p-0'>Cliente</p>,
    id: 'cliente',
    accessor: (row) => (
      <strong className='m-0 p-0'>{row.cliente}</strong>
    ),
    hasOrder: true,
  },
  {
    Header: <p className="m-0 p-0 text-end">Total</p>,
    id: 'importe_total',
    accessor: (row) => (
      <p className='m-0 p-0 text-end'>
        {currencyFormat.format(row?.importe_total || 0)}
      </p>
    ),
  },
  {
    Header: <p className='m-0 p-0 text-center'>Estado</p>,
    id: 'estado',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.status_display}
      </span>
    ),
  },
  {
    Header: '',
    id: 'comprobante_cancelacion_id',
    accessor: (row) => (
      <div className='text-center'>
        {row?.comprobante_cancelacion && row.comprobante_cancelacion?.id &&
          <OverlayTrigger
            placement='top'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div className='text-start'>
                  <p className='m-0 p-0'>ID: {row.comprobante_cancelacion.id}</p>
                  <p className='m-0 p-0 mt-2'>Comprobante: {row?.comprobante_cancelacion.nro_completo}</p>
                  {row?.comprobante_cancelacion.tipo_comprobante_agrupado !== 1
                    ? <p className='m-0 p-0 mt-2'>Tipo: {row?.comprobante_cancelacion?.tipo_comprobante_agrupado_desc || '-'}</p>
                    : <p className='m-0 p-0 mt-2'>Motivo: {row?.comprobante_cancelacion?.motivo || '-'}</p>
                  }
                </div>
              </TooltipBS>
            }
          >
            <i className="bi bi-code-square text-danger ms-2 fs-3" />
          </OverlayTrigger>
        }
      </div>
    )
  }
]

export {
  COLUMNS,
  COLUMNS_2,
}