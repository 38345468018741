import React from 'react'
import { Link } from 'react-router-dom';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';

import { KTCard, KTCardBody } from '_metronic/helpers'
import { ButtonPrimary, CreateButton, Loading } from 'components'

import './styles.sass'

const TableContainer = ({
  title = '',
  subtitle,
  goTo,
  goToText,
  goToSecondary,
  goToSecondaryText,
  dropdownText,
  dropdownActions,
  dropdownDisabled=false,
  createButton = undefined,
  filter,
  loading = false,
  actions,
  doubleActions = false,
  children,
}) => {
  return (
    <>
      <KTCard className="mb-8">
        <KTCardBody className={`d-flex justify-content-between align-items-center ${!title ? 'p-0' : ''}`}>
          {title && <h3 className='m-0' style={{height:"38px", lineHeight:"38px"}}>{title}</h3>}

          <div className='d-flex'>
            {goTo && goTo !== '' &&
              <Link to={goTo}>
                <CreateButton text={goToText ? goToText : 'Crear'} styles='me-4' />
              </Link>
            }

            {goToSecondary && goToSecondary !== '' &&
              <Link to={goToSecondary}>
                <CreateButton text={goToSecondaryText ? goToSecondaryText : 'Crear'} styles='w-auto' />
              </Link>
            }

            {createButton && createButton}

            { dropdownActions && dropdownActions.length &&
              <DropdownButton
                title={
                  <>
                    <i className="bi bi-file-earmark-plus mb-1 me-2 fs-4"></i>
                    {dropdownText || 'Crear'}
                  </>
                }
                align={{ lg: 'end' }}
                disabled={dropdownDisabled}
              >
                  {dropdownActions.map((a, idx)=>(
                    <Dropdown.Item key={idx} as={Link} to={a.href} state={a.state}><i className={`bi bi-${a.icon} pe-2`}></i>{a.label}</Dropdown.Item>
                  ))}
              </DropdownButton>
            }
          </div>
        </KTCardBody>

        {filter &&
          <KTCardBody className='border-top'>
            {filter}
          </KTCardBody>
        }
      </KTCard>

      <Card>
        {actions &&
          <Card.Header className={`d-flex justify-content-${doubleActions ? 'between' : 'end'} align-items-center`}>
            {doubleActions
              ? <div className='w-100'>{actions}</div>
              : <>{actions}</>
            }
          </Card.Header>
        }

        <Card.Body className='TableContainer'>
          {loading ? <Loading /> : children}
        </Card.Body>
      </Card>

      {/* <KTCard>
        <KTCardBody className='TableContainer'>
          {loading ? <Loading /> : children}
        </KTCardBody>
      </KTCard> */}
    </>
  )
}

export default TableContainer
