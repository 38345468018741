import moment from 'moment'

export const COLUMNS = [
  {
    Header: <div className='text-center'>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        {row.id_real}
      </div>
    ),
  },
  {
    Header: <div className=''>Tipo</div>,
    id: 'tipo_comprobante',
    accessor: (row) => (
      <div className=''>
        {row.tipo_comprobante}
      </div>
    ),
  },
  {
    Header: <div className=''>Número</div>,
    id: 'nro_completo',
    accessor: (row) => (
      <div className=''>
        {row.nro_completo}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>CAE</div>,
    id: 'cae',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cae ? row.cae : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Fecha</div>,
    id: 'fecha',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.fecha).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>F. cancelación</div>,
    id: 'fecha_cancelacion',
    accessor: (row) => (
      <div className='text-center'>
        {row?.fecha_cancelacion ? moment(row.fecha_cancelacion).format('DD/MM/YYYY') : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'status',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center`}>
        {row.label}
      </span>
    )
  },
]