import { FC, useEffect, useState } from 'react'
import { AppBar, Tabs, Tab, Typography, Box, Icon } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'; // Importa el ícono que prefieras

import './styles.css'
import { isFunction } from 'react-bootstrap-typeahead/types/utils';

interface TabI {
  value: string,
  label: string,
  icon: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined,
  show: boolean,
  disabled?:boolean,
  outdated_pvp?: boolean,
  errors?: boolean,
}

interface Props {
  initialValue: string;
  elevation?: number;
  position?: 'static' | 'fixed' | 'absolute' | 'sticky' | 'relative';
  tabs: Array<TabI>;
  tabsContent: Array<React.ReactNode>;
  handleChange:any;
}

// const TabLabelWithIcon = ({ label, icon, outdatedPvp }: any) => (
//   <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
//     {outdatedPvp && (
//       <Box sx={{ position: 'absolute', left: 0, top: '-8px', display: 'flex' }}>
//         <WarningIcon sx={{ fontSize: '1rem', color: 'yellow' }} />
//       </Box>
//     )}
//     {icon && <Icon>{icon}</Icon>}
//     <Typography variant="body2" sx={{ ml: icon ? 1 : 0 }}>
//       {label}
//     </Typography>
//   </Box>
// );

const TabContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography component="div" style={{ paddingTop: 4 * 3 }}>
      {children}
    </Typography>
  );
}

const TabsContainer: FC<Props> = ({
  initialValue,
  elevation = 0,
  position = 'static',
  tabs,
  tabsContent,
  handleChange=null
}) => {
  const [value, setValue] = useState(initialValue)

  return (
    <div>
      <AppBar elevation={elevation} position={position} color='default' className='noShadow'>
        <Tabs
          value={value}
          onChange={(e, newValue) => { 
            if(typeof handleChange == 'function') 
              handleChange(newValue); 
            setValue(newValue) 
          }}
          indicatorColor='primary'
          textColor='primary'
          className='noShadow'
          // sx={{ '& .MuiTabs-indicator': { display: 'none' } }} // Oculta el indicador predeterminado
        >
          {tabs.map((tab) => (
            tab.show &&
              <Tab
                key={`tab-${tab.value}`}
                value={tab.value}
                label={tab.label}
                icon={tab.icon}
                disabled={tab.disabled}
                sx={{
                  ...(tab.outdated_pvp && {
                    color: '#f9db57 !important', // Forza el color amarillo incluso si está activa
                    opacity: value === tab.value ? 1 : 0.6, // Opacidad diferenciada para activa/no activa
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 3,
                      backgroundColor: '#f9db57', // Color para outdated_pvp
                    },
                    '&.Mui-selected': {
                      color: '#f9db57', // Cambia el color de la pestaña activa
                    },
                  }),
                  ...(tab.errors && {
                    color: 'red !important',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 3,
                      backgroundColor: 'red', // Color para outdated_pvp
                    },
                    '&.Mui-selected': {
                      color: 'red', // Cambia el color de la pestaña activa
                    },
                  })
                }}
                className={tab?.outdated_pvp ? 'outdated-tab' : ''}
              />
          ))}
        </Tabs>

        {tabsContent.map((tabContent, index) => (
          <div key={index} hidden={value !== tabs[index].value}>
            {value === tabs[index].value && tabs[index].show && <TabContainer>{tabContent}</TabContainer>}
          </div>
        ))}
      </AppBar>
    </div>
  )
}

export default TabsContainer