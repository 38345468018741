import { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useTransactionalDocuments, useReports } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import AgrupacionModal from './AgrupacionModal'
import { COLUMNS, COLUMNS_2 } from './columns'

import { labelColor, statusDisplay } from 'pages/Administrative/Documents/List'
import { Factura } from 'components'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD'),
}

const ESTADOS_ORDEN = {
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13,
}

const TransactionalDocumentsReport = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetTransactionalDocumentsReport } = useReports()
  const [data, setData] = useState([])
  const [conDetalle, setConDetalle] = useState(false)

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading: loadingDownload, handlePrintFactura } = useTransactionalDocuments()
  const { handlePrintVisualizarComprobante } = useTransactionalDocuments()
  const { handleGetVisualizarComprobante } = useTransactionalDocuments()
  

  const [modalAgrupacion, setModalAgrupacion] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [idsAgrupados, setIdsAgrupados] = useState([])
  const [esPedido, setEsPedido] = useState(true)

  const getReport = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.reporte_comprobantes) {
        const { reporte_comprobantes } = currentUser

        params = {
          ...params,
          id: reporte_comprobantes?.id || '',
          sistema: reporte_comprobantes?.sistema || '',
          sales_order_id: reporte_comprobantes?.sales_order_id || '',
          out_order_id: reporte_comprobantes?.out_order_id || '',
          transactional_document_type_id: reporte_comprobantes?.transactional_document_type_id || '',
          date_from: reporte_comprobantes?.date_from || '',
          date_to: reporte_comprobantes?.date_to || '',
          razon_social: reporte_comprobantes?.razon_social || '',
          documento: reporte_comprobantes?.documento || '',
          status_filter: reporte_comprobantes?.status_filter || '',
          comprobante: reporte_comprobantes?.comprobante || '',
        }
      }

      const response = await handleGetTransactionalDocumentsReport(params)

      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.estado),
        status_display: statusDisplay(d.estado),
      }))

      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetTransactionalDocumentsReport, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = ()=>{
    let actions = [];

    actions.push({
      label: "Descargar",
      icon: "bi bi-download text-primary",
      action: downloadDocument
    });
    actions.push({
        label: 'Visualizar Comprobante',
        icon: 'bi bi-eye text-primary',
        action: () => {
          if (currentRow) {
            visualizarComprobante();
          }
        } ,
      })

    return actions
  }

  const [showModal, setShowModal] = useState(false);
  const [comprobanteData, setComprobanteData] = useState(null); 

  const visualizarComprobante = async () => {
    await getVisualizaarComprobante();  
    setShowModal(true); 
  };
  const handleClose = () => {
    setShowModal(false);
  };


  const downloadDocument = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintFactura(currentRow?.tipo_comprobante_agrupado, currentRow?.id_comprobante)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Comprobante descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `comprobante-${currentRow.id_comprobante}-${currentRow.tipo_comprobante_agrupado}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el comprobante')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const downloadVisualizarComprobante = async (arrayModificaciones) => {
    if (currentRow) {
      try {

        console.log("array", arrayModificaciones)

        const response = await handlePrintVisualizarComprobante(currentRow?.tipo_comprobante_agrupado, currentRow?.id_comprobante, arrayModificaciones)
        
        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Comprobante descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `comprobante-${currentRow.id_comprobante}-${currentRow.tipo_comprobante_agrupado}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el comprobante')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }


  const getVisualizaarComprobante = async () => {
    if (currentRow) {
      try {
        const response = await handleGetVisualizarComprobante(currentRow?.tipo_comprobante_agrupado, currentRow?.id_comprobante);
        setComprobanteData(response); 
        console.log("setComprobanteData", response)
        console.log("ID comprobante", currentRow?.id_comprobante)
        return response;
  
      } catch (error) {
        toast.error(error.message);
      }
    }
  }
  
  /* Fin menu contextual */

  const getExport = async (query) => {
    try {
      const response = await handleGetTransactionalDocumentsReport(query)

      if (response.result) {
        return response.result
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: <p className="m-0 p-0 text-center">IDs Pedido</p>,
      id: 'ids_pedidos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_pedidos && row.ids_pedidos.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  setModalTitle('Pedidos de venta')
                  setIdsAgrupados(row.ids_pedidos.split(','))
                  setEsPedido(true)
                  setModalAgrupacion(true)
                }}
              >
                Pedidos agrupados
              </span>
            : <span>{row.ids_pedidos}</span>
          }
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">IDs O. Egreso</p>,
      id: 'ids_ordenes',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_ordenes && row.ids_ordenes.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  setModalTitle('Órdenes de egreso')
                  setIdsAgrupados(row.ids_ordenes.split(','))
                  setEsPedido(false)
                  setModalAgrupacion(true)
                }}
              >
                Órdenes agrupadas
              </span>
            : <span>{row.ids_ordenes}</span>
          }
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">IDs Remito</p>,
      id: 'ids_remitos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_remitos && row.ids_remitos.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  setModalTitle('Remitos')
                  setIdsAgrupados(row.ids_remitos.split(','))
                  setEsPedido(false)
                  setModalAgrupacion(true)
                }}
              >
                Remitos agrupados
              </span>
            : <span>{row.ids_remitos}</span>
          }
        </p>
      ),
      hasOrder: true,
    },
    ...COLUMNS_2,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    // --
  }, [conDetalle])

  useEffect(()=>{
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Reporte de Comprobantes'
        filter={<Filter setData={setData} queryMD={queryMD} setQueryMD={setQueryMD} setTotalCount={setTotalCount} getExport={getExport} setConDetalle={setConDetalle} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />

      <AgrupacionModal show={modalAgrupacion} onClose={() => setModalAgrupacion(false)} title={modalTitle} ids={idsAgrupados} pedido={esPedido} />
      <Factura show={showModal} handleClose={handleClose} comprobanteData={comprobanteData} downloadVisualizarComprobante={downloadVisualizarComprobante}/>
    </>
  )
}

export default TransactionalDocumentsReport