import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { Input, SwitchField } from 'components'

const initialValues = {
  id: '',
  text: '',
  laboratorio: '',
  vencimiento_dias: '',
  con_stock: 1,
}

const Filter = ({ loading, data, query, setQuery, exportData }) => {
  const filterData = (values) => {
    let params = {
      ...query,
      ...values,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      laboratorio: values?.laboratorio ? values.laboratorio : undefined,
      vencimiento_dias: values?.vencimiento_dias ? values.vencimiento_dias : undefined,
      con_stock: values?.con_stock || undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    setQuery({ ...params })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, resetForm, submitForm }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-2'>
                <Input id='id' name='id' disabled={loading} />
                <p className='text-muted'>ID</p>
              </div>

              <div className='col-6'>
                <Input id='text' name='text' disabled={loading} />
                <p className='text-muted'>SKU / Nombre / Droga</p>
              </div>

              <div className='col-4'>
                <Input id='laboratorio' name='laboratorio' disabled={loading} />
                <p className='text-muted'>Laboratorio</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-2'>
                <Input id='vencimiento_dias' name='vencimiento_dias' type='number' disabled={loading} />
                <p className='text-muted'>Vencimiento en X días</p>
              </div>

              <div className='col-4'>
                <div className="d-flex my-3">
                  <SwitchField
                    id='con_stock'
                    name='con_stock'
                    disabled={loading}
                  />
                  <span style={{ fontSize: '1.1rem' }}>Con stock</span>
                </div>
            </div>
            </div>

            <div className='row'>
              <div className='col-12 text-end'>
                <Button
                  type='button'
                  variant="secondary"
                  onClick={() => exportData()}
                  className="me-3"
                  disabled={loading}
                >
                  <i className="bi bi-file-earmark-excel mb-1" />
                  Exportar
                </Button>

                <Button
                  type='button'
                  variant="secondary"
                  onClick={() => {
                    resetForm()
                    submitForm()
                  }}
                  className="me-3"
                  disabled={loading}
                >
                  <i className="bi bi-zoom-out mb-1" />
                  Limpiar
                </Button>

                <Button type='submit' variant='primary' disabled={loading}>
                  <i className={`bi bi-${loading ? 'arrow-clockwise' : data.length > 0 ? 'search me-1' : 'play-fill fs-3'}`} />
                  {loading
                    ? 'Cargando...'
                    : data.length > 0 ? 'Buscar' : 'Ejecutar'
                  }
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter