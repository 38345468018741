import Swal from "sweetalert2"

export function mostrarAdvertencia({
  text = '¿Estás seguro?',
  title = 'Advertencia',
  icon = 'warning',
  showDenyButton = false,
  denyButtonText = 'No, cerrar',
  confirmButtonText = 'Sí, confirmar',
  input = null, // Ej: 'textarea', 'text', etc.
  inputLabel = '',
  inputValue = '',
  inputAttributes = { autocomplete: 'off' },
  inputErrorMessage = 'Por favor, completa el campo'
} = {}) {
  const opciones = {
    title,
    text,
    icon,
    customClass: {
      confirmButton: 'btn btn-primary',
      denyButton: 'btn btn-secondary'
    },
    reverseButtons: true,
    confirmButtonText
  }

  // Si se requiere botón "Deny"
  if (showDenyButton) {
    opciones.showDenyButton = true
    opciones.denyButtonText = denyButtonText
  }

  // Si se requiere input
  if (input) {
    opciones.input = input
    opciones.inputValue = inputValue
    opciones.inputLabel = inputLabel
    opciones.inputAttributes = inputAttributes
    opciones.inputValidator = (value) => {
      if (!value?.trim()) {
        return inputErrorMessage
      }
    }
  }

  return Swal.fire(opciones)
}