import * as Yup from 'yup'
import moment from 'moment'

const initialValues = {
  id: '',
  order_type_id: '',
  order_type_name: '',
  order_type_label: '',
  sales_order_status_id: '',
  sales_order_status_label: '',
  user_id: '',
  user_name: '',
  business_unit_id: '',
  business_unit_name: '',
  customer_supplier_id: '',
  customer_supplier_business_name: '',
  seller_id: '',
  seller_name: '',
  payment_condition_id: '',
  payment_condition_label: '',
  payment_condition_name: '',
  issue_date: '',
  purchase_order: '',
  billing_type_id: '',
  billing_type_name: '',
  billing_type_label: '',
  bidding: false,
  requires_coordination: false,
  bidding_value: '',
  revocation_user_id: '',
  revocation_user_name: '',
  revocation_notes: '',
  revocation_date: '',
  profitability_auth_sent_date: '',
  observations: '',
  total: '',
  total_profitability: '',
  price_update_id: '',
  de_blister: false,
  por_unidades: false,
  created_at: '',
  updated_at: '',
  updated_by_user_id: '',
  user_update_name: '',
  cancel_at: '',
  cancel_by_user_id: '',
  user_cancel_name: '',
}

const schema = Yup.object().shape({
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  purchase_order: Yup.string(),
  billing_type_id: Yup.number().required('* Seleccione un tipo de facturación').nonNullable(),
  bidding: Yup.boolean().default(false),
  bidding_value: Yup.string().when('bidding', {
    is: true,
    then: (schema) => schema.required('* Complete el Nro. de Licitación').min(1, '*El valor de la licitación es incorrecto'),
    else: (schema) => schema
  }),
  de_blister: Yup.boolean().default(false),
});

const dataAdapter = (values) => {
  let data = {
    ...values,
    // General
    customer_supplier_business_name: `[${values.customer_supplier_id}] ${values.customer_supplier_business_name}`,
    seller_name: `[${values.seller_id}] ${values.seller_name}`,
    purchase_order: values?.purchase_order || '',
    bidding: Boolean(values?.bidding),
    bidding_value: Boolean(values?.bidding) ? values.bidding_value : '',
    // Artículos,
    price_update_id: values?.price_update_id || '',
    de_blister: Boolean(values?.de_blister),
    por_unidades: Boolean(values?.por_unidades),
    // Destinatario
    dispatch_date: values?.dispatch_date ? moment(values.dispatch_date).format('YYYY-MM-DD') : '',
    requires_coordination: Boolean(values?.requires_coordination),
    to_patient: Boolean(values?.to_patient),
    remito_para_terceros: Boolean(values?.remito_para_terceros),
    home_delivery_option_id: values?.home_delivery_option_id || '',
    patient_id: values?.patient_id || '',
    patient_fullname: values?.patient_fullname || '',
    patient_documentation: values?.patient_documentation || '',
    patient_affiliate_number: values?.patient_affiliate_number || '',
    address_id_traza: values?.address_id_traza || '',
    direccion_traza: values?.address_id_traza ? `${values?.traza_name}${values?.traza_domicilio ? ` ${values.traza_domicilio}` : ''}${values?.traza_localidad ? ` ${values.traza_localidad}` : ''}${values?.traza_gln ? ` | GLN: ${values.traza_gln}` : ''}` : '',
    address_id_ship: values?.address_id_ship || '',
    direccion_ship: values?.address_id_ship ? `${values?.ship_name}${values?.ship_domicilio ? ` ${values.ship_domicilio}` : ''}${values?.ship_localidad ? ` ${values.ship_localidad}` : ''}${values?.ship_gln ? ` | GLN: ${values.ship_gln}` : ''}` : '',
    observations: values?.observations || '',
    dispatch_observations: values?.dispatch_observations || '',
    // Misc.
    issue_date: moment(values.issue_date).format('YYYY-MM-DD'),
    created_at: moment(values.created_at).format('YYYY-MM-DD'),
    updated_at: values?.updated_at ? moment(values.updated_at).format('YYYY-MM-DD') : '',
    updated_by_user_id: values?.updated_by_user_id || '',
    user_update_name: values?.user_update_name || '',
    revocation_user_id: values?.revocation_user_id || '',
    revocation_user_name: values?.revocation_user_name || '',
    revocation_notes: values?.revocation_notes || '',
    revocation_date: values?.revocation_date ? moment('YYYY-MM-DD') : '',
    revocation_auth_sent_date: values?.revocation_auth_sent_date ? moment(values.revocation_auth_sent_date).format('YYYY-MM-DD') : '',
    cancel_at: values?.cancel_at ? moment(values.cancel_at).format('YYYY-MM-DD') : '',
    cancel_by_user_id: values?.cancel_by_user_id || '',
    user_cancel_name: values?.user_cancel_name || '',
  }

  return data
}

const updateDataAdapter = (values) => {
  let data = {
    id: values.id,
    // General
    business_unit_id: values.business_unit_id,
    customer_supplier_id: values.customer_supplier_id,
    seller_id: values.seller_id,
    payment_condition_id: values.payment_condition_id,
    purchase_order: values?.purchase_order || null,
    billing_type_id: values.billing_type_id,
    bidding: values?.bidding || null,
    bidding_value: Boolean(values?.bidding) ? values?.bidding_value : null,
    // Artículos
    price_update_id: values?.price_update_id || null,
    por_unidades: Boolean(values?.por_unidades),
    de_blister: Boolean(values?.de_blister),
    // Destinatario
    dispatch_date: values?.dispatch_date || null,
    requires_coordination: Boolean(values?.requires_coordination),
    to_patient: Boolean(values?.to_patient),
    remito_para_terceros: Boolean(values?.remito_para_terceros),
    id_tercero_para_remitir: values?.id_tercero_para_remitir || null,
    home_delivery_option_id: values?.home_delivery_option_id || null,
    patient_id: values?.patient_id || null,
    address_id_traza: values?.address_id_traza || null,
    address_id_ship: values?.address_id_ship || null,
    observations: values?.observations || null,
    dispatch_observations: values?.dispatch_observations || null,
  }

  return data
}

export {
  initialValues,
  schema,
  dataAdapter,
  updateDataAdapter
}