import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { useUsers, usePedidos } from 'hooks'

export const useComentarios = () => {
  const { handleGetUsers } = useUsers()
  const { loading, handleObtenerComentariosPedido, handleCrearComentarioPedido } = usePedidos()

  const obtenerUsuarios = useCallback(async () => {
    try {
      const response = await handleGetUsers()
      const data = response.data.result.map(d => ({
        id: d.id,
        display: d.name,
      }))

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleGetUsers])

  const obtenerComentarios = useCallback(async (idPedido, params = {}) => {
    try {
      const response = await handleObtenerComentariosPedido(idPedido, params)
      return response.data.result
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleObtenerComentariosPedido])

  const crearComentario = useCallback(async (idPedido, comentario) => {
    try {
      await handleCrearComentarioPedido(idPedido, comentario)
      toast.success(`Comentario agregado correctamente al Pedido #${idPedido}`)
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCrearComentarioPedido])

  // const handleSubmit = async () => {
  //   try {
  //     await handleAddNote(id, { note: observations })
  //     setObservations("")
  //   } catch (error) {
  //     toast.error(error.message)
  //   } finally {
  //     getLocalNotes()
  //   }
  // }

  return {
    loading,
    obtenerUsuarios,
    obtenerComentarios,
    crearComentario,
  }
}