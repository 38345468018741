import { useTransactionalDocuments } from 'hooks'
import { helix } from 'ldrs'
import { useState, useCallback, useEffect } from 'react'
import { Button, Modal, OverlayTrigger, Table, Tooltip} from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'


const AgregarOrdenesModal = ({ show, onHide, selectedRows, setSelectedRows, ordenesEgresoDisponibles, faltantes, setFaltantes }) => {
    const [text, setText] = useState('');
    const [count, setCount] = useState(0);
    const [countEncontrados, setCountEncontrados] = useState(0);
    const [idsDisponibles, setIdsDisponibles] = useState([])

    const countElements = (text) => {
      return text.split(',').filter(Boolean).length;
    };

    const handlePaste = (e) => {
      let cadenaIds = ''
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text');
        if(pastedData.includes('\n')){
            const values = pastedData
              .split('\n')          
              .map(val => val.trim())
              .filter(Boolean);  
            cadenaIds = values.join(',');
        }
        else{
          cadenaIds = pastedData
        }

        setText(cadenaIds);        
        setCount(cadenaIds.split(',').length); 
            
        const selRows = ordenesEgresoDisponibles
            .filter(d=>cadenaIds.split(',').includes(d.id.toString()))
            .map(s=>({
                id: s.id, 
                afip_id: s.afip_id, 
                checked: true, 
                remito_nro_cbt_completo:s.remito_nro_cbt_completo
            }))
            

        const notIn = cadenaIds.split(',').filter(d=>!idsDisponibles.includes(+d))

        setFaltantes(notIn.join(","))
        setCountEncontrados(selRows.length)
        setSelectedRows(selRows)
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setText(value);             
        setCount(countElements(value));

        const ids = value.split(',');

        const selRows = ordenesEgresoDisponibles
            .filter(d=>ids.includes(d.id.toString()))
            .map(s=>({
                id: s.id, 
                afip_id: s.afip_id, 
                checked: true, 
                remito_nro_cbt_completo:s.remito_nro_cbt_completo
            }))

        const notIn = ids.filter(d=>!idsDisponibles.includes(+d))

        setFaltantes(notIn.join(","))
        setCountEncontrados(selRows.length)
        setSelectedRows(selRows)
    };

    useEffect(()=>{
        if(show){
            setText("")
            setCount(0)
            setCountEncontrados(0)
            setSelectedRows([])
            setFaltantes("")
            if(ordenesEgresoDisponibles){
              setIdsDisponibles(ordenesEgresoDisponibles?.map(d=>d.id))
            }
            else{
              setIdsDisponibles([])
            }
        }
    }, [show])

    useEffect(()=>{
      if(ordenesEgresoDisponibles){
        setIdsDisponibles(ordenesEgresoDisponibles?.map(d=>d.id))
      }
    }, [ordenesEgresoDisponibles])

    useEffect(()=>{
        helix.register()
    }, [])


    return (
        <Modal size="md" show={show} onHide={onHide} backdrop='static'>
        <Modal.Header closeButton>
            <Modal.Title>Agrupamiento de órdenes de egreso</Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
            <div className="row">
                <div className="col">
                <div className='form-label'>Total de elementos copiados: {count}</div>
                <div className='form-label'>Total de elementos encontrados: {countEncontrados}</div>
                  <textarea
                      className='form-control mt-5 mb-5'
                      value={text}
                      onPaste={handlePaste} // Controlamos el evento de pegado
                      onChange={handleChange} // Para controlar cambios normales si el usuario escribe
                      placeholder="Pega aquí la columna del Excel"
                      rows="10"
                      cols="50"
                  />
                </div>
            </div>
            <labell className="form-label">Elementos no encontrados</labell>
            <textarea value={faltantes} className='form-control mt-0'disabled />
          



        </Modal.Body>
        <Modal.Footer>
            <div className="row">
                <div className="col">
                    <Button variant="secondary" onClick={()=>onHide(null)} className='me-3'>Cerrar</Button>
                </div>
            </div>
        </Modal.Footer>
        </Modal>
    )
}

export default AgregarOrdenesModal