import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { helix } from 'ldrs'

import { useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, AgrupacionModal, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'

import { comprobanteLabel, comprobanteColor } from 'pages/Sells/common/utils/'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const TabComprobantes = ({ setTab, serviceComprobantes }) => {
  const { id } = useParams()

  const { loading, obtenerComprobantes, descargarFactura, descargarFacturaSimplificada, descargarRemito } = serviceComprobantes
  const [comprobantes, setComprobantes] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [modalAgrupacion, setModalAgrupacion] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [idsAgrupados, setIdsAgrupados] = useState([])
  const [esPedido, setEsPedido] = useState(true)

  const handleObtenerComprobantes = useCallback(async () => {
    try {
      let params = {
        sales_order_id: id
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }
      const response = await obtenerComprobantes(params)
      const data = response.data.result.map(d => ({
        ...d,
        label: comprobanteLabel(d.estado),
        label_color: comprobanteColor(d.estado),
      }))

      setComprobantes(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setComprobantes([])
      setTotalCount(0)
    }
  }, [id, query, obtenerComprobantes])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (
      currentRow?.tipo_comprobante_codigo_interno !== 4 &&                // 4: Remito
      currentRow?.estado?.toUpperCase() != 'ERROR_NEGOCIO_AFIP' &&
      currentRow?.estado?.toUpperCase() != 'DEFERRED' &&
      currentRow?.estado?.toUpperCase() != 'ERROR_PROCESAR_COMPROBANTE' &&
      currentRow?.estado?.toUpperCase() != 'ERROR_CLIENT'
    ) {
      actions.push({
        label: 'Descargar comprobante',
        icon: 'bi bi-download text-primary',
        action: async () => {
          await descargarFactura(currentRow?.tipo_comprobante_codigo_interno, currentRow?.id_real)
        }
      })

      if (currentRow?.texto_libre != null && currentRow?.texto_libre != '') {
        actions.push({
          label: 'Descargar comprobante simplificado',
          icon: 'bi bi-download text-primary',
          action: async () => {
            await descargarFacturaSimplificada(currentRow?.tipo_comprobante_codigo_interno, currentRow?.id_real)
          }
        })
      }
    }

    if (currentRow?.tipo_comprobante_codigo_interno == 4) { // 4: Remito
      actions.push({
        label: 'Descargar remito',
        icon: 'bi bi-download text-primary',
        action: async () => {
          let copias = 2
          await descargarRemito(currentRow?.id_real, copias)
        }
      })
    }

    return actions
  }
  /* Menu contextual */

  const columns = useMemo(() => [
    {
      Header: <p className="m-0 p-0 text-center">IDs O. egreso</p>,
      id: 'ids_pedidos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.out_order_ids && row.out_order_ids.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  setModalTitle('Órdenes de egreso')
                  setIdsAgrupados(row.out_order_ids.split(','))
                  setEsPedido(false)
                  setModalAgrupacion(true)
                }}
              >
                Órdenes agrupadas
              </span>
            : <Tooltip text='Ir a OE'><Link to={`${routes.OUT_ORDERS}/${row.out_order_ids}`}>{row.out_order_ids}</Link></Tooltip>
          }
        </p>
      ),
      hasOrder: true,
    },
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    handleObtenerComprobantes()
  }, [handleObtenerComprobantes])

  useEffect(() => {
    helix.register()
  }, [])

  useEffect(() => {
    setTab('comprobantes')
  }, [])

  return (
    <>
      <TableContainer>
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={comprobantes} queryMD={query} setQueryMD={setQuery} paginationOptions={paginationOptions} />
      </TableContainer>

      <AgrupacionModal show={modalAgrupacion} onClose={() => setModalAgrupacion(false)} title={modalTitle} ids={idsAgrupados} pedido={esPedido} />

      <ContextMenu
        ref={contextRef}
        currentPos={currentPos}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
      />
    </>
  )
}

export default TabComprobantes