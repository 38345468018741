import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'
import { helix } from 'ldrs'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useReports, useOutOrders, useTransactionalDocuments, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS, COLUMNS_SIN_COMPROBANTES } from './columns'

import { labelColor, statusDisplay, labelColorSinComprobantes } from './constants'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  to_date: moment().format('YYYY-MM-DD'),
  sin_comprobantes: 1,
}

const RemitosReport = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetReporteRemitos, handleGetReporteRemitosSinComprobantesDetalle, handleGetReporteRemitosVerifarma } = useReports()
  const [data, setData] = useState([])
  const [conComprobantes, setConComprobantes] = useState(false)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { handlePrintWaybill } = useOutOrders()
  const { handlePrintFactura } = useTransactionalDocuments()
  const { handlePrintRemitoFa } = useOutOrders()

  const getReporteRemitos = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.reporte_remitos) {
        const { reporte_remitos } = currentUser

        params = {
          ...params,
          id: reporte_remitos?.id || '',
          id_text: reporte_remitos?.id_text || '',
          orden_compra: reporte_remitos?.orden_compra || '',
          from_date: reporte_remitos?.from_date || '',
          to_date: reporte_remitos?.to_date || '',
          text: reporte_remitos?.text || '',
          paciente: reporte_remitos?.paciente || '',
          paciente_documento: reporte_remitos?.paciente_documento || '',
          remito: reporte_remitos?.remito || '',
          comprobante: reporte_remitos?.comprobante || '',
          sin_comprobantes: conComprobantes ? 0 : 1,
          verifarma: reporte_remitos?.verifarma || '',
          direccion: reporte_remitos?.direccion || '',
          localidad: reporte_remitos?.localidad || '',
          provincia: reporte_remitos?.provincia || '',
        }
      }

      const response = await handleGetReporteRemitos(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color_comprobante: conComprobantes ? labelColor(d.FacturaEstado) : '',
        status_display_comprobante: conComprobantes ? statusDisplay(d.FacturaEstado) : '',
        label_color_guia: labelColorSinComprobantes(d.GuiaEstadoId),
        status_display_guia: d.GuiaEstado,
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetReporteRemitos, query, currentUser])

  const exportVerifarma = async(data) => {
    try {
      const params = data.map(d => d.RemitoId)

      if (params && params.length) {
        const response = await handleGetReporteRemitosVerifarma({
          remitos_ids: params.join(','),
        })

        const blob = new Blob([response.data]);

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'archivo.zip'; // Puedes cambiar el nombre del archivo según sea necesario
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleExportVerifarma = async (values) => {
    const params = {
      ...query,
      pageNumber: 1,
      pageSize: undefined,
    }
    const response = await handleGetReporteRemitos(params)

    if (response.data.result) {
      const datos = response.data.result.filter(d => !d.Verifarma)

      if (datos && datos.length) {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: "Hay remitos que no están marcados como verifarma, ¿desea exportarlos igual?",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, exportar",
          customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          exportVerifarma(response.data.result)
        }
      }

      exportVerifarma(response.data.result)
    }
  }

  const exportData = async (values) => {
    try {
      const params = {
        ...query,
        ...values,
        sin_comprobantes: !conComprobantes,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteRemitos(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          let data = {}

          if (!conComprobantes) {
            data = {
              'Nro.': d.RemitoId,
              'ID Pedido': d.PedidoId,
              '¿Requiere coordinación': d.RequiereCoordinacionEntrega,
              'Estado de coordinación': d.EstadoCoordinacionEntrega,
              'Orden de compra': d.OrdenCompra,
              'ID O. Egreso': d.OrdenEgresoId,
              'RemitoFecha': moment(d.RemitoFecha).format('DD/MM/YYYY'),
              'Remito': d.RemitoNro,
              'RemitoFechaCancel': d?.RemitoFechaCancel ? moment(d.RemitoFechaCancel).format('DD/MM/YYYY') : '-',
              'Valor Remito': d?.remitoValor || 0,
              'ID Cliente': d.ClienteId,
              'Razón social': d.RazonSocial,
              'ID Paciente': d.PacienteId,
              'Nombre completo': d.nombrePaciente,
              'Documento': d.docPaciente,
              'Nro. afiliado': d.nroAfiliadoPaciente,
              'Teléfono': d?.telPaciente,
              'Celular': d?.celPaciente,
              'Cond. venta': d.CondicionVenta,
              'Transporte': d.Transporte,
              'Serv. transporte': d.ServicioTransporte,
              'Operativa': d?.Operativa,
              'Dirección Traza.': d.DireccionTraza,
              'Domicilio Traza.': d.DomicilioTraza,
              'Localidad Traza.': d.LocalidadTraza,
              'Provincia Traza.': d.ProvinciaTraza,
              'GLN': d.gln,
              'Verifarma': d?.Verifarma ? 'Sí' : 'No',
              'ID O. Envío': d.GuiaId,
              'Estado O. Envío': d.GuiaEstado,
            }

            delete data.GuiaEstadoId
          } else {
            data = {
              'Nro.': d.RemitoId,
              'ID Pedido': d.PedidoId,
              '¿Requiere coordinación': d.RequiereCoordinacionEntrega,
              'Estado de coordinación': d.EstadoCoordinacionEntrega,
              'Orden de compra': d.OrdenCompra,
              'ID O. Egreso': d.OrdenEgresoId,
              'RemitoFecha': moment(d.RemitoFecha).format('DD/MM/YYYY'),
              'Remito': d.RemitoNro,
              'RemitoFechaCancel': d?.RemitoFechaCancel ? moment(d.RemitoFechaCancel).format('DD/MM/YYYY') : '-',
              'Valor Remito': d?.remitoValor || 0,
              'ID Cliente': d.ClienteId,
              'Razón social': d.RazonSocial,
              'ID Paciente': d.PacienteId,
              'Nombre completo': d.nombrePaciente,
              'Documento': d.docPaciente,
              'Nro. afiliado': d.nroAfiliadoPaciente,
              'Teléfono': d?.telPaciente,
              'Celular': d?.celPaciente,
              'Cond. venta': d.CondicionVenta,
              'Transporte': d.Transporte,
              'Serv. transporte': d.ServicioTransporte,
              'Operativa': d?.Operativa,
              'Dirección Traza.': d.DireccionTraza,
              'Domicilio Traza.': d.DomicilioTraza,
              'Localidad Traza.': d.LocalidadTraza,
              'Provincia Traza.': d.ProvinciaTraza,
              'GLN': d.gln,
              'FechaComprobante': d?.FechaComprobante ? moment(d.FechaComprobante).format('DD/MM/YYYY') : '-',
              'TipoComprobanteDet': d?.TipoComprobanteDet ? d.TipoComprobanteDet : '-',
              'NroComp': d?.NroComp ? d.NroComp : 'Sin nro. comprobante',
              'CAE': d.CAE,
              'FacturaEstado': statusDisplay(d.FacturaEstado),
              'Verifarma': Boolean(d.Verifarma) ? 'Sí' : 'No',
              'ID O. Envío': d.GuiaId,
              'Estado O. Envío': d.GuiaEstado,
              'importe_gravado': d?.importe_gravado || 0,
              'importe_no_gravado': d?.importe_no_gravado || 0,
              'importe_exento': d?.importe_exento || 0,
              'importe_subtotal': d?.importe_subtotal || 0,
              'importe_otros_tributos': d?.importe_otros_tributos || 0,
              'importe_total': d?.importe_total || 0,
            }
          }

          return data
        })

        let ws = XLSX.utils.json_to_sheet(datos)

        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Reportes")
        XLSX.writeFile(wb, `Remitos${conComprobantes ? ' sin comprobantes' : ''} ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const exportDataDetail = async (values) => {
    try {
      const params = {
        ...query,
        ...values,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteRemitosSinComprobantesDetalle(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          let data = {
            ...d,
            'RemitoFecha': moment(d.RemitoFecha).format('DD/MM/YYYY'),
            'RemitoFechaCancel': d?.RemitoFechaCancel ? moment(d.RemitoFechaCancel).format('DD/MM/YYYY') : '-',
            'Verifarma': d?.Verifarma ? 'Sí' : 'No',
          }

          return data
        })

        let ws = XLSX.utils.json_to_sheet(datos)

        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Reportes")
        XLSX.writeFile(wb, `Detalle - Remitos sin comprobantes ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];
  
    if (currentRow?.RemitoId && currentRow.PedidoId) {
      actions.push({
        label: "Descargar Remito",
        icon: "bi bi-download text-primary",
        action: () => printWaybill(),
      });
  
      actions.push({
        label: "Descargar Verifarma",
        icon: "bi bi-download text-success",
        action: () => exportVerifarma([currentRow]),
      });

      actions.push({
        label: 'Descargar RemitoFA',
        icon: 'bi bi-download text-primary',
        action: () => downloadRemitoFA(),
      });

    }
  
    if (currentRow?.NroComp) {
      actions.push({
        label: 'Descargar Comprobante',
        icon: 'bi bi-download text-primary',
        action: () => printFactura(),
      });
    }
  
    return actions;
  };
  
  const downloadRemitoFA = () => {
    Swal.fire({
      title: "Descargar RemitoFA",
      html: `
        <input type="text" id="codigoFaInput" class="swal2-input" style="width: 350px;" placeholder="Código FA (Ej: 91)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'Código FA (Ej: 91)'">
        <input type="text" id="caiFaInput" class="swal2-input" style="width: 350px;" placeholder="CAI FA (Ej: 50018204902122)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'CAI FA (Ej: 50018204902122)'">
        <input type="text" id="fechaVtoFaInput" class="swal2-input" style="width: 350px;" placeholder="Fecha Vto FA (Ej: 04/01/2025)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'Fecha Vto FA (Ej: 04/01/2025)'">
        <input type="text" id="numeradoInicioInput" class="swal2-input" style="width: 350px;" placeholder="Numerado Inicio (Ej: 0903-00003001)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'Numerado Inicio (Ej: 0903-00003001)'">
        <input type="text" id="numeradoFinInput" class="swal2-input" style="width: 350px;" placeholder="Numerado Fin (Ej: 0903-00005000)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'Numerado Fin (Ej: 0903-00005000)'">
        <input type="text" id="fechaImpresionInput" class="swal2-input" style="width: 350px;" placeholder="Fecha Impresión (Ej: ENERO 2024)" oninput="this.placeholder = ''" onblur="if(!this.value) this.placeholder = 'Fecha Impresión (Ej: ENERO 2024)'">
      `,
      showCancelButton: true,
      confirmButtonText: "Descargar",
      cancelButtonText: "Cerrar",
      customClass: {
        confirmButton: 'btn btn-primary', 
        cancelButton: 'btn btn-secondary'
      },

      width: '550px',
      preConfirm: () => {
        const codigoFa = document.getElementById('codigoFaInput').value;
        const caiFa = document.getElementById('caiFaInput').value;
        const fechaVtoFa = document.getElementById('fechaVtoFaInput').value;
        const numeradoInicio = document.getElementById('numeradoInicioInput').value;
        const numeradoFin = document.getElementById('numeradoFinInput').value;
        const fechaImpresion = document.getElementById('fechaImpresionInput').value;
  
        if (!codigoFa || !caiFa || !fechaVtoFa || !numeradoInicio || !numeradoFin || !fechaImpresion) {
          Swal.showValidationMessage('Debe completar todos los campos');
          return false;
        }
        
        return {
          codigoFa,
          caiFa,
          fechaVtoFa,
          numeradoInicio,
          numeradoFin,
          fechaImpresion
        };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { codigoFa, caiFa, fechaVtoFa, numeradoInicio, numeradoFin, fechaImpresion } = result.value;

        imprimirRemitoFa({
          codigoFa,
          caiFa,
          fechaVtoFa,
          numeradoInicio,
          numeradoFin,
          fechaImpresion
        });
      }
    });
  };
  
  
  const imprimirRemitoFa = async (data) => {
    if (currentRow) {
      try {
        let copias = 2
        data.id = currentRow.OrdenEgresoId
        const response = await handlePrintRemitoFa(data, copias)

        if (response.status === 200) {
          toast.success('¡Remito descargado!')

          const blob = await response.data

          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${currentRow.RemitoNro.replaceAll(' ', '')}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {

          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  
  

  const printWaybill = async () => {
    if (currentRow) {
      try {
        let copias = 2
        const response = await handlePrintWaybill(currentRow.OrdenEgresoId, copias)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${currentRow.RemitoNro.replaceAll(' ', '')}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const printFactura = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintFactura(currentRow?.CodigoInternoComprobante, currentRow?.ComprobanteId)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Comprobante descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `comprobante-${currentRow.NroComp.replaceAll(' ', '')}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          toast.error('Error al descargar el comprobante')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  /* Fin Menu contextual */

  const columns = useMemo(() => {
    if (!conComprobantes) {
      return  [
        ...COLUMNS_SIN_COMPROBANTES,
        {
          Header: '',
          id: 'actions',
          className:'col-icons',
          accessor: (row) =>
            <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        }
      ]
    } else {
      return [
        ...COLUMNS,
        {
          Header: '',
          id: 'actions',
          className:'col-icons',
          accessor: (row) =>
            <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        }
      ]
    }
  }, [conComprobantes, COLUMNS, COLUMNS_SIN_COMPROBANTES])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getReporteRemitos()
  }, [getReporteRemitos])

  useEffect(() => {
    if (currentUser?.reporte_remitos) {
      setConComprobantes(currentUser.reporte_remitos?.sin_comprobantes ? true : false)
    }
  }, [currentUser?.reporte_remitos])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Reporte de Remitos'
        filter={
          <Filter
            setData={setData}
            data={data}
            setTotalCount={setTotalCount}
            query={query}
            setQuery={setQuery}
            exportData={exportData}
            exportDataDetail={exportDataDetail}
            handleExportVerifarma={handleExportVerifarma}
            conComprobantes={conComprobantes}
            handleConComprobantes={setConComprobantes}
          />
        }
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default RemitosReport