import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders, useOutOrders } from 'hooks'

import { AuthModal } from './'

import { OrderTypeI, SalesOrderStatusI } from 'interfaces'

import { checkIsAllowed } from '_metronic/helpers'

import routes from 'app/routing/routes'
import './styles.sass'

const MenuActions = ({ values, handleGetSalesOrder, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigation = useNavigate()
  const {currentUser} = useAuth();
  const [currentUserRoles, setCurrentUserRoles] = useState([]);

  const [modal, setModal] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { auth } = useAuth()
  const {
    handleCheckSalesOrderPVP,
    handleUpdateSalesOrderStatus,
    handleClonseSalesOrder,
    handleCreateOutOrder,
    handleCreatePartial,
    handlePrintSalesOrder,
    handleCancelSalesOrderSpecial,
    handleCancelacionParcial,
  } = useSalesOrders()

  const { handleGetOutOrders } = useOutOrders()

  const updateSalesOrderStatus = async (newStatus) => {
    try {
      if (!values.address_id_traza && newStatus === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA) {
        toast.error("El domicilio de entrega es obligatorio 2")
        return
      }

      if (newStatus === SalesOrderStatusI.AUTORIZADO) {
        await handleCreateOutOrder(values?.id)
      } else {
        await handleUpdateSalesOrderStatus(values.id, newStatus)
      }

      toast.success("Registro actualizado correctamente", { theme: "colored" })

      if (newStatus === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA) {
        navigation(routes.SALES_ORDERS)
      } else if (newStatus === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO) {
        navigation(routes.SALES_ORDERS);
      } else {
        navigation(routes.SALES_ORDERS)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
      handleClose()
    }
  }

  const enviarAOperaciones = async()=>{

    try{
      const resp = await Swal.fire({
          title: "Advertencia",
          text: "Envía el pedido a operaciones?",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No",
          confirmButtonText: "Enviar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
          reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(values?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA)
          await handleUpdateSalesOrderStatus(values?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
          await handleCreateOutOrder(values?.id)          
          toast.success("Orden actualizada correctamente",{theme: "colored" })
          navigation(routes.SALES_ORDERS)
          
      }
    }
    catch(err){

    }

  }
  const cloneSalesOrder = async () => {
    try {
      await handleClonseSalesOrder(values.id, auth?.user.id, 'token')

      toast.success("Registro clonado correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleClose()
    }
  }

  const createOutOrder = async () => {
    try {
      if(!values.address_id_traza || ! values.address_id_ship){
        toast.error("El domicilio de entrega es obligatorio 3")
        return
      }
      await handleCreateOutOrder(values.id, auth?.user.id, 'token')

      toast.success("Orden creada correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
      handleClose()
    }
  }

  const availableActions = () => {
    let actions = []

    if (values) {
      if (values.sales_order_status_id === SalesOrderStatusI.BORRADOR && values.detail && values.detail.length > 0 &&
         values.order_type_id != OrderTypeI.PEDIDO_EGRESO) {
        actions.push({
          label: "Enviar a autorizar",
          icon: "bi bi-box-arrow-in-down-right text-success",
          // action: actionRentAutorize
          action: () => setModal(true)
        })
      }

      if (values.sales_order_status_id === SalesOrderStatusI.BORRADOR && values.detail && values.detail.length > 0 &&
        values.order_type_id == OrderTypeI.PEDIDO_EGRESO) {
        actions.push({
          label: "Enviar a operaciones",
          icon: "bi bi-box-arrow-in-down-right text-success",
          // action: actionRentAutorize
          action: enviarAOperaciones
        })
      }

      if (
        values.sales_order_status_id === SalesOrderStatusI.BORRADOR ||
        values.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO ||
        values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA ||
        values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
      ) {
        actions.push({
          label: "Cancelar pedido",
          icon: "bi bi-trash text-danger",
          action: cancelOrder
        })
      }

      // if (values.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO) {
      //   actions.push({
      //     label: "Pasar a Borrador",
      //     icon: "bi bi-layout-text-sidebar-reverse text-success",
      //     action: actionActivar
      //   })
      // }


      if (values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA
        && parseInt(values?.lines) > 0
        && checkIsAllowed(currentUserRoles, ["CONTROL_RENTAS", "ADMIN"])
      ) {
        actions.push({
          label: 'Autorizar rentabilidad',
          icon: 'bi bi-check-all text-success',
          action: actionRentAutorized
        })

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: actionRentNotAutorize
        })
      }

      if (values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
        && parseInt(values?.lines) > 0
        && checkIsAllowed(currentUserRoles, ["CONTROL_CRED", "ADMIN"])
      ) {
        actions.push({
          label: 'Autorizar crédito',
          icon: 'bi bi-check-all text-success',
          action: actionCreditAutorize
        })

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: actionCreditNotAutorize
        })
      }

      if (values.sales_order_status_id === SalesOrderStatusI.AUTORIZADO) {
        actions.push({
          label: "Crear órden de egreso",
          icon: "bi bi-box-arrow-up-right text-success",
          action: createOutOrder
        })

        actions.push({
          label: "Cancelar pedido",
          icon: "bi bi-x-circle text-danger",
          action: cancelOrder
        })
      }
      if (
        values.sales_order_status_id === SalesOrderStatusI.FALTA_STOCK ||
        values.sales_order_status_id === SalesOrderStatusI.RESERVA_PARCIAL ||
        values.sales_order_status_id === SalesOrderStatusI.EN_REVISION ||
        values.sales_order_status_id === SalesOrderStatusI.COORDINACION_ENTREGA ||
        values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_PREPARACION
      ) {
        if (values.sales_order_status_id !== SalesOrderStatusI.EN_REVISION) {
          actions.push({
            label: "Crear orden de egreso parcial",
            icon: "bi bi-box-arrow-up-right text-success",
            action: createOutOrderPartial
          })
        }

        if (values.sales_order_status_id === SalesOrderStatusI.EN_REVISION || values.sales_order_status_id === SalesOrderStatusI.COORDINACION_ENTREGA || values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_PREPARACION) {
          if (values.out_orders_ids.split(',').length == 1) {
            actions.push({
              label: 'Cancelar',
              icon: 'bi bi-x-circle text-danger',
              action: cancelSalesOrderSpecial
            })
          }

          if (values.out_orders_ids.split(',').length > 1) {
            actions.push({
              label: 'Cancelar parcialmente',
              icon: 'bi bi-x-circle text-danger',
              action: cancelacionParcial
            })
          }
        }
      }

      actions.push({
        label: "Descargar",
        icon: "bi bi-download text-success",
        action: printSalesOrder
      })
      actions.push({
        label: "Clonar",
        icon: "bi bi-clipboard-check",
        action: cloneSalesOrder
      })
    }

    return actions
  }

  const printSalesOrder = async () => {
    if (values.id) {
      if(values.detail.length<=0){
        toast.error("El pedido no tiene artículos cargados")
        return
      }
      try {
        const response = await handlePrintSalesOrder(values.id)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Pedido descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `pedido-${values.id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionRentAutorize = async () => {
    if (values.address_id_ship == "" || !values.address_id_ship || values.address_id_traza == "" || !values.address_id_traza) {
      toast.error("El pedido no tiene dirección cargada")
      return
    }

    try {
      const response = await handleCheckSalesOrderPVP(values.id)

      let text = '¿Enviar a autorizar la rentabilidad del pedido?';
      if (response.data.result.length > 0) {
        let qty = response.data.result.length;
        text = `El pedido #${values.id} tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere enviarlo a autorizar?`;
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Sí, enviar a autorizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      });

      if (resp.isConfirmed) {
        await updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA);
      }

      handleGetSalesOrder();
    } catch(err){
      toast.error(err.message)
    }
  }

  const actionActivar = async () => {
    const resp = await Swal.fire({
      title: "Advertencia",
      text: `Pasa el pedido #${values.id} a estado Borrador?`,
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No",
      confirmButtonText: "Si, aceptar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      try {

        // submitRef.current.click()
        handleGetSalesOrder();
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const cancelOrder = async () => {
    const inputValue = ''
    const resp = await Swal.fire({
      title: "Advertencia",
      text: `¿Confirma la cancelación del pedido #${values.id}?`,
      icon: "warning",
      input: "textarea",
        inputValue,
        inputLabel: "Motivo",
        inputAttributes: {
          autocomplete:"off"
        },
        inputValidator: (value) => {
          if (!value || value.trim() == '') {
            return "Por favor, completar motivo de rechazo"
          }
        },
      showDenyButton: true,
      denyButtonText: "No, cerrar",
      confirmButtonText: "Si, cancelar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      try {
        await handleUpdateSalesOrderStatus(values.id, SalesOrderStatusI.CANCELADO, { observaciones: resp.value })
        toast.success(`Pedido #${values.id} cancelado`)
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetSalesOrder()
      }
    }
  }

  const createOutOrderPartial = async () => {
    const resp = await Swal.fire({
      title: 'Advertencia',
      text: `¿Confirma la creación de una orden de egreso parcial con los artículos disponibles para el pedido #${values.id}?`,
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: 'Si, crear',
      customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
      reverseButtons: true
    })

    if (resp.isConfirmed) {
      const _reserva = values.detail_reserved_and_used.reduce((acc, val) => +acc + +val.qty_reserved, 0)

      if (_reserva <= 0) {
        toast.error("El pedido no tiene artículos reservados para crear una orden de egreso parcial")
        return
      }

      await handleCreatePartial(values.id)
      handleGetSalesOrder()
    }
  }

  const actionRentAutorized = async () => {
    try {
      const response = await handleCheckSalesOrderPVP(values.id)

      let text = `¿Autoriza la rentabilidad del pedido #${values.id}?`

      if (response.data.result.length !== 0) {
        let qty = response.data.result.length
        text = `El pedido #${values.id} tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere autorizar la rentabilidad?`
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Sí, autorizar pedido",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
      }

      handleGetSalesOrder()
    } catch (error) {
      toast.error(error.message0)
    }
  }

  const actionRentNotAutorize = async () => {
    try {
        const inputValue = '';
        const resp = await Swal.fire({
          title: 'Advertencia',
          text: `¿Confirma el rechazo de la autorización de rentabilidad del pedido #${values.id}?`,
          icon: 'warning',
          input:"textarea",
          inputValue,
          inputLabel: "Motivo",
          inputAttributes: {
              autocomplete:"off"
          },
          inputValidator: (value) => {
              if(!value || value.trim()==''){
                return "Por favor, completar motivo de rechazo"
              }
          },
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: 'Si, rechazar',
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          await handleUpdateSalesOrderStatus(values.id, SalesOrderStatusI.NO_AUTORIZADO, {observaciones:resp.value})
          toast.success("Autorización rechazada correctamente", { theme: "colored" })      
          // await updateSalesOrderStatus(SalesOrderStatusI.NO_AUTORIZADO)
          handleGetSalesOrder();
        }
      }
      catch(err){
        toast.error(err.message)        
      }
  }


  const actionCreditAutorize = async () => {
    try {
      const response = await handleCheckSalesOrderPVP(values.id)

      let text = '¿Autorizar el crédito del pedido?'

      if (response.data.result.length !== 0) {
        let qty = response.data.result.length
        text = `El pedido #${values.id} tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere autorizar el crédito?`
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Sí, autorizar pedido",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await updateSalesOrderStatus(SalesOrderStatusI.AUTORIZADO)
      }

      handleGetSalesOrder()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const actionCreditNotAutorize = async () => {
    try{
        const inputValue = '';
        const resp = await Swal.fire({
          title: 'Advertencia',
          text: `¿Confirma el rechazo de la autorización de crédito del pedido #${values.id}?`,
          icon: 'warning',
          input:"textarea",
          inputValue,
          inputLabel: "Motivo",
          inputAttributes: {
              autocomplete:"off"
          },
          inputValidator: (value) => {
              if(!value || value.trim()==''){
                return "Por favor, completar motivo de rechazo"
              }
          },
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: 'Si, rechazar',
          customCalss: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true,
        })
    
        if (resp.isConfirmed) {
          await handleUpdateSalesOrderStatus(values.id, SalesOrderStatusI.NO_AUTORIZADO, {observaciones:resp.value})
          toast.success("Autorización rechazada correctamente", { theme: "colored" }) 
          // await updateSalesOrderStatus(SalesOrderStatusI.NO_AUTORIZADO)
        }
        handleGetSalesOrder();

    }
    catch(err){
      toast.error(err.message)

    }
  }

  const cancelSalesOrderSpecial = async () => {
    try {
      // let params = {
      //   sales_order_id: values.id,
      // }
      // const outOrdersResponse = await handleGetOutOrders(params)
      // const data = outOrdersResponse.data.result

      // if (data.length > 1) {
      //   toast.error(`El Pedido #${values.id} NO se puede cancelar porque cuenta con más de 1 (una) Órden de egreso`);
      //   return
      // }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Confirma la cancelación del pedido #${values.id}?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cerrar",
        confirmButtonText: "Sí, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        // console.log(`Cancelar pedido #${values.id} - Estado: ${values.sales_order_status_label}`)
        const response = await handleCancelSalesOrderSpecial(values.id)

        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
    }
  }

  const cancelacionParcial = async () => {
    try {

      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a cancelar parcialmente el pedido #${values.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cerrar",
        confirmButtonText: "Sí, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleCancelacionParcial(values.id)

        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
    }
  }

  useEffect(() => {
    if (currentUser) {
      const _roles = currentUser.roles || []
      setCurrentUserRoles(_roles)
    }
  }, [currentUser])

  return (
    <>
      <DropdownButton title="Acciones" disabled={disabled}>
        {availableActions().map((data, index)=>(
          <Dropdown.Item key={index} onClick={() => data.action()}>
            <i className={`${data.icon} pe-3`} />
            {data.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>

      <AuthModal show={modal} onClose={() => setModal(false)} salesOrder={values} handleGetSalesOrder={handleGetSalesOrder} />
    </>

  )
}

const Actions = ({ values, editing, handleEditing, submitRef, cancelRef, handleGetSalesOrder, loading }) => {
  const navigation = useNavigate()
  

  return (
    <div className='d-flex align-items-center'>
      <div className='row'>
        {!editing
          ?
            <>
              <div className='d-flex mt-5'>
                <Button
                  type="button"
                  variant="secondary"
                  className='me-3'
                  onClick={() => navigation(-1)}
                >
                  <i className="ki-duotone ki-left"/>
                  Volver
                </Button>

                {(
                  values.sales_order_status_id === SalesOrderStatusI.BORRADOR ||
                  values.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO
                  // || values.sales_order_status_id === SalesOrderStatusI.CANCELADO
                ) &&
                  <button
                    className='me-3 btn btn-outline btn-outline-primary'
                    type="button"
                    onClick={()=>handleEditing(true)}
                  >
                    <i className="bi bi-pencil" />
                    Editar
                  </button>
                }

                <MenuActions values={values} handleGetSalesOrder={handleGetSalesOrder} disabled={loading || editing} />
              </div>
            </>
          :
            <>
              <div className='d-flex mt-5'>
                <Button
                  variant="secondary"
                  type="button"
                  className='me-3'
                  onClick={() => cancelRef.current.click()}
                >
                  <i className="bi bi-slash-circle"/>
                  Cancelar
                </Button>

                <Button
                  variant="primary"
                  type="button"
                  className='me-3'
                  onClick={() => submitRef.current.click()}
                >
                  <i className="bi bi-save me-2"/>
                  Guardar
                </Button>

                <MenuActions values={values} handleGetSalesOrder={handleGetSalesOrder} disabled={loading || editing}/>
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default Actions