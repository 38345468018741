import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const colorUnidadNegocio = (business_unit_id) => {
  switch (business_unit_id) {
    case 1:
      return 'text-primary'
    case 2:
      return 'text-warning'
    default:
      return 'text-muted'
  }
}

const COLUMNS = [
  {
    Header: <p className='m-0 p-0 text-center'>Uni. de negocio</p>,
    id: 'business_unit_id',
    accessor: (row) => (
      <p className={`m-0 p-0 ${colorUnidadNegocio(row?.business_unit_id)} fw-bolder ${row?.business_unit_id ? '' : 'fst-italic'} text-center`}>
        {row?.business_unit_id ? row.business_unit_name : 'Sin unidad de negocio'}
      </p>
    ),
  },
  {
    Header: <p className='m-0 p-0'>Dueño</p>,
    id: 'customer_suppliers_id',
    accessor: (row) => (
      <p className={`m-0 p-0 ${row?.customer_suppliers_id ? '' : 'fst-italic text-muted'}`}>
        {row?.customer_suppliers_name || 'Sin dueño'}
      </p>
    ),
  },
  {
    Header: <p className='m-0 p-0 text-center'>ID Artículo</p>,
    id: 'id',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.id}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Artículo</p>,
    id: 'name',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.name}</p>
    ),
  },
  {
    Header: <p className='m-0 p-0'>Droga</p>,
    id: 'droga',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.droga}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Laboratorio</p>,
    id: 'laboratorio',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.laboratorio}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>SKU</p>,
    id: 'sku',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.sku}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Disp</p>,
    id: 'Disponible',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.Disponible}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>No Disp</p>,
    id: 'NoDisponible',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.NoDisponible}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Pend Ing</p>,
    id: 'PendienteIngreso',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.PendienteIngreso}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Pend Aut</p>,
    id: 'EnAutorizacion',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.EnAutorizacion}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Res</p>,
    id: 'Reservado',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.Reservado}</p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Falta</p>,
    id: 'FaltaStock',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.FaltaStock}</p>
    )
  },
  {
    Header: () => (
      <OverlayTrigger overlay={<Tooltip style={{ width: '250px'}}>{"Cajas necesarias a comprar (Falta - Pend. Ingreso - Disponible)"}</Tooltip>}>
        <p className='m-0 p-0 text-center'>Comprar</p>
      </OverlayTrigger>
    ),
    id: 'Necesario',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>{row.Necesario}</p>
    )
  },
  {
    Header: () => (
      <OverlayTrigger overlay={<Tooltip style={{ width: '250px'}}>{"Cajas necesarias a comprar incluyendo pendientes de autorización (Falta + Pend. Autorización - Pend. Ingreso - Disponible)"}</Tooltip>}>
        <div className='m-0 p-0 text-center'>Comprar + Aut</div>
      </OverlayTrigger>
    ),
    id: 'NecesarioMasAutorizacion',
    accessor: (row) => (
      <div className='text-center'>{row.NecesarioMasAutorizacion}</div>
    )
  },
]

export {
  COLUMNS
}
