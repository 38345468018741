import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { helix } from 'ldrs'

import { useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'
import ModalDocumentos from './Modal'

const TabDocumentos = ({ loading, editar, pedido, obtenerPedido, setTab, serviceDocumentos }) => {
  const { id } = useParams()

  const { loading: loadingDocumentos, obtenerDocumentos, obtenerDocumento, crearDocumento, eliminarDocumento } = serviceDocumentos
  const [documentos, setDocumentos] = useState([])

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const handleObtenerDocumentos = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC'
      }
      const response = await obtenerDocumentos(id, params)
      setDocumentos(response)
    } catch (error) {
      setDocumentos([])
    }
  }, [id, obtenerDocumentos])

  const handleObtenerDocumento = async (fileId, fileName) => {
    await obtenerDocumento(id, fileId, fileName)
  }

  const handleCrearDocumento = async (file) => {
    await crearDocumento(id, file, selectedFile)
    setSelectedFile(null)
    setModal(false)
    handleObtenerDocumentos()
  }

  const handleEliminarDocumento = async (file_id) => {
    await eliminarDocumento(id, file_id)
    handleObtenerDocumentos()
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Descargar',
      icon: 'bi bi-download text-primary',
      action: () => handleObtenerDocumento(currentRow.id, currentRow.riga_file_asset_name)
    })

    actions.push({
      label: 'Eliminar',
      icon: 'bi bi-x-circle text-danger',
      action: () => {
        handleEliminarDocumento(currentRow.id)
      }
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    },
  ], [COLUMNS, availableActions])

  useEffect(() => {
    handleObtenerDocumentos()
  }, [handleObtenerDocumentos])

  useEffect(() => {
    setTab('documentos')
  }, [])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <div className='row mb-8'>
        <div className='col text-end'>
          {editar &&
            <Button
              type='button'
              variant='primary'
              onClick={() => setModal(true)}
            >
              <i className='bi bi-plus-circle me-1' />
              Añadir documento
            </Button>
          }
        </div>
      </div>

      <ModalDocumentos show={modal} onClose={() => setModal(false)} onSave={handleCrearDocumento} selectedFile={selectedFile} handleSelectedFile={setSelectedFile} />

      <TableContainer>
        {loadingDocumentos && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }} />}

        <CustomTable columns={columns} data={documentos || []} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default TabDocumentos