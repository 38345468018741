import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, FormGroup, FormLabel, Modal, Table } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormikContext } from "formik";
import BarCodeReader from "./BarCodeReader";
import moment from 'moment'
import { ZebraScanner } from "components";
import { useOutOrders } from "hooks";
import { parseGS1DataMatrix } from "utilities/gs1DataMatrix";
import { OrderTypeI } from "interfaces";
import Select from "react-select"

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: !state.hasValue ? "1px solid red" : (state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef"),
    minHeight: '38px',
    width:'300px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  menu: (base) => ({
    ...base,
    width: '300px'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};

const defaultCounters={
  picking:0,
  ok:0,
  error:0
}

export function ArticleScanModal({ show, onHide, getOutOrder, stockArticles, salesOrderTypeId, storageSpacesOptions }) {

    const [scanCodes, setScanCodes] = useState([]);
    const { values, setFieldValue } = useFormikContext();
    const [counters, setCounters] = useState(defaultCounters);
    const [storageSpace, setStorageSpace] = useState(null)

    /*****************************************************************************************/

    const barcodesRef = useRef([]);
    const processingQueue = useRef(false);
    const canAddCode = useRef(true);
    const {handleLotDetail, loading } = useOutOrders()
    const scanCodesRef = useRef(scanCodes);
    
    const updateTagView = (value)=>{
      // console.log(value)
        if(value?.data){
          const newUpdate = [...scanCodesRef.current];
          const cambio = newUpdate.find(a => a.serial_number==value.data[0].serial_number);
          // console.log(cambio)
          // console.log(newUpdate)
          if(cambio){
            cambio.loading=false;
            cambio.result='OK'
            setScanCodes(newUpdate);
            
          } 
        }
        else if (value?.err?.request){
          const newUpdate = [...scanCodesRef.current];

          const cambio = newUpdate.find(a => a.serial_number==value.err.request.serial_number);

          if(cambio){
            cambio.loading=false;
            cambio.result=value.err.message
            setScanCodes(newUpdate);
          } 

        }
    }

    const processEnter = (cadena)=>{
      if(canAddCode.current){
        canAddCode.current=false;
        const buffCadena=cadena;
        cadena="";
        const dataTransformada = buffCadena.replace(/Shift/g, "")
            .replace(/CapsLock/g, '') 
            .replace(/Alt0029/g, "\u001D")
            .replace(/'/g, '-')
  
        const data = parseGS1DataMatrix(dataTransformada)
        data.scan_code=dataTransformada;

        if(data?.expiration_date){
            const hoy = moment();
            const expiration = moment(data.expiration_date, 'YYMMDD')
            if(expiration.isSameOrBefore(hoy)){
                toast.error("El artículo se encuentra vencido")
                canAddCode.current=true;
                return;                
            }
        }

        if (!scanCodesRef.current.map(c=>c.serial_number).includes(data.serial_number)) {

            const _actual = stockArticles.filter(a=>a.serial_number==data.serial_number);

            if(_actual && _actual.length ){

              let transfer = {
                  qty: 1,
                  lot_number: data.lot_number?data.lot_number:_actual[0].lot_number,
                  expiration_date: data.expiration_date?moment(data.expiration_date, 'YYMMDD').format("YYYY-MM-DD"):_actual[0].expiration_date,
                  gtin: data.gtin,
                  product_var_id: _actual[0].product_var_id,
                  serial_number: data.serial_number?data.serial_number:undefined,
                  product_var_name : _actual[0].name,
                  storage_space_id: _actual[0].storage_space_id,
                  loading:true,
                  // scan_code:data.scan_code
              }

              if(salesOrderTypeId==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO){
                transfer = {
                  ...transfer,
                  storage_space_id_destino:storageSpace?.value
                }
              }

              handleNewTag(transfer, null)
              setCounters(counters=>({...counters, picking: counters.picking + 1}))
              const asyncJob = getAsyncJob(transfer);
              enqueue(asyncJob);

            }
            else{
              toast.error("El artículo no se encuentra en el stock")
            }

         }         
         canAddCode.current=true;
      }
    }

    const nextJob = () => {

        if (processingQueue.current) 
          return;
        
        const next = barcodesRef.current.shift();
        
        if (next) {
          processingQueue.current = true;
          next
            .job()
            .then((value) => {
            //   console.log(value);
              next.resolve(value);
              updateTagView(value)
              processingQueue.current = false;
              nextJob();
            })
            .catch((error) => {
              next.resolve(error);
              updateTagView(error)
              // toast.error(error.err.message)
              processingQueue.current = false;
              nextJob();
            });
        }
    }

    const enqueue = (job) => {
        // we'll wrap the job in a promise and include the resolve 
        // and reject functions in the job we'll enqueue, so we can 
        // control when we resolve and execute them sequentially
        new Promise((resolve, reject) => {
            barcodesRef.current.push({ job, resolve, reject });
        });
        // we'll add a nextJob function and call it when we enqueue a new job;
        // we'll use _isBusy to make sure we're executing the next job sequentially
        nextJob();
    }

    const getAsyncJob = (item) => {
        return () =>
          new Promise((resolve, reject) => {
            const transport = { ...item }
            const {loading, ...resto} = transport;
            handleLotDetail(values.id, resto).then(res=>resolve(res)).catch(err=>reject({...transport, err}))
          });
    };

/****************************************************************************************** */

    const handleNewTag = (value, err)=>{

        if(!value){
          // getOutOrder();
          toast.error(err.message, {theme: "colored"})
          return;
        }
        const newScanCodes = [...scanCodes];
        newScanCodes.unshift(value);
        setScanCodes(newScanCodes)
        
        const copyDetail = [...values.detail];
        
        // getOutOrder();
    }

    useEffect(() => {
      scanCodesRef.current = scanCodes;
    }, [scanCodes]);


    useEffect(()=>{
      setScanCodes([])
      setStorageSpace(null)
    }, [show])


    return (
      <>
            <Modal size="xl" show={show} onHide={onHide} backdrop='static' >
            {/* <Modal.Header closeButton>
              <Modal.Title>Agregar un artículo al pedido</Modal.Title>
            </Modal.Header> */}

              <Modal.Body className="">
                <h3>Picking</h3>

                {/* <BarCodeReader  handleNewTag={handleNewTag} out_order_id={values?.id} storage_space_id={1}  stockArticles={stockArticles}/> */}

                { salesOrderTypeId==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO &&
                    <div className="mt-5 mb-5">
                      <label className="form-label">Espacio de almacenamiento destino</label>
                      <Select 
                          options={storageSpacesOptions}
                          styles={customStyles}
                          value={storageSpace}
                          onChange={e=>{
                            setStorageSpace(storageSpacesOptions.find(o=>o.value==e.value))
                          }}
                        />
                    </div>
                }
                { (salesOrderTypeId!=OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO || storageSpace!=null) && <ZebraScanner 
                  processEnter = {processEnter}  
                  disabled={false}
                />}

                <div>
                { true && 
                    <FormGroup>
                      <FormLabel className="mt-10">Escaneados</FormLabel>
                      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <td>Producto</td>
                              <td>Código</td>
                              <td>Serie</td>
                              <td>Lote</td>
                              <td>Vencimiento</td>
                              <td style={{maxWidth:"150px"}}></td>
                            </tr>
                          </thead>
                          <tbody>{scanCodes?.map((v, idx)=>(
                            <tr key={idx}>
                              <td>{v.product_var_name}</td>
                              <td>{v.gtin}</td>
                              <td>{v.serial_number || 'Sin datos'}</td>
                              <td>{v.lot_number || 'Sin datos'}</td>
                              <td>{v.expiration_date ? moment(v.expiration_date).format("DD/MM/YYYY"):'Sin datos'}</td>
                              { v.loading? 
                                  <td style={{maxWidth:"150px"}}><div className='spinner-border text-success'></div></td>
                                :
                                  v.result=='OK'?
                                    <td> <i className="bi bi-check-circle text-success fs-1 fw-bold"></i></td>
                                  :
                                     <td className="text-danger" style={{maxWidth:"150px"}}>{v.result}</td>
                              }
                              { v.expiration_date && moment(v.expiration_date) && 
                                (moment().isAfter(moment(v.expiration_date), 'day') || 
                                moment(v.expiration_date).diff(moment(), 'days') <= 90) &&
                                <td className="text-danger">Lote vencido o próximo a vencer</td>
                              }
                              
                              {/* TODO colocar tooltip para que se sepa porque está rojo o verde*/}
                              {/* {v.result=='OK'?
                                (v.serial_number?<td><i className="bi bi-check-circle text-success fs-1 fw-bold"></i></td>:<td></td>)
                                :
                                (v.result=='DUPLICATED'?
                                  <td className="text-danger">Ya ingresado</td>
                                  :
                                  <td><i className="bi bi-slash-circle text-danger fs-1 fw-bold"></i></td>)
                              } */}
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </div>
                    </FormGroup>
                }
                </div>

              </Modal.Body>

              <Modal.Footer style={{borderTop: "0 none"}}>
                <Button variant="secondary" onClick={onHide}>Salir</Button>
              </Modal.Footer>
          </Modal>
    </>
    )
  }
  