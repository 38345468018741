import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useIncomeOrder, useLogisticStock, useOutOrders, useTransactionalDocuments } from 'hooks';
import { Logistic } from 'services'
import { CustomTable, FormTable, Tooltip } from 'components';
import { AnmatEventsModal } from './AnmatEventsModal';
import { useNavigate } from 'react-router-dom';
import routes from 'app/routing/routes';
import {helix} from 'ldrs'
import { EditLotNumberModal } from './EditLotNumberModal';
import { useAuth } from 'app/modules/auth';
import { RoleI } from 'interfaces';

const detallesIguales = (detalleOE, detalleDevolucion)=>{
  const sortedA = [...detalleOE].sort((a, b) => a.product_var_id - b.product_var_id);
  const sortedB = [...detalleDevolucion].sort((a, b) => a.product_var_id - b.product_var_id);

  if (sortedA.length != sortedB.length) return false;

  return sortedA.every((itemA, index) => {
      const itemB = sortedB[index];
      return +itemA.product_var_id == +itemB.product_var_id && +itemA.qty == +itemB.qty;
  });
}

const AnmatTab = ({ editEnable, refreshIncomeOrder, request }) => {
  const { values } = useFormikContext();
  const [loading, setLoading] = useState(false)
  const [anmatResponse, setAnmatResponse] = useState({})
  const [entities, setEntities] = useState([])
  const [hasTracedErrors, setHasTracedErrors] = useState(false)
  const [showAnmatModal, setShowAnmatModal] = useState(false);
  const [showEditLotNumberModal, setShowEditLotNumberModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null)
  const [loadingReintentarTraza, setLoadingReintentarTraza] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useAuth()
  const { handleConfirmIncomeOrderForced, handleIncomeOrderDetailManualTraced, handleReintentarTraza, loading:loadingIncomeOrderUpdate } = useIncomeOrder();
  const {handleGetOutOrder, handleOutOrderGenerateInvoice} = useOutOrders();
  const {handleDescartarDiferido, handleCreatePartialCreditNote, handleCreateCreditNote} = useTransactionalDocuments();

  const loadAnmatValidate = async (waybill_number) => {
    try {
      const { in_order_id, detail } = values

      setLoading(true)
      const resp = await Logistic.anmatValidate({ in_order_id });

      if (resp) {
        const respAnmat = resp.data
        setAnmatResponse(respAnmat)

        // Agrega en el detalle una marca "informado_proveedor" para indicar si encontró ese GTIN y serial_number en la respuesta de anmat
        // Agrega "trazado" cin esta lógica:
        // anmat_traced_at NULL -> No trazado
        // anmat_traced_at not NULL && anmat_traced_error NULL -> trazado
        // anmat_traced_at not NULL && anmat_traced_error NOT NULL -> Error traza

        let aux = [...values.detail]
          .filter(a => a.product_var_traced === 1)
          .map(a => {
              let estado_traza = "";
              let color_traza="";

              if(a.manual_traced_at){
                estado_traza="Trazado manualmente"
                color_traza="estado-amarillo"
              }
              else{
                if(a.anmat_traced_at){
                  if(a.anmat_traced_error){
                    estado_traza="Error Traza"
                    color_traza="estado-rojo"
                  }
                  else{
                    estado_traza="Trazado"
                    color_traza="estado-verde"
                  }
                }
                else{
                  estado_traza="Pendiente"
                  color_traza="estado-gris"
                }
              }

              const _interseccion = respAnmat.original.filter(item_anmat =>
                  respAnmat.mapped.some(item_riga => item_riga.gtin == item_anmat.gtin && 
                    item_riga.lot_number == item_anmat.lote && 
                    item_riga.serial_number == item_anmat.numero_serial)
              );
              
              return {
                  ...a,
                  informado_proveedor: respAnmat.original
                      .filter(d => (d.gtin == a.gtin && d.numero_serial == a.serial_number).length > 0 ) || (a.anmat_traced_at && !a.anmat_traced_error) ? true : false,
                  traced_status: estado_traza,
                  traced_status_color:  color_traza
              }}
          )

        const _hasTracedErrors = values.detail.filter(a => a.anmat_traced_at && a.anmat_traced_error)

        setHasTracedErrors(_hasTracedErrors)
        setEntities(aux);
      }
    } catch(error){
      toast.error(error.message)
    } finally {
      setLoading(false);
    }
  }

  const reintentarTraza = async ()=>{
    try{
        if (true) {//cancelado o rechazado
          const msg = `Confirma el reintento de traza para esta órden?`
    
          const resp = await Swal.fire({
            title: "Advertencia",
            text: msg,
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, trazar",
            customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
            reverseButtons: true
          })
    
          if (resp.isConfirmed) {
            setLoadingReintentarTraza(true)
            await handleReintentarTraza(values.in_order_id)
            toast.success("El pedido se trazó correctamente", { theme: "colored" })
            setLoadingReintentarTraza(false)
            loadAnmatValidate()
          }
        }
      }
      catch(error){
        toast.error(error.message)
      }
      finally{
        setLoadingReintentarTraza(false)
      }
  }
  
  const handleStoreErrored = async () => {
    let _confirm = null;
    const {returned_request_reason='', returned_request_reason_detail='', returned_request_reason_sector=''} = request;
    let _observations = returned_request_reason ?  returned_request_reason + ' - ' :''
      _observations += returned_request_reason_detail ?  returned_request_reason_detail + ' - ' :''
      _observations += returned_request_reason_sector ?  returned_request_reason_sector :'';

    try{
      if (true) {//cancelado o rechazado
        const msg = `Va a ingresar artículos que no han sido trazados en ANMAT, confirma?`
        const inputValue = _observations;

        let swalData = {
          title: "Advertencia",
          text: msg,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, enviar a stock",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        }
        if(values.out_order_id){ 
          swalData = {
            ...swalData,
            input: "textarea",
            inputValue,
            inputLabel: "Motivo NC",
            inputAttributes: {
              autocomplete: "off",          
            },
            inputValidator: (value) => {
              if (!value || value.trim() == '') {
                return "Por favor, completar motivo para la NC"
              }
            },
          }
        }

        const resp = await Swal.fire( swalData )

        let _outOrder = null;
        if (resp.isConfirmed) {
          _confirm = await handleConfirmIncomeOrderForced(values.in_order_id)
          
          refreshIncomeOrder()

          if(values.out_order_id){ 
            // Es devolucion
            _outOrder = await handleGetOutOrder(values.out_order_id)
            
            if(_outOrder?.data?.result){
              const {afip_id, afip_estado, afip_codigo_interno_tipo_comprobante, afip_codigo_tipo_comprobante, detail:outOrderDetail} = _outOrder.data.result

              if(afip_estado=='SUCCESS' || afip_estado=='SUCCESS_WITH_OBSERVATIONS' ){
                  if( afip_codigo_interno_tipo_comprobante==1){
                    //hacer nc parcial
                    //mayor a 200, manda fceAnulacion:true
                    const obTransport = {
                      in_order_id: values.in_order_id,
                      fceAnulacion: afip_codigo_tipo_comprobante>200 ? true : false,
                      observaciones: resp.value,
                      items: values.detail?.map(d=>({
                        product_var_id:d.product_var_id, 
                        qty:d.qty
                      }))
                    } 
                    if(detallesIguales(outOrderDetail, values.detail_grouped)){
                      const {in_order_id, items, ...rest} = obTransport;
                      await handleCreateCreditNote(afip_codigo_interno_tipo_comprobante, afip_id, rest)
                    }
                    else{
                      await handleCreatePartialCreditNote(afip_codigo_interno_tipo_comprobante, afip_id, obTransport)
                    }
                  }
                  else{
                    //refacturar
                  }
              }
              else if(afip_estado=='DEFERRED'){
                // es comprobante diferido, descartar y refacturar
                await handleDescartarDiferido(afip_id)
                await handleOutOrderGenerateInvoice(values.out_order_id)
              }
  
            }
            else{
              toast.error("Error al obtener la órden de egreso para realizar la devolución")
              return;
            }
  
          }
          toast.success("El pedido se envió a Stock", { theme: "colored" })
          navigate(routes.LOGISTIC_INCOME)
        }
      }
    }
    catch(error){
        if(_confirm){
          toast.error("El pedido se ingresó en Stock, pero falló la generación de comprobantes")
        }
        else{
          toast.error(error.message, {theme: "colored"})
        }
    }
    finally{
      refreshIncomeOrder();
    }
  }

  const manualTraced = async (in_order_detail_id) => {
    try {
      setLoading(true)
      const response = await handleIncomeOrderDetailManualTraced(values.in_order_id, in_order_detail_id)

      if (response) {
        toast.success("Se trazó manualmente el producto", { theme: "colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
      loadAnmatValidate()
    }
  }

  const handleEditRow = (row)=>{
    setCurrentRow(row)
    setShowEditLotNumberModal(true)
  }

  const lotNumberUpdated = ()=>{
    setShowEditLotNumberModal(false)
    refreshIncomeOrder()
  }

  useEffect(() => {
    setLoading(false)
    setCurrentRow(null)
    setShowAnmatModal(false)
    setShowEditLotNumberModal(false)

    // if (waybill_number) {
    //   loadAnmatValidate()
    // }

  }, [])

  useEffect(()=>{
    const { waybill_number } = values
    if (waybill_number) {
      loadAnmatValidate()
    }
  }, [values.detail])

  const columns =[
    {
      Header: "Artículo",
      accessor: 'product_var_name',
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
      id: 'gtin',
      accessor: (row) => (
        <div className='text-center'>{row.gtin}</div>
      )
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Lote</div>),
      id:"lot_number",
      accessor: (row) =>(
        <div className="text-center">
          {row.lot_number}
        </div>
      ) 
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Nro. serie</div>),
      id: 'serial_number',
      accessor: (row) =>(
        <div className="text-center">
          {row.serial_number}
        </div>
      ) 
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Informado proveedor</div>),
      className:'col-status',
      id:"informado_proveedor",
      accessor: (row) =>(
        <span className={ `badge ${row.informado_proveedor?'estado-verde':'estado-rojo'} w-100 justify-content-center fw-normal`}>
          {row.informado_proveedor?'Sí':'No'}
        </span>
      ) 
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Trazado</div>),
      className:'col-status',
      id:"traced_status",
      accessor: (row) =>(
        <span className={ `badge ${row.traced_status_color} w-100 justify-content-center fw-normal`}>
          {row.traced_status}
        </span>
      ) 
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>En stock</div>),
      className:'col-status',
      id:"stock_id",
      accessor: (row) =>(
        <span className={ `badge ${row.stock_id?'estado-verde':'estado-gris'} w-100 justify-content-center fw-normal`}>
          {row.stock_id?'Sí':'Pendiente'}
        </span>
      ) 
    },
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        row?.anmat_traced_error && row?.anmat_traced_error != '' &&
          <>
            <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor:"pointer"}} onClick={()=>handleEditRow(row)}></i>
          </>
        
      )
    }
  ]

  useEffect(()=>{
    helix.register()
  }, [])

   return (
    <>
      <AnmatEventsModal show={showAnmatModal} onHide={()=>setShowAnmatModal(false)} events = {anmatResponse} waybill_number={values?.waybill_number} />
      <EditLotNumberModal show={showEditLotNumberModal} onHide={()=>setShowEditLotNumberModal(false)} currentRow={currentRow} lotNumberUpdated={lotNumberUpdated}/>

      <div className='row mt-8 mb-8'>

          <div className="col">
          {!loading &&
            <>
              <p className='form-label'>
                Remito: <b>{values?.waybill_number}</b>
              </p>

              <p className='form-label'>
                Artículos pickeados pertenecientes al remito:
              </p>
            </>
          }
          </div>
          <div className='col'>
            <div className='text-end'>
              {!loading && values.order_status_id === 13 &&
                  <Button type="button" variant="primary" onClick={reintentarTraza} className='me-3' disabled={loading || loadingReintentarTraza}>
                    <i className="" />
                    Reintentar Traza
                  </Button>
              }
              {!loading && values.order_status_id === 13 && (currentUser?.user_id==1 || currentUser?.roles.some(r => r.id != RoleI.OPERACIONES_GTE)) &&
                  <Button type="button" variant="secondary" onClick={handleStoreErrored} className='me-3' disabled={loading || loadingReintentarTraza}>
                    <i className="" />
                    Forzar Ingreso (SIN TRAZAR)
                  </Button>
              }
              {!loading &&
                  <Button type="button" variant="secondary" onClick={() => setShowAnmatModal(true)} disabled={loading || loadingReintentarTraza}>
                    <i className=""/>
                    Eventos ANMAT
                  </Button>
              }
            </div>
          </div>

      </div>
      
      { (loading || loadingReintentarTraza) ?
          <div className='text-center' style={{marginLeft:"auto"}}>
            <l-helix color="var(--bs-primary)" style={{marginTop:"0px" }}></l-helix>
          </div>
        :
          <CustomTable columns={columns} data={entities ||[]} />
      }


    </>
  )
}

export default AnmatTab