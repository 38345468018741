import { useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Button, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'

import { useAgentTypes } from 'hooks'

import { AddressModal, ContactModal } from 'containers/Modals'
import { InputBS, TextareaBS, SwitchField, SelectField, FormTable } from 'components'

import { addShipmentAddress, schema } from './constants'

import './styles.css'

const ShipmentAddressModal = ({ show, onClose, values = addShipmentAddress, onSave, submodal = false, ours = true, origin='' }) => {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState(addShipmentAddress)
  const [addressModal, setAddressModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)

  const { handleGetAgentTypes } = useAgentTypes()
  const [agentTypes, setAgentTypes] = useState([])

  const getAgentTypes = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes();

      let agents = response.result
      let lastAgent = agents.pop()
      agents.unshift(lastAgent)

      setAgentTypes(agents)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  const handleClose = async () => {
    if (values?.address?.id) {
      onClose()
    } else {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `La dirección no fue guardada, ¿quiere cerrar igualmente?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, cerrar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (show) {
      getAgentTypes()
    }
  }, [show, getAgentTypes])

  useEffect(() => {
    setInitialValues({
      ...addShipmentAddress,
      ...values,
      address: {
        ...addShipmentAddress.address,
        ...values?.address,
        route: values?.address?.route ? values?.address.route : '',
        street_number: values?.address?.street_number ? values?.address.street_number : '',
        floor: values?.address?.floor ? values?.address.floor : '',
        postal_code: values?.address?.postal_code ? values?.address.postal_code : '',
        locality: values?.address?.locality ? values?.address.locality : '',
        administrative_area_level_1: values?.address?.administrative_area_level_1 ? values?.address.administrative_area_level_1 : '',
        administrative_area_level_2: values?.address?.administrative_area_level_2 ? values?.address.administrative_area_level_2 : '',
        manual: values?.address?.origin == 'MANUAL' || values?.address.origin == 'MIGRACION' || Boolean(values?.address.manual),
        origin: Boolean(values?.address?.manual) ? 'MANUAL' : 'GOOGLE',
      },
      contact: {
        ...addShipmentAddress.contact,
        ...values?.contact,
        name: values?.contact?.name ? values.contact.name : '',
        email: values?.contact?.email ? values.contact.email : '',
        has_notifications: Boolean(values?.contact.has_notifications),
        phone: values?.contact?.phone ? values.contact.phone : '',
        mobile_suffix: values?.contact?.mobile_suffix ? values.contact.mobile_suffix : '',
        mobile: values?.contact?.mobile ? values.contact.mobile : '',
        observations: values?.contact?.observations ? values.contact.observations : '',
      },
      has_traceability: Boolean(values?.has_traceability),
      agent_type_id: values?.agent_type_id || '',
      gln: values?.gln ? values.gln : '',
      integrates_verifarma: Boolean(values?.integrates_verifarma),
      is_ours: Boolean(values?.is_ours),
      is_enabled: Boolean(values?.is_enabled),
      observations: values?.observations ? values.observations : '',
    })
  }, [setInitialValues, values])

  if (!show) return null

  return (
    <Modal size='xl' show={show} onHide={handleClose} backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{values?.address?.id ? `Editar dirección #${values.address.id}` : 'Nueva dirección'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <>
                {/* <div className='row mb-8'>
                  <div className='col-10'></div>
                  <div className='col-2 text-end'>
                    <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
                  </div>
                </div> */}

                <div className='row my-8'>
                  <div className={ours ? 'col-10' : 'col-12'}>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.NAME' })} />
                  </div>

                  {ours &&
                    <div className='col-2'>
                      <div className='d-flex' style={{ paddingTop: '32px' }}>
                        <SwitchField id='is_ours' name='is_ours' />
                        <span style={{ fontSize: '1.1rem' }}>{intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.IS_OURS' })}</span>
                      </div>
                    </div>
                  }
                </div>

                <div className='separator my-8'></div>

                <div className='my-8'>
                  <div className='d-flex justify-content-between align-items-center mb-8'>
                    <label className='form-label m-0'>Datos de dirección</label>

                    <div>
                      <Button className='BSButton' variant='outline-primary' onClick={() => setAddressModal(true)} >{values.address.id ? 'Editar dirección' : 'Añadir dirección'}</Button>
                    </div>
                  </div>
                </div>

                <AddressModal
                  show={addressModal}
                  onClose={() => setAddressModal(false)}
                  values={values}
                  onSave={values => {
                    setFieldValue('address', values.address)
                    setAddressModal(false)
                  }}
                  submodal
                />

                {(values?.address?.id || values?.address?.route) &&
                  <FormTable
                    columns={[
                      { header: 'Calle' },
                      { header: 'CP' },
                      { header: 'Localidad' },
                      { header: 'Comuna / Partido' },
                      { header: 'Provincia' },
                      { header: '', styles: 'text-center' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>{values.address?.route} {values.address?.street_number}{values.address?.floor !== '' ? ` (${values.address.floor})` : ''}</td>
                      <td className='align-middle'>{values.address?.postal_code}{values.address?.postal_code_suffix}</td>
                      <td className='align-middle'>{values.address?.locality}</td>
                      <td className='align-middle'>{values.address?.administrative_area_level_2}</td>
                      <td className='align-middle'>{values.address?.administrative_area_level_1}</td>
                      <td className='align-middle text-center'>
                        <button type='button' className='btn btn-icon' onClick={() => setAddressModal(true)}>
                          <i className='fas fa-pencil text-primary'></i>
                        </button>
                      </td>
                    </tr>
                  </FormTable>
                }

                <div className='separator my-8'></div>

                {origin != 'Paciente' &&
                  <>
                    <div className='row my-8'>
                      {/* <h1 className='mb-8'>Trazabilidad</h1> */}
                      <div className='col-2'>
                        <SelectField
                          id='has_traceability'
                          name='has_traceability'
                          options={[{ value: false, label: 'NO' }, { value: true, label: 'SÍ' }]}
                          label={'Trazabilidad'}
                        />
                      </div>

                      {Boolean(values.has_traceability) && (
                        <>
                          <div className='col-4'>
                            <SelectField
                              id='agent_type_id'
                              name='agent_type_id'
                              label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.TYPE' })}
                              options={[{ value: '', label: 'Seleccione un tipo de agente' }, ...agentTypes.map(item => { return { value: item.id, label: item.name }})]}
                            />
                          </div>

                          <div className='col'>
                            <InputBS id='gln' name='gln' label="GLN" maxLength={13} />
                          </div>

                          {/* <div className='col-2'>
                            <SelectField
                              id='integrates_verifarma'
                              name='integrates_verifarma'
                              options={[ { value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
                              label={'Verifarma'}
                            />
                          </div> */}
                        </>
                      )}
                    </div>

                    <div className='separator my-8'></div>

                    <div className='my-8'>
                      <div className='d-flex justify-content-between align-items-center mb-8'>
                        <label className='form-label m-0'>Datos de contacto</label>

                        <div>
                          <Button className='BSButton' variant='outline-primary' type='button' onClick={() => setContactModal(true)} >{values.contact.name ? 'Editar datos de contacto' : 'Añadir datos de contacto'}</Button>
                        </div>
                      </div>
                    </div>

                    <ContactModal
                      show={contactModal}
                      onClose={() => setContactModal(false)}
                      values={values}
                      onSave={values => {
                        setFieldValue('contact', values.contact)
                        setContactModal(false)
                      }}
                      submodal
                    />

                    {values?.contact?.name &&
                      <FormTable
                        columns={[
                          { header: 'Nombre' },
                          { header: 'Email' },
                          { header: 'Notificaciones' },
                          { header: 'Teléfono' },
                          { header: 'Celular' },
                          { header: '', styles: 'text-center' },
                        ]}
                      >
                        <tr>
                          <td className='align-middle'>{values.contact.name}</td>
                          <td className='align-middle'>{values.contact.email && values.contact.email !== '' ? values.contact.email : '-'}</td>
                          <td className='align-middle'>
                            {values.contact.has_notifications
                              ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                              : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                            }
                          </td>
                          <td className='align-middle'>{values.contact.phone && values.contact.phone !== '' ? values.contact.phone : '-'}</td>
                          <td className='align-middle'>{values.contact.mobile_suffix && values.contact.mobile_suffix !== '' ? `${values.contact.mobile_suffix} ` : ''}{values.contact.mobile !== '' ? values.contact.mobile : '-'}</td>
                          <td className='align-middle text-center'>
                            <button type='button' className='btn btn-icon' onClick={() => setContactModal(true)}>
                              <i className='fas fa-pencil text-primary'></i>
                            </button>
                          </td>
                        </tr>
                      </FormTable>
                    }

                    <div className='separator my-8'></div>
                  </>
                }

                <div className='row my-8'>
                  <div className='col'>
                    <TextareaBS id='observations' name='observations' label='Observaciones' />
                  </div>
                </div>

                <Card.Footer className='d-flex flex-row justify-content-end'>
                  <Button type='button' className='me-3' variant='secondary' onClick={handleClose}><i className="bi bi-slash-circle" />Cancelar</Button>
                  <Button type='submit' variant='primary' onClick={handleSubmit}><i className='bi bi-save me-2' />Guardar</Button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ShipmentAddressModal
