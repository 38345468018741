import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { usePedidos } from 'hooks'

import { useActions } from './services/actions'
import AuthModal from './AuthModal'

import { OrderTypeI, RoleI, SalesOrderStatusI } from 'interfaces'

const MenuActions = ({ loading, pedido, obtenerPedido }) => {
  const { currentUser } = useAuth()

  const {
    cancelarPedido,
    autorizarRentabilidadPedido,
    autorizarCreditoPedido,
    noAutorizarPedido,
    crearOrdenEgreso,
    crearOrdenEgresoParcial,
    cancelacionEspecialPedido,
    cancelacionParcialPedido,
    imprimirPedido,
    clonarPedido,
  } = useActions()

  const { handleObtenerDetallePedido, handleObtenerOrdenesEgresoPedido } = usePedidos()
  const [detalle, setDetalle] = useState([])
  const [ordenes, setOrdenes] = useState([])

  const obtenerDetallePedido = useCallback(async () => {
    if (pedido?.id) {
      try {
        const response = await handleObtenerDetallePedido(pedido.id)
        const data = response.data.result

        setDetalle(data)
        return data
      } catch (error) {
        setDetalle([])
        return []
      }
    }

    return []
  }, [pedido, handleObtenerDetallePedido])

  const obtenerOrdenesEgresoPedido = useCallback(async () => {
    if (pedido?.id) {
      try {
        const response = await handleObtenerOrdenesEgresoPedido(pedido.id)
        const data = response.data.result

        setOrdenes(data)
        return data
      } catch (error) {
        setOrdenes([])
        return []
      }
    }

    setOrdenes([])
    return []
  }, [pedido, handleObtenerOrdenesEgresoPedido])

  const [authModal, setAuthModal] = useState(false)

  const availableActions = () => {
    let actions = []

    if ([SalesOrderStatusI.BORRADOR].includes(pedido.sales_order_status_id)) {
      actions.push({
        label: 'Enviar a autorizar',
        icon: 'bi bi-box-arrow-in-down-right text-success',
        action: async () => {
          try {
            const detalleData = await obtenerDetallePedido()

            if (detalleData.length > 0) {
              setAuthModal(true)
            } else {
              toast.warning(`El pedido #${pedido.id} no tiene artículos`)
            }
          } catch (error) {
            toast.error(error.message)
          }
        }
      })
    }

    if ([
      SalesOrderStatusI.BORRADOR,
      SalesOrderStatusI.NO_AUTORIZADO,
      SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA,
      SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO,
      SalesOrderStatusI.AUTORIZADO,
    ].includes(pedido.sales_order_status_id)) {
      actions.push({
        label: 'Cancelar',
        icon: 'bi bi-trash text-danger',
        action: async () => {
          await cancelarPedido(pedido.id, SalesOrderStatusI.CANCELADO)
          await obtenerPedido()
        }
      })
    }

    if ([SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA].includes(pedido.sales_order_status_id)) {
      let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_RENTAS]

      if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
        actions.push({
          label: 'Autorizar rentabilidad',
          icon: 'bi bi-check-all text-success',
          action: async () => {
            await autorizarRentabilidadPedido(pedido.id)
            await obtenerPedido()
          }
        })

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: async () => {
            await noAutorizarPedido(pedido.id, true)
            await obtenerPedido()
          }
        })
      }
    }

    if ([SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(pedido.sales_order_status_id)) {
      let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_CRED]

      if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
        actions.push({
          label: 'Autorizar crédito',
          icon: 'bi bi-check-all text-success',
          action: async () => {
            await autorizarCreditoPedido(pedido.id)
            await obtenerPedido()
          }
        })

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: async () => {
            await noAutorizarPedido(pedido.id, false)
            await obtenerPedido()
          }
        })
      }
    }

    if ([SalesOrderStatusI.AUTORIZADO].includes(pedido.sales_order_status_id)) {
      actions.push({
        label: 'Creaer orden de egreso',
        icon: 'bi bi-box-arrow-up-right text-success',
        action: async () => {
          await crearOrdenEgreso(pedido.id, pedido)
          await obtenerPedido()
        }
      })
    }

    if (
      [
        SalesOrderStatusI.FALTA_STOCK,
        SalesOrderStatusI.RESERVA_PARCIAL,
        SalesOrderStatusI.EN_REVISION,
        SalesOrderStatusI.COORDINACION_ENTREGA,
        SalesOrderStatusI.PENDIENTE_PREPARACION,
      ].includes(pedido.sales_order_status_id)
    ) {
      if (pedido.sales_order_status_id !== SalesOrderStatusI.EN_REVISION) {
        actions.push({
          label: 'Crear orden de egreso parcial',
          icon: 'bi bi-box-arrow-up-right text-success',
          action: async () => {
            await crearOrdenEgresoParcial(pedido.id, pedido)
            await obtenerPedido()
          }
        })
      }

      if (
        [
          SalesOrderStatusI.EN_REVISION,
          SalesOrderStatusI.COORDINACION_ENTREGA,
          SalesOrderStatusI.PENDIENTE_PREPARACION,
        ].includes(pedido.sales_order_status_id)
      ) {
        if (ordenes.length == 1) { // Cant. órdenes de egreso == 1
          actions.push({
            label: 'Cancelar',
            icon: 'bi bi-x-circle text-danger',
            action: async () => {
              await cancelacionEspecialPedido(pedido.id)
              await obtenerPedido()
            }
          })
        }

        if (ordenes.length > 1) { // { Cant. órdenes de egreso > 1
          actions.push({
            label: 'Cancelar parcialmente',
            icon: 'bi bi-x-circle text-danger',
            action: async () => {
              await cancelacionParcialPedido(pedido.id)
              await obtenerPedido()
            }
          })
        }
      }
    }

    actions.push({
      label: 'Descargar',
      icon: 'bi bi-download text-primary',
      action: async () => {
        try {
          const detalleData = await obtenerDetallePedido()

          if (detalleData.length > 0) {
            await imprimirPedido(pedido.id)
          } else {
            toast.info('El pedido no tiene artículos cargados')
          }
        } catch (error) {
          toast.error(error.message)
        } finally {
          await obtenerPedido()
        }
      }
    })

    actions.push({
      label: 'Clonar',
      icon: 'bi bi-clipboard-check',
      action: async () => {
        await clonarPedido(pedido.id)
        await obtenerPedido()
      }
    })

    return actions
  }

  useEffect(() => {
    obtenerOrdenesEgresoPedido()
  }, [obtenerOrdenesEgresoPedido])

  if (availableActions().length == 0) return null

  return (
    <>
      <DropdownButton title='Acciones' disabled={loading}>
        {availableActions().map((a, _idx) => (
          <Dropdown.Item key={_idx} onClick={() => a.action()}>
            <i className={`${a.icon} pe-3`} />
            {a.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>

      <AuthModal show={authModal} onClose={() => setAuthModal(false)} pedido={pedido} obtenerPedido={obtenerPedido} />
    </>
  )
}

const Actions = ({ loading, pedido, editar = false, setEditar, submitRef, cancelRef, obtenerPedido }) => {
  const navigate = useNavigate()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <Button type='button' variant='secondary' onClick={() => navigate(-1)} className='btn btn-icon btn-icon-secondary me-3' disabled={loading}>
        <i className='bi bi-chevron-left text-gray-900' />
      </Button>

      {editar
        ?
          <>
            <Button type='button' variant='secondary' onClick={() => cancelRef.current.click()} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cancelar
            </Button>

            <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading}>
              <i className='bi bi-save me-1' />
              Guardar
            </Button>
          </>
        :
          <>
            {[SalesOrderStatusI.BORRADOR, SalesOrderStatusI.NO_AUTORIZADO].includes(pedido?.sales_order_status_id) &&
              <Button type='button' onClick={() => setEditar(true)} className='me-3 btn btn-outline btn-outline-primary'>
                <i className='bi bi-pencil text-primary me-1' />
                Editar
              </Button>
            }

            <MenuActions loading={loading} pedido={pedido} obtenerPedido={obtenerPedido} />
          </>
      }

    </div>
  )
}

export default Actions