import { useState, useEffect } from "react";
import { Button, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

import { useOutOrders } from "hooks";

import './article.css'
import { OrderTypeI } from "interfaces";

export function DetailEditModal({ show, onHide, rowForEdit, canEdit, salesOrderTypeId }) {
    const [serialNumbersForEdit, setSerialNumbersForEdit] = useState([])
    const [serialNumbersFiltered, setSerialNumbersFiltered] = useState([])
    const {handleGetOutStockStatus, handleQuitarSerialDelPicking, loading} = useOutOrders();
    const [searchText, setSearchText] = useState("");

    const loadData = async () => {
        const resp = await handleGetOutStockStatus(rowForEdit.out_order_id, { product_var_id: rowForEdit.product_var_id })
        const data = resp?.data?.result;
        if (data) {
            /* Cuando se implemente el filtro por product_var_id en el back, sacar el filter de esta línea */
            // const series = data.filter(d => d.product_var_id == rowForEdit.product_var_id)
            const series = data

            setSerialNumbersForEdit(data)
            setSerialNumbersFiltered(data)
        } else {
            toast.error("Error al cargar datos")
        }
    }

    const deleteSerialNumber = async (serial) => {
        const text = rowForEdit.traced == 1
            ? `¿Confirma la eliminación del serial #${serial.serial_number} del egreso #${rowForEdit.out_order_id}?`
            : `¿Quiere cancelar el pick del stock #${serial.stock_id} del egreso #${rowForEdit.out_order_id}?`

        const textBtn = rowForEdit.traced == 1
            ? `Sí, eliminar serie`
            : `Si, cancelar`

        const resp = await Swal.fire({
            title: "Advertencia",
            text: text,
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No",
            confirmButtonText: textBtn,
            customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
            reverseButtons: true,
        })

        if (resp.isConfirmed) {
            const resp = await handleQuitarSerialDelPicking(rowForEdit.out_order_id, { out_order_detail_stock_id: serial.id })
            loadData()
        }
    }

    useEffect(() => {
        if (searchText != '') {
            setSerialNumbersFiltered(serialNumbersForEdit.filter(s => s.serial_number.toLowerCase().includes(searchText.toLowerCase())))
        } else {
            setSerialNumbersFiltered(serialNumbersForEdit)
        }
    }, [searchText])

    useEffect(() => {
        if (rowForEdit) {
            setSearchText("");
            loadData();
        }
    }, [rowForEdit])

    return (
        <>
            <Modal show={show} onHide={onHide} backdrop='static' centered size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {serialNumbersForEdit.length ? `#${serialNumbersForEdit[0].product_var_id} | ${serialNumbersForEdit[0].product_var_name}` : ''}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="">
                    {/* <h3 className="pb-2">{serialNumbersForEdit.length ? serialNumbersForEdit[0].product_var_name : ''}</h3> */}

                    <div className="row py-5">
                        <div className="col-6">
                            <FormLabel className="form-label">Buscar serie</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                onChange={ev => setSearchText(ev.target.value)}
                                value={searchText}
                                id='serial' autoComplete="off"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {loading &&
                                <div className='w-100 text-center'>
                                    <div className='spinner-border text-success'></div>
                                </div>
                            }

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className="text-center">ID Stock</th>
                                        {/* <th>Espacio origen</th> */}
                                        {salesOrderTypeId==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO ? 
                                            <th>Espacio destino</th>
                                            :
                                            <th>Espacio origen</th>
                                        }
                                        <th>Lote</th>
                                        <th>Vto.</th>
                                        <th>Nro. Serie</th>
                                        {canEdit &&
                                            <th></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {serialNumbersFiltered?.map((serie, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{serie.stock_id}</td>
                                            {/* <td>{serie.storage_space_name}</td> */}
                                            {salesOrderTypeId==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO ? 
                                                <td>{serie.storage_space_name_destino}</td>
                                                :
                                                <td>{serie.storage_space_name}</td>
                                            }
                                            <td>{serie.lot_number}</td>
                                            <td>{serie.expiration_date ? moment(serie.expiration_date).format("DD/MM/YYYY") : "" }</td>
                                            <td>{serie.serial_number}</td>
                                            {canEdit &&
                                                <td className="text-center">
                                                    <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Eliminar</Tooltip>}>
                                                        <span className="">
                                                            <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} onClick={() => deleteSerialNumber(serie)} />
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                            }
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ borderTop: "0 none" }}>
                    <Button variant="secondary" onClick={onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}