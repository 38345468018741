import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { usePedidos, usePriceUpdate } from 'hooks'

export const useArticulos = () => {
  const { loading, handleObtenerDetallePedido } = usePedidos()
  const { loading: loadingActuPrecio, handleGetPriceUpdates } = usePriceUpdate()

  /**
   * @async Obtener detalle de un Pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<Array<any>>}
   */
  const obtenerDetalle = useCallback(async (idPedido) => {
    try {
      const response = await handleObtenerDetallePedido(idPedido)
      return response
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleObtenerDetallePedido])

  /**
   * @async Obtener tipos de actualizaciones de precio
   * @returns {Promise<Array<any>>}
   */
  const obtenerActualizacionesPrecio = useCallback(async (idPedido) => {
    try {
      const response = await handleGetPriceUpdates()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label
      }))

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleGetPriceUpdates])

  return {
    loading,
    obtenerDetalle,
    loadingActuPrecio,
    obtenerActualizacionesPrecio,
  }
}